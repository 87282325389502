import { Backdrop, Box, Modal } from '@mui/material';
import React from 'react'
import "./CommonModal.css"

interface CommonModalProps {
    modalOpen: boolean;
    handleClose: (reason?: any, event?: any) => void;
    children: any;
    BackdropProps?: {
        onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    };
    className?: string;
    width?: any;
}


const CommonModal: React.FC<CommonModalProps> = ({ modalOpen, handleClose, children, BackdropProps, className, width }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width ? width : 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Modal
            open={modalOpen}
            disableEnforceFocus
            onClose={handleClose}
            className={`${className} commonModal`}
            BackdropComponent={Backdrop}
            BackdropProps={BackdropProps}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {children}
            </Box>
        </Modal>
    )
}

export default CommonModal