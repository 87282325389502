import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
interface ClipboardPaidModalProps {
    copied: boolean;
    setCopied: any;
    encodedData?: any
    // Define your props here
}
const ClipboardPaidModal: React.FC<ClipboardPaidModalProps> = ({ copied, encodedData, setCopied }) => {

    const usermetaData = useSelector((state: any) => state.ui.userMetadata.token)

    const encToken: any = btoa(usermetaData)
    const url = `${window.location.origin}/user-register/${encToken}${encodedData ? `/${encodedData}` : ""}`
    return (
        <div className={`clipboardScreen ${copied ? "copiedText" : ""}`}>

            <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
                <button>{copied ? 'Copied!' : 'Copy Link'}</button>
            </CopyToClipboard>
            <Tooltip title={url}>
                <p className='copyLink'>  {url} </p>
            </Tooltip>
        </div>
    )
}

export default ClipboardPaidModal;
