import React from 'react';
import { getRandomColor } from '../../../utils/CommonFunction';
import "./index.css"
import { Tooltip } from 'antd';

interface StudentCourseBoxProps {
    // Define your props here
    item: any;
    name: string;
    classClick: (item: any, name: string, department?: any, courseStatus?: string) => void;
    scheduleStartTime?: any;
    scheduleEndTime?: any;
    courseStatus: any;
    viewSubmissionClick?: (e: any, item: any) => void
}

const StudentCourseBox: React.FC<StudentCourseBoxProps> = ({ item, classClick, name, scheduleStartTime, scheduleEndTime, courseStatus, viewSubmissionClick }) => {

    const clickOnClass = (e: any, item: any, name: string, courseStatus: string) => {
        e.stopPropagation()
        classClick(item, name, courseStatus)
    }

    return (
        <div className={`subject`} onClick={(e: any) => clickOnClass(e, item, name, courseStatus)} style={{ background: getRandomColor(item.courseName).color }}>

            <div className="subjectDetails">
                <div className="subjectHeader">
                    <h2 className={`boxHeading ${courseStatus}`} style={{ fontWeight: "500" }}>
                        {item.courseName}
                    </h2>
                    {courseStatus !== "Nothing" ?
                        <p className="availableCourse available_course_absolute">
                            Available
                        </p>
                        : ""}
                </div>
                <p className="teacherName">Course Code : {item.courseCode}</p>
                <p className="teacherName">Course Type : {item.courseType}</p>
                {scheduleStartTime ? <p className="teacherName" >Scheduled Time : {scheduleStartTime}</p> : ""}
                {(scheduleEndTime && item.publishStatus !== 3) ? <p className="teacherName" >Scheduled Close Time : {scheduleEndTime}</p> : ""}
                {item.courseType === "Regular" ? "" : (
                    <p className="teacherName">
                        Attempts : {item.leftAttempts ? item.leftAttempts : 0}
                    </p>
                )}
                {(viewSubmissionClick && item.courseType === "Test" && item.leftAttempts < item.allowedAttempts) ? <Tooltip title="View Submission">
                    <p onClick={(e: any) => viewSubmissionClick(e, item)} className="viewSubmissionLink">View Submission</p>
                </Tooltip> : ""}
            </div>
        </div>
    );
};

export default StudentCourseBox;