import { Select } from 'antd';
import React from 'react';
import "./index.css"

interface DropdownProps {
    // Define your props here
    handleChange?:(e:any)=>void,
    options:any[],
    placeholder?:string
}

const Dropdown: React.FC<DropdownProps> = ({handleChange , options , placeholder}) => {
    return (
        <Select
            // defaultValue="lucy"
            placeholder={placeholder}
            style={{ width: 120 }}
            onChange={handleChange}
            options={options}
        />
    );
};

export default Dropdown;