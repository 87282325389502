import React from 'react';
import StudentTeacherList from './studentTeacherList';
import { InputMask } from '@react-input/mask';

interface TeacherBodyProps {
  // Define your props here
  teacherList: any[];
  name: string;
  teacherEdit:any;
  handleClose:any;
  modalOpen:boolean;
  teacherAddClick:any;
  deleteClick:any;
  importClick:any;
  generateClick:()=>void
}

const TeacherBody: React.FC<TeacherBodyProps> = ({ teacherList, name , teacherEdit , handleClose, modalOpen ,teacherAddClick , deleteClick , importClick , generateClick}) => {
  return (
    <>
      <StudentTeacherList  data={teacherList} {...{ name , teacherEdit , handleClose, modalOpen , teacherAddClick , deleteClick , importClick , generateClick}} />
    </>
  );
};

export default TeacherBody;