import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import "./index.css"
import { useSelector } from 'react-redux';
import PopoverComp from '../popoverComp';
import DownloadIcon from '@mui/icons-material/Download';

interface PDFWritterProps {
    treeData: any;
}

const PDFWritter: React.FC<PDFWritterProps> = ({ treeData }) => {

    const [pdfData, setPdfData] = useState(treeData)
    const appLocation = useSelector((state: any) => state.ui.appLocation)
    const [pdfContent, setPdfContent] = useState<string>("")
    const [pdfContentDescription, setPdfContentDescription] = useState<string>("")
    const [pdfContentDescriptionOnly, setPdfContentDescriptionOnly] = useState<string>("")
    const allCoursedata = appLocation.split("/")
    useEffect(() => {
        const onlyPage = treeData?.filter((item: any) => item.data.fileType)
        setPdfData([...onlyPage])
    }, [treeData.length])
    const componentRef = useRef<HTMLDivElement>(null);
    const componentRefWithDiscription = useRef<HTMLDivElement>(null);
    const componentRefWithDiscriptionOnly = useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: allCoursedata[4],
        pageStyle: `
        @media print {
            body {
                    zoom: 0.8; 
                    margin: 0.1cm 0cm;
                }
            @page{
                border:1px solid gray;
            }
        }
    `,


    });
    const handlePrintWithDescription = useReactToPrint({
        content: () => componentRefWithDiscription.current,
        documentTitle: allCoursedata[4],
        pageStyle: `
        @media print {
            body {
                zoom: 0.8; 
                margin: 0.1cm 0cm;
            },
        @page{
            border:1px solid gray;
            padding-top:10px;
        }
        }
    `,

    });
    const handlePrintWithDescriptionOnly = useReactToPrint({
        content: () => componentRefWithDiscriptionOnly.current,
        documentTitle: allCoursedata[4],
        pageStyle: `
        @media print {
            body {
                zoom: 0.8; 
                margin: 0.1cm 0cm;
            },
        @page{
            border:1px solid gray;
            padding-top:10px;
        }
        }
    `,

    });


    const checkDuplicate = (text: string, data: string[]) => {
        if (((data[0].replace(/\s/g, '') === "a" || data[0].replace(/\s/g, '') === "<p>a</p>") && (data[1].replace(/\s/g, '') === "b" || data[1].replace(/\s/g, '') === "<p>b</p>") && (data[2].replace(/\s/g, '') === "c" || data[2].replace(/\s/g, '') === "<p>c</p>") && (data[3].replace(/\s/g, '') === "d" || data[3].replace(/\s/g, '') === "<p>d</p>")) || ((data[0].replace(/\s/g, '') === "1" || data[0].replace(/\s/g, '') === "<p>1</p>") && (data[1].replace(/\s/g, '') === "2" || data[1].replace(/\s/g, '') === "<p>2</p>") && (data[2].replace(/\s/g, '') === "3" || data[2].replace(/\s/g, '') === "<p>3</p>") && (data[3].replace(/\s/g, '') === "4" || data[3].replace(/\s/g, '') === "<p>4</p>"))) {
            return ""
        } else {
            return text
        }
    }

    const validateOptions = (data: any) => {
        if (data?.length) {
            return `
                <div class = "pdfQuestions">
                    <ul> 
                        <li>${checkDuplicate(data[0], data)}</li>
                        <li>${checkDuplicate(data[1], data)}</li>
                        <li>${checkDuplicate(data[2], data)}</li>
                        <li>${checkDuplicate(data[3], data)}</li>
                    </ul>
                </div>
                `
            // }
        } else {
            return ""
        }
    }

    const checkIndex = (data: any, array: any) => {
        if (data == 0 || data == 1 || data == 2 || data == 3) {
            return data
        } else {
            const correctIndex = array?.mcqData ? JSON.parse(array?.mcqData).findIndex((item: any) => item) : 0
            return correctIndex
        }
    }

    const checkText = (text: string) => {
        if (!text || text === "<p><br></p>") {
            return false
        } else {
            return true
        }
    }

    const getCorrectAns = (mcqData: any, element: any) => {
        if (element) {
            if (element.PageDataJson) {
                const jsonData = JSON.parse(element.PageDataJson)
                if (jsonData.questionType === "text") {
                    return `${jsonData.answer} to ${jsonData.rangeTo}`
                } else {
                    const data = JSON.parse(mcqData)
                    const multiAnswerArray = []
                    if (element?.multiAnswer.length) {
                        for (let i = 0; i < element?.multiAnswer.length; i++) {
                            const value = element.multiAnswer[i];
                            multiAnswerArray.push(data[value])
                        }
                        return multiAnswerArray.join(", ")
                    } else {
                        return data[element.correctAnswer]
                    }
                }
            } else {
                if (element.correctAnswer >= 0 && element.mcqData) {
                    try {
                        const mcq = JSON.parse(element.mcqData)
                        return mcq[element.correctAnswer]
                    } catch (error) {
                        console.error("Error parsing MCQ data:", error)
                        return ""
                    }
                } else {
                    return ""
                }
            }
        }
    }

    const correctAnswerFunction = (mcqData: any, element: any, index?: any) => {
        let answerText
        if (element.correctAnswer) {
            if (checkIndex(element.correctAnswer, element) >= 0) {
                answerText = getCorrectAns(element?.mcqData, element)
            } else {
                answerText = ""
            }
        } else {
            answerText = ""
        }
        return answerText
    }


    useEffect(() => {
        let htmlContent = `
            <div class="pdfBody"> 
            <p class="pdfHeadtext ${allCoursedata[6] ? "" : "border_bottom_pdf"}">${allCoursedata[4]}</p>
             ${allCoursedata[6] ? `<p class="pdfCourseDescription">${allCoursedata[6]}</p>` : ""}
        `;
        for (let index = 0; index < treeData.length; index++) {
            const element = treeData[index];
            let mediaContent = "";
            if (element?.Media && element.Media.length > 0) {
                for (let j = 0; j < element.Media.length; j++) {
                    const data = element.Media[j];
                    mediaContent += `<img src="${data}" />`;
                }
            }

            htmlContent += `
                <div class="question_heading"> 
                <div class="pdf_qus_opt">
                    <div class="pdfQuestionheading">
                        <span>${index + 1}.</span>
                        <div>
                            ${checkText(element?.text) ? element?.text : mediaContent}
                        </div>
                    </div>
                    <div>
                        ${checkText(element?.text) ? mediaContent : ""}
                    </div>

                    ${validateOptions(element?.mcqData ? JSON.parse(element.mcqData) : [])}
                </div>
                    <div class="border_seprator">
                    <p></p>
                    </div> 
                </div>`;
        }
        htmlContent += `</div></div>`;
        setPdfContent(htmlContent)
        let htmlContentWithDescription = `<div class="pdfBody"> 
            <p class="pdfHeadtext ${allCoursedata[6] ? "" : "border_bottom_pdf"}">${allCoursedata[4]}</p>
           ${allCoursedata[6] ? `<p class="pdfCourseDescription">${allCoursedata[6]}</p>` : ""}
        `;
        for (let index = 0; index < treeData.length; index++) {
            const element = treeData[index]
            let answerText
            if (checkIndex(element.correctAnswer, element) >= 0) {
                answerText = element?.mcqData ? JSON.parse(element?.mcqData)[element.correctAnswer] : ""
            } else {
                answerText = ""
            }
            let mediaContent2 = ""; // Initialize variable for media content
            if (element?.Media && element.Media.length > 0) {
                for (let j = 0; j < element.Media.length; j++) {
                    const data = element.Media[j];
                    mediaContent2 += `<img src="${data}" />`;
                }
            }

            htmlContentWithDescription +=
            `<div class ="question_heading"> 
                <div class="pdf_qus_opt">
                    <div class="pdfQuestionheading questions_with_img"><span>${index + 1}.</span><div>${checkText(element?.text) ? element.text : mediaContent2}</div></div> 
                    <div>
                        ${checkText(element?.text) ? mediaContent2 : ""}
                    </div>
                    ${validateOptions(element.mcqData ? JSON.parse(element?.mcqData) : [])}
                    
                    <div class="pdfQuestionheading correct_heading"><span class="pdfcorrectAnswer">Correct Answer - </span>${correctAnswerFunction(element.mcqData, element, index)}</div>
                </div>
                    <div class="pdfDescription">${element?.description}</div>
                    <div class="border_seprator">
                      <p></p>
                    </div> 
            </div>`;
        }
        setPdfContentDescription(htmlContentWithDescription)

        let descriptionHtmlOnly = `
            <div class="pdfBody">
            <p class="pdfHeadtext ${allCoursedata[6] ? "" : "border_bottom_pdf"}">${allCoursedata[4]}</p>
            <p class="pdfCourseDescription">${allCoursedata[6]}</p>
            `

        for (let index = 0; index < treeData.length; index++) {
            const element = treeData[index]
            let answerText
            if (checkIndex(element.correctAnswer, element) >= 0) {
                answerText = element?.mcqData ? JSON.parse(element?.mcqData)[element.correctAnswer] : ""
            } else {
                answerText = ""
            }

            let mediaContent2 = ""; // Initialize variable for media content
            if (element?.Media && element.Media.length > 0) {
                for (let j = 0; j < element.Media.length; j++) {
                    const data = element.Media[j];
                    mediaContent2 += `<img src="${data}" />`;
                }
            }

            descriptionHtmlOnly +=
                `<div class ="question_heading"> 
                    <div class="pdfQuestionheading"><span>${index + 1}.</span><div>${element?.description}</div></div> 
                    <div>
                        ${mediaContent2}
                    </div>
                    <div class="pdfQuestionheading correct_heading"><span class="pdfcorrectAnswer">Correct Answer - </span>${correctAnswerFunction(element.mcqData, element)}</div>
                    <div class="border_seprator">
                    <p></p>
                    </div> 
                </div>
            `;
        }
        setPdfContentDescriptionOnly(descriptionHtmlOnly)
    }, [treeData])

    const content = <ul className='pdfContent'>
        <li>
            <p onClick={handlePrint}>Export Questions</p>
        </li>
        <li>
            <p onClick={handlePrintWithDescription}>Export Question, Answer, Description</p>
        </li>
        <li>
            <p onClick={handlePrintWithDescriptionOnly}>Export Answer, Description</p>
        </li>
    </ul>


    const checkData = () => {
        const data = treeData.filter((item: any) => !item.id)
        return data.length
    }

    return (
        checkData() ? null :
            <div key={treeData.length}>
                <div style={{ display: 'none' }}>
                    <div ref={componentRef} >
                        <div dangerouslySetInnerHTML={{ __html: pdfContent }} />
                    </div>
                </div>
                <div style={{ display: 'none' }}>
                    <div ref={componentRefWithDiscription} >
                        <div dangerouslySetInnerHTML={{ __html: pdfContentDescription }} />
                    </div>
                </div>
                <div style={{ display: 'none' }}>
                    <div ref={componentRefWithDiscriptionOnly} >
                        <div dangerouslySetInnerHTML={{ __html: pdfContentDescriptionOnly }} />
                    </div>
                </div>
                {/* <Button className='exportPdf' btnText="Export PDF" onClick={handlePrint} />
                <Button className='exportPdf' btnText="Export PDF with description" onClick={handlePrintWithDescription} />
                <Button className='exportPdf' btnText="Export PDF with description only" onClick={handlePrintWithDescriptionOnly} /> */}
                <div className='downloadPdfIcon'>
                    <PopoverComp {...{ content }} ><DownloadIcon /> </PopoverComp>
                </div>

            </div>
    );
};

export default PDFWritter;