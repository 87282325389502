import React, { useState, useEffect, forwardRef, useCallback, useImperativeHandle } from 'react';
import { ChildComponentRef } from '../../../screens/creatorStudioStudent';
import "./index.css"
import { setStorageFunction } from '../../../utils/CommonFunction';

interface ChildComponentProps { }


const CountdownTimer = forwardRef<ChildComponentRef, ChildComponentProps>((props, ref) => {

    const [timeLeft, setTimeLeft] = useState<number>(0);


    useEffect(() => {
        const endTime = localStorage.getItem('endTime');
        if (endTime) {
            const timeRemaining = new Date(endTime).getTime() - new Date().getTime();
            if (timeRemaining > 0) {
                setTimeLeft(timeRemaining);
            } else {
                setTimeLeft(0);
                localStorage.removeItem('endTime');
            }
        }
    }, []);

    useEffect(() => {
        if (timeLeft > 0) {
            const intervalId = setInterval(() => {
                const newTimeLeft = timeLeft - 1000;
                setTimeLeft(newTimeLeft);
                setStorageFunction('timeLeft', newTimeLeft.toString());
                if (newTimeLeft <= 0) {
                    clearInterval(intervalId);
                    localStorage.removeItem('endTime');
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [timeLeft]);

    const startTimer = () => {
        const totalTime = localStorage.getItem("totalTime")
        if (totalTime) {
            const currentTime = new Date();
            const endTime = new Date(currentTime.getTime() + (+totalTime * 60 * 1000));
            setTimeLeft(+totalTime * 60 * 1000);
            setStorageFunction('endTime', endTime.toString());
        }
    };
    useImperativeHandle(ref, () => ({
        callChildFunction: startTimer,
    }), [startTimer]);

    const formatTime = (milliseconds: number): string => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };


    return (

        <div className='left_time'>
            <strong>Time left:</strong> {formatTime(timeLeft)}
        </div>
    );
});

export default CountdownTimer;
