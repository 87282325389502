import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React from 'react';

interface PopclickProps {
    content: any;
    children: any;
    placement?: TooltipPlacement;
    className?: any;
    handleOpenChange: (e:boolean) => void;
    open: boolean
}

const Popclick: React.FC<PopclickProps> = ({ children, content, handleOpenChange, className, placement ,open }) => {
    return (
        <Popover open={open} className={className} onOpenChange={handleOpenChange} content={content} placement={placement} trigger="click">
            {/* <Icon iconName="Download" ></Icon> */}
            {children}
        </Popover>
    );
};

export default Popclick;