import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CourseBox from '../../components/Common/courseBox';
import Button from '../../components/Common/Button/Button';
import { DefaultButton, Icon, PrimaryButton } from '@fluentui/react';
import { alphanumericSort, checkAi, convertStamptoDate, convertSubjectData, convertTimeStamp, dandTInTimestamp, encryption, returnMatchedArray,  searchOnlyParent, timestampToDateandTime } from '../../utils/CommonFunction';
import CommonModal from '../../components/commonModal/CommonModal';
import { DeleteModalContent } from './courseModalContent/deleteModal';
import ScheduleModal from '../../components/Common/scheduleModal';
import { PublishNowModal, UnPublishModal } from './courseModalContent/publishNowModal';
import { GetCoursePagesNew, addCourse, deleteCourse, editCourse, getTopics, getUserProfile, updatePublishStatus } from '../../redux/actions/API/courses';
import { useNavigate } from 'react-router-dom';
import { creatorStudio } from '../../utils/routePath';
import { setAppLocation } from '../../redux/actions/UI/appLocation';
import { Tooltip } from 'antd';
import "./index.css"
import AddEditCourse from './courseModalContent/addEditCourse';
import { useFormik } from 'formik';
import { courseInitialValue } from '../../utils/formikInitial';
import { addCourseSchema } from '../../utils/validationSchema';
import moment from 'moment';

import { editConfig } from '../../redux/actions/API/departmentClasses';
import { fetchPost, postAPiCall } from '../../API/Baseurl';
import ConfigModal from '../dtmanager/configModal';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { Button as MuiButton, TextField } from '@mui/material';
interface CourseProps {
    // Define your props here
}
interface configInterface {
    subjectId: number | null,
    chapter: any[],
    topic: any[],
    interval: any,
    date: any,
    time: any,
    timeStamp: any,
    numQuestions: any,
    testTime: any,
    correct: any,
    wrong: any,
    easy: any,
    medium: any,
    hard: any,
    selectedSub: any,
    chapterData: any[],
    topicData: any[],
    selectedChapter: any[],
    selectedTopic: any[],
    availablity: any
}

const initialConfigData = {
    subjectId: null,
    chapter: [],
    topic: [],
    interval: "",
    date: moment().format('YYYY-MM-DD'),
    time: "",
    timeStamp: "",
    numQuestions: 0,
    testTime: "",
    correct: "",
    wrong: 0,
    easy: 0,
    medium: 0,
    hard: 0,
    selectedSub: {},
    chapterData: [],
    topicData: [],
    selectedChapter: [],
    selectedTopic: [],
    availablity: {}
}
const defaultConfigData = {
    subjectId: null,
    chapter: [],
    topic: [],
    interval: "",
    time: "",
    timeStamp: "",
    date: moment().format('YYYY-MM-DD'),
    numQuestions: 0,
    testTime: "",
    correct: "",
    wrong: 0,
    easy: 0,
    medium: 0,
    hard: 0,
    selectedSub: {},
    chapterData: [],
    topicData: [],
    selectedChapter: [],
    selectedTopic: [],
    availablity: {}
}

const Course: React.FC<CourseProps> = (props) => {
    const allCoursesData = useSelector((state: any) => state.api.getStudentCourses?.data?.courses) || []
    const getTopicData = useSelector((state: any) => state?.api?.getTopics?.data)
    const screenpropsData = useSelector((state: any) => state.ui.screenProps.data?.Homeworks) || {}
    const screenProps = useSelector((state: any) => state.ui.screenProps.data?.Homeworks)
    const allDepartmentData = useSelector((state: any) => state.api.getDepartmentClasses?.data)
    const [courseData, setCourseData] = useState<any[]>([])
    const [allCourseData, setAllCourseData] = useState<any[]>([])
    const [ searchValue, setSearchValue] = useState<string>("")
    const [modalName, setModalName] = useState<string>("")
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [currentCourseData, setCurrentCourseData] = useState<any>({})
    const [selectedDate, setSelectedDate] = useState<any>(new Date())
    const [selectedTime, setSelectedTime] = useState<any>("")
    const [selectedMinute, setSelectedMinute] = useState<any>("")
    const [publishWithResultStatus, setPublishWithResultStatus] = useState<string>("")
    const [courseModal, setCourseModal] = useState<boolean>(false)
    const [courseModalName, setCourseModalName] = useState<string>("")
    const [configModal, setConfigModal] = useState<boolean>(false)
    const [isEditableCourse, setIsEditableCourse] = useState<boolean>(false)
    const [configArray, setConfigArray] = useState<configInterface[]>([initialConfigData])
    const [currentConfig, setCurrentConfig] = useState<number>(0)
    const [subjectData, setSubjectData] = useState<any[]>([])
    const [currentClass, setCurrentClass] = useState<any>({})
    const [isJee, setIsJee] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [dropdownKey, setDropdownKey] = useState<number>(0)
    const navigate = useNavigate()
    const [questionError, setQuestionError] = useState<boolean>(false)

    useEffect(() => {
        dispatch(getTopics())
    }, [])
    useEffect(() => {
        if (getTopicData) {
            setSubjectData(convertSubjectData(getTopicData))
        }
    }, [getTopicData])

    const { handleChange, setFieldValue, handleSubmit, handleBlur, resetForm, values, errors, touched } = useFormik({
        initialValues: courseInitialValue,
        validationSchema: addCourseSchema,
        onSubmit: () => submitCourse(),
    });
    useEffect(() => {
        const data = allDepartmentData[0]?.classes?.filter((item: any) => item.classId === screenProps.classId)
        setCurrentClass(data[0])
    }, [JSON.stringify(allDepartmentData[0]?.classes)])

    const courseUpdateFunc = () => {
        let data = {}
        if (values.courseType === "Regular") {
            data = {
                departments: values.departments,
            }
        } else {
            data = {
                departments: values.departments,
                correctScoring: +(values.correctScoring),
                wrongScoring: +(values.wrongScoring),
                testTime: +(values.testTime),
                isJee,
                partialCorrect: values.partialScoring
            }
        }
        const stringData = JSON.stringify(data)
        if (courseModalName === "Add Course") {
            const payload = {
                data: {
                    courseName: values.courseName,
                    courseCode: values.courseCode,
                    courseType: values.courseType,
                    courseDataJson: stringData,
                    classIds: values.classIds,
                    teacherIds: values.teacherIds,
                    courseDescription: values.courseDescription,
                    publishStatus: values.publishStatus,
                    allowedAttempts: values.allowedAttempts,
                    file: null,
                },
                classId: screenProps.classId
            }
            dispatch(addCourse(payload))
        }
        else if (courseModalName === "Edit Course") {
            const payloadUpdate = {
                data: {
                    courseId: currentCourseData.courseId,
                    courseName: values.courseName,
                    courseCode: values.courseCode,
                    courseDescription: values.courseDescription,
                    courseDataJson: stringData,
                    publishStatus: 2,
                    allowedAttempts: values.allowedAttempts,
                    courseType: values.courseType,
                    classIds: values.classIds,
                    teacherIds: values.teacherIds,
                },
                classId: screenProps.classId
            }
            dispatch(editCourse(payloadUpdate))
        }
        setCourseModalName("")
        setCourseModal(false)
        resetForm()
    }


    const submitCourse = () => {
        if (!values.classIds.length) {
            Swal.fire({
                text: `Since no class has been selected, the course will not appear for the student.`,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#b3b2b2",
                confirmButtonText: "Yes",
                title: "Are you sure?",
            }).then((result) => {
                if (result.isConfirmed) {
                    courseUpdateFunc()
                }
            });
        } else {
            courseUpdateFunc()
        }
    }

    useEffect(() => {
        const filteredData = allCoursesData?.filter((item: any) => {
            return item?.classIds?.includes(screenpropsData.classId)
        })
        const sortedData = filteredData.sort((a: any, b: any) => alphanumericSort(a.courseName.trim(), b.courseName.trim()))
        setCourseData([...sortedData])
        setAllCourseData([...sortedData])
    }, [allCoursesData])


    const editClick = (item: any, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setCourseModalName("Edit Course")
        setFieldValue("allowedAttempts", item.allowedAttempts)
        setFieldValue("classIds", item.classIds)
        setFieldValue("courseName", item.courseName)
        setFieldValue("courseCode", item.courseCode)
        setFieldValue("courseType", item.courseType)
        setFieldValue("teacherIds", item.teacherIds)
        setFieldValue("publishStatus", item.publishStatus)
        if (item?.courseDataJson) {
            const data = JSON.parse(item?.courseDataJson)
            if (data?.partialCorrect) {
                setFieldValue("partialScoring", data?.partialCorrect)
            }
        }
        setFieldValue("courseDescription", item.courseDescription)
        const courseData = JSON.parse(item.courseDataJson)
        setFieldValue("correctScoring", courseData.correctScoring)
        setFieldValue("wrongScoring", courseData.wrongScoring)
        setFieldValue("testTime", courseData.testTime)
        setIsJee(item.courseDataJson ? (JSON.parse(item.courseDataJson).isJee) : false)
        setFieldValue("departments", courseData.departments)
        setCurrentCourseData(item)
        setCourseModal(true)
    }

    const classClick = (item: any, name: string, department: any) => {
        dispatch(GetCoursePagesNew({ courseId: item.courseId, auto: checkAi(item.courseCode) }))
        dispatch(setAppLocation({ pathname: `/creator-studio/${item.courseId}/${item.courseType}/${item.courseName}/${item.courseCode}/${item.courseDescription}/${item.courseDataJson ? JSON.parse(item.courseDataJson).testTime : 60}/${item.courseDataJson ? (JSON.parse(item.courseDataJson).isJee ? "jee_true" : "") : ""}` }))
        navigate(creatorStudio)
    }

    const deleteClick = (item: any, name: string, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setCurrentCourseData(item)
        setModalName("Delete Course")
        setModalOpen(true)
    }
    const analyticClick = (item: any, name: string, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        navigate(`/analytic/${encryption(item.courseId)}/${encryption(screenpropsData.classId)}/${encryption(item.courseCode)}/${encryption(item.leftAttempts)}`)
    }
    const publishClick = (item: any, e: React.MouseEvent<HTMLElement>, status: number) => {
        e.stopPropagation()
        if (status === 2) {

        } else {
            setModalName('Unpublish')
            setModalOpen(true)
        }
        setCurrentCourseData(item)
    }
    const publishNowClick = (item: any, e: any, type: string) => {
        e.stopPropagation()
        setModalName(type)
        setModalOpen(true)
    }
    const scheduleClick = (e: any, type: string) => {
        e.stopPropagation()
        setModalName(type)
        setModalOpen(true)
    }

    const popoverContent = <div className="publishPop">
        <ul>
            <MuiButton variant="outlined" onClick={(e: any) => publishNowClick(currentCourseData, e, "Publish Now")}>Publish Now</MuiButton>
            <MuiButton variant="outlined" onClick={(e: any) => scheduleClick(e, "Scheduled")}>Schedule Time</MuiButton>
        </ul>
    </div>
    const handleClose = () => {
        setModalName("")
        setCourseModal(false)
        setCourseModalName("")
        setModalOpen(false)
        resetForm()
    }

    const confirmDlt = () => {
        dispatch(deleteCourse({
            courseId: currentCourseData.courseId,
            classId: screenProps.classId
        }))
        handleClose()
    }

    const selectDate = (e: any, timeString: any) => {
        setSelectedDate(timeString)
    }
    const selectTime = (e: any, timeString: any) => {
        setSelectedTime(timeString)
    }

    const minuteChange = (e: any) => {
        setSelectedMinute(e.target.value)
    }
    const changeStatus = (e: any) => {
        setPublishWithResultStatus(e)
    }

    console.log("modalNamemodalName , ", courseModal);

    const applyPublishStatus = (value: any) => {
        if (modalName === "Scheduled") {
            if (publishWithResultStatus) {
                return publishWithResultStatus
            } else {
                return "1"
            }
        }
        else {
            if (value == 1 || value == 3) {
                return "2"
            } else if (value == 2) {
                return "1"
            }
        }
    }

    const showScheduledDate = (data: string, activeTime?: string) => {
        if (data && data !== "null") {
            const array = data.split(" ")
            if (array[1] !== "NaN") {
                if (activeTime) {
                    return convertStamptoDate(array[1], array[2])
                } else {
                    return convertStamptoDate(array[1])
                }
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    const modalContent = () => {
        if (modalName === "Delete Course") {
            return <DeleteModalContent {...{ confirmDlt, handleClose, modalName }} />
        } else if (modalName === "Scheduled") {
            return <ScheduleModal
                {...{ selectedDate, selectedTime, minuteChange }}
                minValue={selectedMinute}
                selectDate={(e: any, timeString: any) => selectDate(e, timeString)}
                selectTime={(e: any, timeString: any) => selectTime(e, timeString)}
                taskValue={currentCourseData.task}
                handleChange={changeStatus}
            />
        } else if (modalName === "Publish Now") {
            return <PublishNowModal />
        } else if (modalName === "Unpublish") {
            return <UnPublishModal />
        }
    }


    const confirmPublishClick = async () => {
        const publishStatus = currentCourseData.publishStatus
        const courseId = currentCourseData.courseId
        const taskData = `${courseId} ${convertTimeStamp(selectedDate, selectedTime)} ${selectedMinute} ${applyPublishStatus(publishStatus)}`
        const updateData: any = {
            publishData: {
                CourseId: courseId,
                PublishStatus: applyPublishStatus(publishStatus),
                Task: modalName === "Scheduled" ? taskData : null
            },
            analyticData: {
                courseId: courseId,
                classId: screenpropsData.classId
            },
            name: "updateClass"
        }
        dispatch(updatePublishStatus(updateData))
        setModalName("")
        setModalOpen(false)

    }

    const confirmCourseClick = (e: any) => {
        debugger
        e.preventDefault()
        handleSubmit()

    }

    const getDateAndTimeArray = (elem: any) => {
        const taskValue = elem.task
        if (taskValue && taskValue !== "null") {
            const dateArray = taskValue.split(" ")
            const dateAndTime = convertStamptoDate(dateArray[1]).split(", ")
            setSelectedDate(dateAndTime[0])
            setSelectedTime(dateAndTime[1])
            setSelectedMinute(dateAndTime[2])
        }
    }
    const scheduleEdit = (e: any, item: any) => {
        e.stopPropagation()
        getDateAndTimeArray(item)
        setModalName("Scheduled")
        setCurrentCourseData(item)
        setModalOpen(true)
    }

    const addCourseModalClick = (name: string, item?: any) => {
        setFieldValue("departments", [screenProps.departmentId])
        const currentClass = returnMatchedArray(allDepartmentData, [screenProps.departmentId])
        setFieldValue("classIds", [screenProps.classId])
        setCourseModalName(name)
        setCourseModal(true)
        setIsEditableCourse(false)
    }

    const editCourseBtnClick = () => {
        setIsEditableCourse(!isEditableCourse)
    }
    const courseChange = (e: any, val: any) => {
        setFieldValue("courseType", val)
    }
    const handleCloseConfig = () => {
        setConfigModal(false)
    }

    const addTestClick = () => {
        setCurrentConfig(configArray.length)
        const newData = JSON.parse(JSON.stringify(defaultConfigData))
        configArray.push(newData)
        setConfigArray([...configArray])
    }


    const subjectClick: any = (event: any, index: number, item: any) => {
        setCurrentConfig(index)
        setDropdownKey(dropdownKey + 1)
    }
    const subjectChange = (e: any, val: any) => {
        configArray[currentConfig].selectedSub = val
        configArray[currentConfig].subjectId = val ? val.id : null;
        configArray[currentConfig].chapterData = val ? val.data : [];
        setConfigArray([...configArray])
        setDropdownKey(dropdownKey + 1)
    }

    const configChange = (e: any, name: string) => {
        const regex = /^\d*$/; // Regex to allow only numbers and empty string
        if (regex.test(e) || e === "") {
            if (name === "interval") {
                configArray[currentConfig].interval = Number(e)
                setConfigArray([...configArray])
            } else if (name === "testTime") {
                configArray[currentConfig].testTime = Number(e)
                setConfigArray([...configArray])
            } else if (name === "numQuestions") {
                configArray[currentConfig].easy = 0
                configArray[currentConfig].medium = 0
                configArray[currentConfig].hard = 0
                configArray[currentConfig].numQuestions = Number(e)
                setConfigArray([...configArray])
            } else if (name === "correct") {
                configArray[currentConfig].correct = Number(e)
                setConfigArray([...configArray])
            } else if (name === "wrong") {
                configArray[currentConfig].wrong = Number(e)
                setConfigArray([...configArray])
            }
        }
    }

    type ConfigKeys = 'easy' | 'medium' | 'hard';

    const conFigSliderChange = (e: any, val: any, data: any, name: ConfigKeys) => {
        const totalQuestions = configArray[currentConfig].numQuestions;

        const availableEasy = configArray[currentConfig].availablity?.Easy;
        const availableMedium = configArray[currentConfig].availablity?.Medium;
        const availableHard = configArray[currentConfig].availablity?.Hard;

        if (name === "easy") {
            configArray[currentConfig].easy = Math.min(val, availableEasy);
        }
        else if (name === "medium") {
            configArray[currentConfig].medium = Math.min(val, availableMedium);
        }
        else if (name === "hard") {
            configArray[currentConfig].hard = Math.min(val, availableHard);
        }

        const selectedEasy = configArray[currentConfig].easy;
        const selectedMedium = configArray[currentConfig].medium;
        const selectedHard = configArray[currentConfig].hard;

        const totalSelected = selectedEasy + selectedMedium + selectedHard;

        if (totalSelected > totalQuestions) {
            if (name === "easy") {
                configArray[currentConfig].easy = Math.max(0, totalQuestions - selectedMedium - selectedHard);
            } else if (name === "medium") {
                configArray[currentConfig].medium = Math.max(0, totalQuestions - selectedEasy - selectedHard);
            } else if (name === "hard") {
                configArray[currentConfig].hard = Math.max(0, totalQuestions - selectedEasy - selectedMedium);
            }
        }

        setConfigArray([...configArray]);
    };

    const timeChange = (e: any, timeString: any) => {
        configArray[currentConfig].timeStamp = timeString
        configArray[currentConfig].time = dandTInTimestamp(`${configArray[currentConfig].date}T${timeString}:00`)
        setConfigArray([...configArray])
    }
    const closeConfig = () => {
        setConfigModal(false)
    }
    const configClick = () => {
        const item = currentClass || {}

        setCurrentConfig(0)
        if (item.config) {
            const data = JSON.parse(item.config)
            const processItems = async () => {
                const processedData = await Promise.all(
                    data.map(async (item: any) => {
                        const sData = subjectData.filter((val: any) => val.id === item.subjectId);
                        item.selectedSub = sData[0];
                        item.chapterData = sData[0].data;
                        if (item.chapter) {
                            const chap = sData[0].data.filter((elem: any) => item.chapter.includes(elem.id))
                            item.selectedChapter = chap
                        } else {
                            item.selectedChapter = sData[0].data
                        }
                        const topicss = item.selectedChapter.map((elem: any) => elem.data).flat()
                        item.topicData = topicss
                        if (item.topic) {
                            if (item.topic.length) {
                                const tops = topicss.filter((elem: any) => item.topic.includes(elem.id))
                                item.selectedTopic = tops
                            } else {
                                item.selectedTopic = topicss
                            }
                        } else {
                            item.selectedTopic = topicss

                        }
                        item.date = timestampToDateandTime(item.time).date;
                        item.timeStamp = timestampToDateandTime(item.time).time.trim();
                        item.timeStamp = item.timeStamp ? dayjs(item.timeStamp.toUpperCase(), 'hh:mm:ss A').format('HH:mm') : item.timeStamp;
                        item.date = item.date ? dayjs(item.date, 'DD/MM/YYYY').format('YYYY-MM-DD') : item.date;

                        const topicId = item.selectedTopic.map((item: any) => item.id);
                        const chapterIdId = item.selectedChapter.map((item: any) => item.id);
                        const subjectIds = item.selectedSub.id;
                        const payloadData = {
                            subjectLst: [subjectIds].length ? [subjectIds] : null,
                            chapterLst: chapterIdId.length ? chapterIdId : null,
                            topicLst: topicId.length ? topicId : null,
                        };

                        // Use await for the API call
                        const response = await fetchPost("CreatorStudios/GetAvailablePages", payloadData);
                        item.availablity = response
                        return item;
                    })
                );

                return processedData;
            };

            processItems()
                .then((result) => {
                    setConfigArray([...result])
                })
                .catch((error) => {
                    console.error('Error processing data:', error);
                });


        } else {
            setConfigArray([initialConfigData])
        }
        setConfigModal(true)
    }

    const checkFilledValidation = (data: configInterface) => {
        return (
            data.subjectId !== null &&
            data.interval !== null &&
            data.date !== "" &&
            data.timeStamp !== "" &&
            data.numQuestions &&
            data.testTime !== null &&
            data.correct !== null &&
            data.correct !== "" &&
            data.wrong !== null &&
            data.wrong !== "" &&
            (data.easy !== null ||
                data.medium !== null ||
                data.hard !== null)
        );
    }


    const updateConfigClick = (e: any) => {
        e?.preventDefault()

        const data = configArray.filter((item: any) => checkFilledValidation(item))
        if (data.length === configArray.length) {
            configArray.forEach((item: any) => {
                item.chapter = item.selectedChapter?.map((chapter: any) => chapter.id)
                item.topic = item.selectedTopic?.map((topic: any) => topic.id)
            });
            const configData = configArray.map(({ selectedSub, timeStamp, date, selectedChapter, selectedTopic, chapterData, topicData, availablity, ...rest }) => {
                return {
                    ...rest,
                };
            });


            dispatch(editConfig({
                "classId": screenProps.classId,
                "config": JSON.stringify(configData)
            }))
            setConfigModal(false)
        } else {
            Swal.fire({
                text: `Your test has some missing data. If you save it without filling in those details, it will be removed.`,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#b3b2b2",
                confirmButtonText: "Yes",
                title: "Are you sure, to save this test?",
            }).then((result) => {
                if (result.isConfirmed) {
                    data.forEach((item: any) => {
                        item.chapter = item.selectedChapter?.map((chapter: any) => chapter.id)
                        item.topic = item.selectedTopic?.map((topic: any) => topic.id)
                    });
                    const configData = data.map(({ selectedSub, timeStamp, date, selectedChapter, selectedTopic, chapterData, topicData, availablity, ...rest }) => {
                        return {
                            ...rest,
                        };
                    });


                    dispatch(editConfig({
                        "classId": screenProps.classId,
                        "config": JSON.stringify(configData)
                    }))
                    setConfigArray([...data])
                    setConfigModal(false)
                }
            });
        }




    }

    const chapterChange = (e: any, val: any) => {
        configArray[currentConfig].selectedChapter = [...val]
        const topicValue = val.map((item: any) => item.data).flat()
        const topicName = configArray[currentConfig].selectedTopic.map((item: any) => item.name)
        const selectedTopicValue = topicValue.filter((item: any) => topicName.includes(item.name))
        configArray[currentConfig].topicData = [...topicValue]
        configArray[currentConfig].selectedTopic = [...selectedTopicValue]
        setConfigArray([...configArray])
    }
    const topicChange = (e: any, val: any) => {
        configArray[currentConfig].selectedTopic = [...val]
        setConfigArray([...configArray])
    }

    const seeAvailable = async () => {
        const topicId = configArray[currentConfig].selectedTopic?.map((item: any) => item.id)
        const chapterIdId = configArray[currentConfig].selectedChapter?.map((item: any) => item.id)
        const subjectIds = configArray[currentConfig].selectedSub.id
        const payloadData = {
            subjectLst: subjectIds ? [subjectIds] : null,
            chapterLst: chapterIdId?.length ? chapterIdId : null,
            topicLst: topicId.length ? topicId : null
        }
        const response = await postAPiCall("CreatorStudios/GetAvailablePages", payloadData)
        if (response.data) {
            setQuestionError(false)
            configArray[currentConfig].availablity = response.data
            setConfigArray([...configArray])
        } else {
            setQuestionError(false)
            configArray[currentConfig].availablity = {}
            setConfigArray([...configArray])
        }

    }

    const selectAllChapter = (e: any) => {
        if (e.target.checked) {
            configArray[currentConfig].selectedChapter = configArray[currentConfig].chapterData
            configArray[currentConfig].topicData = configArray[currentConfig].chapterData.map((item: any) => item.data).flat()
            setConfigArray([...configArray])
        } else {
            configArray[currentConfig].selectedChapter = []
            configArray[currentConfig].topicData = []
            configArray[currentConfig].selectedTopic = []
            setConfigArray([...configArray])
        }
    }
    const selectAllTopic = (e: any) => {
        if (e.target.checked) {
            configArray[currentConfig].selectedTopic = configArray[currentConfig].topicData
            setConfigArray([...configArray])
        } else {
            configArray[currentConfig].selectedTopic = []
            setConfigArray([...configArray])
        }
    }

    const setMaxNumber = (name: string) => {
        if (configArray[currentConfig]?.availablity) {

            if (Object.keys(configArray[currentConfig]?.availablity).length) {
                if (configArray[currentConfig]?.availablity[name] < configArray[currentConfig]?.numQuestions) {
                    return configArray[currentConfig]?.availablity[name]
                } else {
                    return configArray[currentConfig]?.numQuestions
                }
            } else {
                return 0
            }

        } else {
            return 0
        }

    }
    const jeeChange = (e: any) => {
        setIsJee(e)
    }

    const showClassName = (ids: number[]) => {
        const classData = allDepartmentData.map((item: any) => item.classes).flat()
        const classesNames = classData.filter((item: any) => ids.includes(item.classId)).map((val: any) => val?.classGrade)
        return classesNames.toString()
    }

    const showButton = () => {
        if (modalName === "Delete Course") {
            return false
        } else if (courseModal) {
            return false
        } else {
            return true
        }
    }

    const searchChange = (e: any) => {
        const searchQuery = e.target.value
        console.log("allCourseData" , allCourseData);
        
        if (searchQuery) {
         
            const filteredData = searchOnlyParent(allCourseData, searchQuery)
            setCourseData([...filteredData])
          
        } else {
     
            setCourseData([...allCourseData])
          
        }
        setSearchValue(e.target.value)
      }

    return (
        <div>

            <CommonModal modalOpen={configModal} handleClose={handleCloseConfig} className="add_course_modal teacher_modal config_modal"
                BackdropProps={{
                    onClick: null as any,
                }}
            >
                <ConfigModal {...{
                    configArray, currentConfig, subjectClick, subjectData, addTestClick,
                    subjectChange, topicChange, chapterChange, seeAvailable, configChange, selectAllChapter, selectAllTopic, questionError,
                    conFigSliderChange, setMaxNumber, selectDate, timeChange, closeConfig
                }} saveClick={updateConfigClick} />
            </CommonModal >

            <CommonModal handleClose={handleClose} className="publish-popup" modalOpen={modalOpen}  >
                {modalContent()}
                {modalName !== "Delete Course" ?
                    <div className="footerBtn">
                        <PrimaryButton
                            onClick={confirmPublishClick}
                            text="Yes"
                        />
                        <DefaultButton
                            onClick={handleClose}
                            text="No"
                        />
                    </div> : ""}
            </CommonModal>
            <CommonModal width={500} handleClose={handleClose} modalOpen={courseModal}  >
                <AddEditCourse onSubmit={confirmCourseClick}  {...{ values, touched, handleClose, errors, handleChange, handleBlur, setFieldValue, isEditableCourse, courseChange, jeeChange, isJee }} labelText={courseModalName} />
                {showButton() ?
                    <div className="footerBtn">
                        <PrimaryButton
                            onClick={confirmCourseClick}
                            text={isEditableCourse ? "Update" : "Add"}
                        />
                        <DefaultButton
                            onClick={handleClose}
                            text={isEditableCourse ? "Cancel" : "No"}
                        />
                    </div> : ""}
            </CommonModal>

            <div className="page_header">
                <div className="refresh_with_time header_with_search">
                    <TextField value={searchValue} className='search_field' onChange={(e: any) => searchChange(e)} size="small" id="outlined-basic" label="Search here" variant="outlined" />
                    <Button btnText={
                        <>
                            <Icon
                                iconName="Edit"
                                className="homeworkIcons"
                                title="Edit Course"
                            />
                            <span> {isEditableCourse ? "View" : "Edit"}</span>
                        </>

                    } onClick={editCourseBtnClick}
                        className={`${isEditableCourse ? "buttonWithicon activeEditAllCourse" : " buttonWithicon enableEditAllCourse"}`}
                        disabled={false} />

                </div>
            </div>

            <div className="homeworkElementsContainer">
                <div className='add_container'>
                    <div className='config_container'>

                        <div
                            className="addSubject"
                            style={{ background: "#E6E6E6", cursor: "pointer" }}
                            onClick={() => addCourseModalClick("Add Course")}
                        >
                            <Tooltip title="Add Course">
                                <div className="addNewSubject">
                                    <Icon iconName="add" className="addSubjectIcon" />
                                    <p className="add_course_text">Add</p>
                                </div>
                            </Tooltip>
                        </div>
                        {currentClass?.section && (currentClass?.section.toLowerCase()) === "ai" ?
                            <div
                                className="addSubject"
                                style={{ background: "#E6E6E6", cursor: "pointer" }}
                                onClick={configClick}
                            >
                                <Tooltip title="Edit Config">
                                    <div className="addNewSubject">
                                        <Icon iconName="EditSolidMirrored12" className="addSubjectIcon" />
                                        <p className="add_course_text">Edit Config</p>
                                    </div>
                                </Tooltip>
                            </div> : ""}
                    </div>

                    <div className="allCourses">
                        {courseData?.map((item: any) => {
                            return (
                                <CourseBox classes={showClassName(item?.classIds)}   {...{ editClick, classClick, item, deleteClick, publishClick, popoverContent, scheduleEdit, analyticClick }} editStatus={isEditableCourse} scheduleEndTime={showScheduledDate(item.task, "activeTime")} scheduleStartTime={showScheduledDate(item.task)} showPublish={true} name="All Course" />
                            )
                        })}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Course;