/* eslint-disable react/prop-types */
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import ListAltIcon from "@mui/icons-material/ListAlt";
import {  InsertDriveFileOutlined } from "@mui/icons-material";

interface TypeIconProps {
    droppable: boolean;
    fileType: string;
}

export const TypeIcon = ({ droppable, fileType }: TypeIconProps) => {
    if (droppable) {
        return <FolderIcon />;
    }

    switch (fileType) {
        case "image":
            return <ImageIcon />;
        case "csv":
            return <ListAltIcon />;
        case "text":
            // return <DescriptionOutlined />;
            return <InsertDriveFileOutlined />;
        default:
            return null;
    }
};
