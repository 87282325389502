
export const toolbarOptions = [
  // [{ 'font': [] }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  ['bold', 'italic', 'underline', 'strike', "formula"],        // toggled buttons
  ['link', 'image', 'video'],            // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'align': [] }],                                    // remove formatting button
  ['omega']
];


export const CUSTOM_OPERATORS = [
  ['\\sqrt{a}', '\\sqrt'],
  ['\\sqrt[2]{a}', '\\sqrt[2]{}'],
  ['\\sqrt[3]{a}', '\\sqrt[3]{}'],
  ['\\sqrt[b]{a}', '\\sqrt[b]{}'],
  ['\\log', '\\log'],
  ['\\log_{10}', '\\log_{10}'],
  ['\\log{_x}', '\\log_{}'],
  ['n \\choose k', '\\choose'],
  ['\\sum', '\\sum'],
  ['\\prod', '\\prod'],
  ['\\pi', '\\pi'],
  ['\\tau', '\\tau'],
  ['\\infty', '\\infty'],
  ['+', '+'],
  ['-', '-'],
  ['\\cdot', '\\cdot'],
  ['\\times', '\\times'],
  ['\\div', '\\div'],
  ['\\frac{a}{b}', '\\frac'],
  ['=', '='],
  ['\\neq', '\\neq'],
  ['>', '>'],
  ['<', '<'],
  ['\\ge', '\\ge'],
  ['\\le', '\\le'],
  ['\\approx', '\\approx'],
  ['\\cong', '\\cong'],
  ['\\sim', '\\sim'],
  ['\\pm', '\\pm'],
  ['\\\\a{^2}', '^2'],
  ['\\\\a{^b}', '^{}'],
  ['\\\\a{_b}', '_{}'],
  ['\\lvert{x}\\rvert', '\\mid{}\\mid'],
  ['\\overline{ab}', '\\overline'],
  ['\\underline{ab}', '\\underline'],
  ['\\overleftarrow{AB}', '\\overleftarrow'],
  ['\\overrightarrow{AB}', '\\overrightarrow'],
  ['\\overleftrightarrow{AB}', '\\overleftrightarrow'],
  ['90\\degree', '\\degree'],
  ['\\measuredangle', '\\measuredangle'],
  ['\\angle', '\\angle'],
  ['\\equiv', '\\equiv'],
  ['\\perp', '\\perp'],
  ['\\parallel', '\\parallel'],
  ['\\triangle', '\\triangle'],
  ['\\int', '\\int'],
  ['\\oint', '\\oint'],
  ['\\partial', '\\partial'],
  ['\\frac{dx}{dy}', '\\dfrac{dx}{dy}'],
  [
    '\\frac{\\partial{x}}{\\partial{y}}',
    '\\dfrac{\\partial{x}}{\\partial{y}}',
  ],

  ['\\lim_{x\\to0}', '\\lim_{x\\to0}'],
  ['\\lim_{x \\to +\\infty}', '\\lim_{x \\to +\\infty}'],
  ['\\lim_{x \\to -\\infty}', '\\lim_{x \\to -\\infty}'],
];
