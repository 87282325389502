import * as type from "../../types/API/types";


export function addInstitution(payload: any) {
  return {
    type: type.ADD_INSTITUTION,
    payload: payload,
  };
}

export function editInstitution(payload: any) {
  return {
    type: type.EDIT_INSTITUTION,
    payload: payload,
  };
}


export function deleteInstitution(payload: any) {
  return {
    type: type.DELETE_INSTITUTION,
    payload: payload,
  };
}


export function getInstitutions() {
  return {
    type: type.GET_INSTITUTIONS,
  };
}


