import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Please enter username'),
    password: Yup.string().required('Please enter password.'),
});
export const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter first name'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Please enter last name.'),
    emailId: Yup.string().email('Please enter valid email').required('Please enter email'),
    phoneNumber: Yup.string().required("Please enter phone number"),
    dateOfBirth: Yup.mixed().required("Please enter date of birth"),
});
export const ForgotSchema = Yup.object().shape({
    email: Yup.string().email('Please enter valid email').required('Please enter email'),
});
export const courseSchema = Yup.object().shape({
    course_name: Yup.string().required('Course name is mandatory'),
    course_code: Yup.string().required('Course code is mandatory'),
    course_type: Yup.string().required('Please select Course type'),
    correct_score: Yup.string(),
    wrong_score: Yup.string(),
    test_time: Yup.string(),
    attempts: Yup.string(),
    department: Yup.string().required('Please select Department'),
    class: Yup.string().required('Please select Class'),
    teacher: Yup.string().required('Please select Teacher'),
    description: Yup.string().required('Course description is mandatory')
})
export const classSchema = Yup.object().shape({
    classGrade: Yup.string().required('Class grade is mandatory'),
    section: Yup.string().required('Section is mandatory'),
    displayName: Yup.string().required('Display name is mandatory')
})
export const departmentSchema = Yup.object().shape({
    deptName: Yup.string().required('Department Name is mandatory'),
    deptCode: Yup.string().required('Department Code is mandatory'),
})
export const teacherSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is mandatory'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Last name is mandatory'),
    contactNumber: Yup.string().required('Contact number is mandatory'),
    email: Yup.string().email('Please enter valid email').required('Email is mandatory'),
    dateOfBirth: Yup.string().required('Date of birth is mandatory'),
})
export const addCourseSchema = Yup.object().shape({
    departments: Yup.array().of(Yup.string()),
    correctScoring: Yup.number()
        .typeError('Correct Scoring must be a number')
        .positive('Correct Scoring must be greater than 0')
        .test(
            'correct-scoring-required',
            'Correct Scoring is required for Test courses',
            function (value) {
                const { courseType } = this.parent;
                if (courseType === 'Test' && (value === undefined || value === null )) {
                    return false;
                }
                return true;
            }
        ),
    wrongScoring: Yup.number()
        .typeError('Correct Scoring must be a number')
        .test(
            'wrong-scoring-required',
            'Wrong Scoring is required for Test courses',
            function (value) {
                const { courseType } = this.parent;
                if (courseType === 'Test' && (value === undefined || value === null )) {
                    return false;
                }
                return true;
            }
        ),

    partialScoring: Yup.string()
        .matches(/^\d+(\.\d+)?$/, 'Partial score should be number only'),
    testTime: Yup.number()
        .typeError('Test Time must be a number')
        .positive('Test Time must be greater than 0')
        .moreThan(10, 'Test Time must be greater than 10')
        .test(
            'test-time-required',
            'Test Time is required for Test courses',
            function (value) {
                const { courseType } = this.parent;  // Access the parent object to get courseType
                if (courseType === 'Test' && (value === undefined || value === null)) {
                    return false;  // Fail validation if testTime is not provided
                }
                return true;  // Pass validation if testTime is provided or courseType isn't 'Test'
            }
        ),
    courseName: Yup.string().required('Course Name is mandatory'),
    courseCode: Yup.string().required('Course Code is mandatory'),
    courseType: Yup.string().required('Course Type is mandatory'),
    courseDataJson: Yup.string(),
    classIds: Yup.array(),
    teacherIds: Yup.array(),
    courseDescription: Yup.string(),
    publishStatus: Yup.string(),
    file: Yup.string(),
    image: Yup.string(),
    allowedAttempts: Yup.string(),
})                                        