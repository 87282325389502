import { Icon } from '@fluentui/react';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ChatIcon from '@mui/icons-material/Chat';
import "./index.css"
import { chat, classes, scorecard, studentCourse, institutions } from '../../../utils/routePath';
import { useDispatch, useSelector } from 'react-redux';
import 'firebase/database';
// import firebase from "../../../firebase"
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { hasCountGreaterThanZero } from '../../../utils/CommonFunction';
import { setAppLocation } from '../../../redux/actions/UI/appLocation';
import { GetQuestionBank } from '../../../redux/actions/API/courses';
interface SidebarnavProps {
    // Define your props here
}

const Sidebarnav: React.FC<SidebarnavProps> = (props) => {

    const location = useLocation()
    const userRole = useSelector((state: any) => state?.ui?.userMetadata)
    const role = userRole.role
    // const database: any = firebase.database();
    const [unreadCount, setUnreadCount] = useState<any>({})
    const [unreadIndication, swtUnreadIndication] = useState<boolean>(false)

    const dispatch = useDispatch()

    const transformData = (data: any): any => {
        const result: any = {};

        for (const channelId in data) {
            result[channelId] = {};

            for (const messageId in data[channelId]) {
                result[channelId] = {
                    id: messageId,
                    count: data[channelId][messageId].count,
                    lastMessage: data[channelId][messageId].lastMessage
                };
            }
        }

        return result;
    };


    // useEffect(() => {
    //     const fetchData = async () => {
    //         const unseenMessageRef = database.ref(`institute/${userRole.InstitutionName}/unseenMessage/${userRole.id}`)
    //         let unseenData: any
    //         unseenMessageRef.on('value', (snapshot: any) => {
    //             const data = snapshot.val();
    //             unseenData = transformData(data)
    //             const checkCount = hasCountGreaterThanZero(unseenData)
    //             swtUnreadIndication(checkCount)
    //             setUnreadCount(unseenData)
    //         });
    //     }
    //     fetchData()
    // }, [])

    const showClassName = () => {
        let ClassName: string
        if (unreadIndication) {
            if (location.pathname === '/chat' && unreadIndication) {
                ClassName = "activeUnread unread"
            } else {
                ClassName = "unread"
            }
        } else {
            ClassName = ""
        }
        return ClassName
    }

    return (
        <div className="sideNavContainer">
            <Link
                to={role === "Student" ? studentCourse : role === 'SuperAdmin' ? institutions : classes}
                replace={true}
                onClick={() => { }}
            >
                <Tooltip title="Dashboard">
                    <Icon
                        iconName="Home"
                        className={
                            location.pathname == "/" ||
                                location.pathname.includes("classes") ||
                                location.pathname.includes("course") ||
                                (location.pathname.includes("creator-studio") && !location.pathname.includes("creator-studio/questions")) ||
                                location.pathname.includes("analytic") ||
                                location.pathname.includes("changePassword") ||
                                location.pathname.includes("viewProfile") ||
                                location.pathname.includes("student-test") ||
                                location.pathname.includes("viewsubmission")
                                ? "activeSideNavIcon" : "sideNavIcon"
                        }
                    />
                </Tooltip>
            </Link>
            {userRole.role === "Student" ? "" :
                <Link
                    to="/dtManagers"
                    onClick={() => { }}
                >
                    <Tooltip title="Department Teacher Manager">
                        <Icon
                            iconName="PieSingle"
                            className={
                                location.pathname === "/dtManagers" ||
                                    location.pathname.includes("student-manager")
                                    ? "activeSideNavIcon" : "sideNavIcon"
                            }
                        />
                    </Tooltip>
                </Link>
            }

            {role != 'SuperAdmin' ? <>
                <Link
                    to={scorecard}
                    onClick={() => { }}
                >
                    <Tooltip title="Scorecard">
                        <LeaderboardIcon className={location.pathname === '/scorecard' ? "activeSideNavIcon" : "sideNavIcon"} />
                    </Tooltip>
                </Link>
                <Link
                    to={chat}
                    onClick={() => { }}
                    className={showClassName()}
                >
                    <Tooltip title="Chat">
                        <ChatIcon className={location.pathname === '/chat' ? "activeSideNavIcon" : "sideNavIcon"} />
                    </Tooltip>
                </Link>
                {userRole.role === "Student" ? "" :
                    <Link
                        to={"/creator-studio/questions"}
                        onClick={() => {
                            dispatch(GetQuestionBank({
                                "startRow": 0,
                            }))
                            dispatch(setAppLocation({ pathname: `/creator-studio/0//Question Bank/""//` }))
                        }}
                        className={showClassName()}
                    >
                        <Tooltip title="Question Bank">
                            <MenuBookIcon className={location.pathname.includes('/creator-studio/questions') ? "activeSideNavIcon question_icon" : "sideNavIcon"} />
                        </Tooltip>
                    </Link>}
            </> : ''}

        </div>
    );
};

export default Sidebarnav;