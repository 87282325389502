import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material';
import "./index.css"

interface Option {
    value: string;
    label: string;
    selected: boolean;
}

interface Props {
    optionData: Option[];
    handleSelect: (e: any, name: string) => void;
    selectedOptions: string[]
    selectedLabel: (selected: any, options: any, value: string, name: any) => any;
    label: string;
    value: any;
    name: string;
    labelText: string;
    label2?: string
}



const SelectWithCheckbox: React.FC<Props> = ({ optionData, handleSelect, selectedOptions, selectedLabel, label, value, name, labelText, label2 }) => {

    return (
        <div className="mui_drop_select">
            <p>{labelText}</p>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                {/* <InputLabel>{label}</InputLabel> */}
                <Select
                    multiple
                    value={selectedOptions}
                    onChange={(e: any) => handleSelect(e, name)}
                    renderValue={(selected: any) => {
                        return selectedLabel(selected, optionData, value, label)
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300,
                                width: 250,
                            },
                        },
                    }}
                >
                    {optionData?.map((option: any) => (
                        <MenuItem key={option[value]} value={option[value]}>
                            <Checkbox checked={selectedOptions.indexOf(option[value]) > -1} />
                            {`${option[label]}`} {label2 ? ` - ${option[label2]}` : ""}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default SelectWithCheckbox;
