import { Table, TableColumnsType, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import "./index.css"
import { useDispatch, useSelector } from 'react-redux';
import { EnableAnalytic, getAnalytic } from '../../redux/actions/API/courses';
import { useNavigate, useParams } from 'react-router-dom';
import { decryption, encryption, indianTime } from '../../utils/CommonFunction';
import moment from 'moment';
import CommonButton from '../../components/Common/CommonButton';
import CommonModal from '../../components/commonModal/CommonModal';

interface AnalyticProps {
    // Define your props here
}


interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
    score: number;
    totalScore: number;
    submissionDate: any;
    remainingAttempts: any;
    userId: number;
    totalPercent: any
}


const Analytic: React.FC<AnalyticProps> = (props) => {

    const analyticState = useSelector((state: any) => state.api.getAnalytic.data)
    const [analyticdata, setAnalyticData] = useState<any>({})
    const [submittedAnalytic, setSubmittedAnalytic] = useState<any[]>([])
    const [currentData, setCurrentData] = useState<any>({})
    const [notSubmittedAnalytic, setNotSubmittedAnalytic] = useState<any[]>([])
    const dispatch = useDispatch()
    const { classId, courseId, courseCode , leftAttempts } = useParams()
    const [enable, setEnable] = useState<boolean>(false)
    const [attemptCount, setAttemptCount] = useState<number>(0)
    const navigate = useNavigate()
    const countPercentage = (item: any) => {
        const data = (item.score * 100) / (item.totalScore ? item.totalScore : 1)
        return data ? `${data.toFixed(2)} %` : "NA"
    }

    const tableColumn: TableColumnsType<DataType> = [
        {
            title: 'S. No',
            dataIndex: 'key',
            width: "80px",
            key: "key",
            fixed: 'left',
            sorter: (a: any, b: any) => a.key.length - b.key.length,
        },
        {
            title: 'Name',
            dataIndex: 'userName',
            key: 'userName',
            width: "250px",
            sorter: (a: any, b: any) => a.userName.length - b.userName.length,
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            width: "100px",
            render: (text, record) => (
                typeof record.score === 'number' ? 
                    <span>{`${record.score}/${record.totalScore}`}</span> 
                    : "NA"
            ),

            sorter: (a: any, b: any) => a.score.length - b.score.length,

        },
        {
            title: 'Remaining Attempts',
            key: 'remainingAttempts',
            dataIndex: 'remainingAttempts',
            width: "180px",
            sorter: (a: any, b: any) => a.remainingAttempts.length - b.remainingAttempts.length,
        },
        {
            title: 'Percentage Score',
            key: 'totalPercent',
            dataIndex: 'totalPercent',
            width: "150px",
            render: (text, record) => (
                <span>{record.totalPercent}</span>
            ),
            sorter: (a: any, b: any) => a.address.length - b.address.length,
        },
        {
            title: 'Test Submitted',
            key: 'submissionDate',
            width: "200px",
            dataIndex: 'submissionDate',
            render: (text, record) => (
                <span>{record.submissionDate ? moment(indianTime(record.submissionDate)).format('LLL') : "NA"}</span>
            ),
            sorter: (a: any, b: any) => a.address.length - b.address.length,
        },
        {
            title: 'Action',
            key: 'action',
            width: "100px",
            dataIndex: 'action',
            render: (text, record) => (
                <div className="analyticAction">
                    <CommonButton
                        onClick={() => viewAnalytic(record)}
                        btnText="View"
                        disabled={!record.submissionDate}
                        className={record.submissionDate ? "view_btn" : "disable_view_btn"}
                    />
                    {/* <CommonButton
                        disabled={record.remainingAttempts}
                        onClick={() => enableAnalytic(record)}
                        btnText="Enable"
                        className={!record.remainingAttempts ? "view_btn" : "disable_view_btn"}
                    /> */}
                </div>
            ),
            fixed: 'right',
        },
    ]

    const viewAnalytic = (item: DataType) => {
        navigate(`/viewsubmission/${courseId}/${encryption(item.userId)}/${courseCode}/${leftAttempts}`)

    }
    const enableAnalytic = (item: DataType) => {
        setCurrentData(item)
        setEnable(true)
    }
    const handleClose = (item: DataType) => {
        setEnable(false)
    }

    useEffect(() => {
        setAnalyticData(analyticState)
        if (analyticState) {
            if (Object.keys(analyticState)?.length) {
                const data = analyticState.userScoringInfo.map((item: any, index: number) => {
                    item.totalPercent = countPercentage(item)
                    return item
                })
                const submitted = data?.filter((item: any) => item.submissionDate)
                submitted.map((item: any, index: number) => {
                    item.key = index + 1
                    return item
                })
                const notSubmitted = data?.filter((item: any) => !item.submissionDate)
                notSubmitted.map((item: any, index: number) => {
                    item.key = index + 1
                    return item
                })
                setSubmittedAnalytic(submitted)
                setNotSubmittedAnalytic(notSubmitted)
            }
        }
    }, [analyticState])


    useEffect(() => {
        const data = {
            courseId: Number(decryption(courseId)),
            classId: classId === "null" ? null : Number(decryption(classId))
        }
        dispatch(getAnalytic(data))
    }, [])
    const totalScoreCount = submittedAnalytic?.reduce((previousValue: any, currentValue: any) => {
        return previousValue + +currentValue?.totalPercent.replace(" %", "");
    }, 0);
    const checkNumber = (data: any) => {
        return (data > 0) ? data : 1
    }

    const countFunction = (value: number) => {
        setAttemptCount(d => d + value)
    }
    const clickPublish = () => {
        const payload: any = {
            userId: currentData.userId,
            courseId: Number(decryption(courseId)),
            increaseCount: attemptCount
        }
        dispatch(EnableAnalytic(payload))
        setEnable(false)
    }

    return (
        <>
            <CommonModal handleClose={handleClose} className='analytics_enable' modalOpen={enable}>
                <div className="edit_attempts">
                    <label className="attempts_label">No. of Attempt</label>
                    <div className="counter">
                        <button className="decreaseBtn" onClick={() => countFunction(attemptCount === 0 ? 0 : -1)}>
                            -
                        </button>
                        <input type="number" disabled value={attemptCount} />
                        <button className="increaseBtn" onClick={() => countFunction(1)}>+</button>
                    </div>
                    <div className="modalFooterbtn">
                        <button onClick={clickPublish}>Yes</button>
                        <button onClick={() => setEnable(false)}>Cancel</button>
                    </div>
                </div>
            </CommonModal>
            <div className="homeworkElementsContainer">

                <div className="studentScoreDetail">
                    <div className="counter">
                        <div>Attempted : <strong>{submittedAnalytic?.length}</strong> | Total : <strong>{analyticdata?.userScoringInfo?.length}</strong></div>
                    </div>
                    {/* <div className="counter">
                        <div>Class Average: <strong>{analyticdata?.classAverage}%</strong></div>
                    </div> */}
                    <div className="counter">
                        <Tooltip title={analyticdata?.CourseName}>
                            <div>CourseName: <strong>{analyticdata?.CourseName}</strong></div>
                        </Tooltip>
                    </div>
                    {/* <div className="counter">
                        <Tooltip title={moment(analyticdata?.courseCreatedDate).format('LLL')}>
                            <div>Course Created Date: <strong>{`${moment(analyticdata?.courseCreatedDate).format('MMMM DD')}`}</strong></div>
                        </Tooltip>
                    </div> */}
                    {/* <div className="publish_btn">
                    {analyticdata?.publishStatus === 2 ? <PopoverComp placement="left" content={this.popoverContent}><button> Publish Result </button></PopoverComp> :
                        <button onClick={() => this.openModal()}> {this.props.analyticData?.data?.publishStatus === 3 ? "Unpublish Result" : "Publish result"} </button>
                    }
                </div> */}
                </div>

                <p className="table_label">Submitted ({submittedAnalytic?.length || 0})</p>
                <Table scroll={{ x: 800 }} columns={tableColumn} pagination={false} dataSource={submittedAnalytic} />
                <p className="table_label">Not Submitted ({notSubmittedAnalytic?.length || 0})</p>
                <Table scroll={{ x: 800 }} columns={tableColumn} pagination={false} dataSource={notSubmittedAnalytic} />
            </div>
        </>
    );
};

export default Analytic;