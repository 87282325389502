import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertSubjectData } from '../utils/CommonFunction';
import FilterOptions from '../screens/submission/filterOptions';
import Button from './Common/Button/Button';
import { GetQuestionBank } from '../redux/actions/API/courses';

interface QuestionBankFilterProps {
    // Define your props here
    subjectData: any[];
    selectedSubject: any[];
    questionChapterData: any[]
    questionSelectedChapter: any[]
    questointopicData: any[];
    selectedTopic: any[]
    selectedDifficulty: any[]
    subChange: (e:any , val:any)=>void
    chapterChange:(e:any , val:any)=>void
    topicChange: (e:any , val:any)=>void
    difficultyChange: (e:any , val:any)=>void
    filterClick: ()=>void
}

const difficultyData: any[] = [
    {
        id: 0,
        name: "Easy"
    },
    {
        id: 1,
        name: "Medium"
    },
    {
        id: 2,
        name: "High"
    },
]
const QuestionBankFilter: React.FC<QuestionBankFilterProps> = ({ subjectData, selectedSubject, questionChapterData, questionSelectedChapter,
    questointopicData, selectedTopic, selectedDifficulty, subChange, chapterChange,
    topicChange, difficultyChange, filterClick }) => {
    return (


        <div className="filter_section">
            <div className="subject_filter">
                <>
                    <FilterOptions
                        data={subjectData}
                        value={selectedSubject}
                        onChange={subChange}
                        name="name"
                        label="Subject"
                    />
                    <FilterOptions
                        data={questionChapterData}
                        value={questionSelectedChapter}
                        onChange={chapterChange}
                        name="name"
                        label="Chapter"
                    />
                    <FilterOptions
                        data={questointopicData}
                        value={selectedTopic}
                        onChange={topicChange}
                        name="name"
                        label="Topic"
                    />
                    <FilterOptions
                        data={difficultyData}
                        value={selectedDifficulty}
                        onChange={difficultyChange}
                        name="name"
                        label="Difficulty Level"
                    />
                </>
            </div>
            <Button className="filter_submit" btnText="Submit" onClick={filterClick} />
        </div>




    );
};

export default QuestionBankFilter;