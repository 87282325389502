import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

interface TimeSelectProps {
  // Define your props here
  onChange: (e: any, timeString: any) => void;
  defaultValue?: any,
  ampm?: boolean
}

const TimeSelect: React.FC<TimeSelectProps> = ({ onChange, defaultValue, ampm=true }) => {


  const defaultTime = dayjs(defaultValue, 'HH:mm');

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['MobileTimePicker']} >
        <MobileTimePicker className='time_picker' value={(defaultValue && defaultValue !== "Invalid Date") ? defaultTime : void 0} label="Time" ampm={ampm} ampmInClock={false} onChange={(e: any) => onChange(e, e.format('HH:mm'))} defaultValue={defaultTime ? dayjs(defaultTime, "HH:mm") : undefined} />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default TimeSelect;