import { DatePicker } from 'antd';
import React from 'react';
import "./index.css"

import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

interface DateSelectProps {
    onChange: (e: any, timeString: string | string[]) => void;
    value?: any;
    defaultValue?: any,
    disablePast?: boolean
}
const DateSelect: React.FC<DateSelectProps> = ({ onChange, value, defaultValue, disablePast = false }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <MobileDatePicker className='date_picker' disablePast={disablePast} format='YYYY-MM-DD' value={(defaultValue && defaultValue !== "Invalid Date") ? dayjs(defaultValue,'YYYY-MM-DD') : dayjs(dayjs().format("YYYY-MM-DD"), "YYYY-MM-DD")} onChange={(e: any) => onChange(e, e.format('YYYY-MM-DD'))} label="Date" />
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default DateSelect;
