import * as type from '../../types/API/types'

export function getDepartmentClasses() {
    return {
        type: type.GET_DEPARTMENT_CLASSES,
    }
}

export function addDepartment(payload: any) {
    return {
        type: type.ADD_DEPARTMENT,
        payload: payload
    }
}

export function editDepartment(payload: any) {
    return {
        type: type.EDIT_DEPARTMENT,
        payload: payload
    }
}

export function deleteDepartment(payload: any) {
    return {
        type: type.DELETE_DEPARTMENT,
        payload: payload
    }
}

export function addClass(payload: any) {
    return {
        type: type.ADD_CLASS,
        payload: payload
    }
}
export function editConfig(payload: any) {
    return {
        type: type.EDIT_CONFIG,
        payload: payload
    }
}

export function editClass(payload: any) {
    return {
        type: type.EDIT_CLASS,
        payload: payload
    }
}
export function deleteClass(payload: any) {
    return {
        type: type.DELETE_CLASS,
        payload: payload
    }
}