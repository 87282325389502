/* eslint-disable react/prop-types */
import { TypeIcon } from "./TypeIcon";
import styles from "./CustomDragPreview.module.css";

export const CustomDragPreview = (props:any) => {
  const item = props.monitorProps.item;

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <TypeIcon
          droppable={item.droppable || false}
          fileType={item?.data?.fileType}
        />
      </div>
      {/* <div className={styles.label}>{item.text}</div> */}
      <div className={styles.label}> <p>Drop Here</p> </div>
    </div>
  );
};
