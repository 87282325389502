import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAiCourse, GetCourseDetails, GetCoursePagesNew, getStudentCourses, getUserProfile } from '../../redux/actions/API/courses';
import StudentCourseBox from '../../components/Common/studentCourseBox';
import { convertStamptoDate, dateANdTimeInIso, encryption, searchOnlyParent, setStorageFunction, timeDiffrence } from '../../utils/CommonFunction';
import { useNavigate } from 'react-router-dom';
import { setAppLocation } from '../../redux/actions/UI/appLocation';
import { getDepartmentClasses } from '../../redux/actions/API/departmentClasses';
import { getStudentsByClass, getTeacherCourses } from '../../redux/actions/API/teacherCourses';
import Swal from 'sweetalert2';
import "./index.css"
import { Button, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

interface StudentCourse {
    courseId: number;
    courseName: string;
    courseType: string;
    teacherName: string;
    courseCode: string;
    courseDescription: string;
    courseDataJson: string;
    courseProgress: number;
    courseWordUri: string | null;
    allowedAttempts: number;
    leftAttempts: number;
    task: string | null;
    publishStatus: number;
    testTime: number;
    correctScoring: number;
    wrongScoring: number;
}
const sortOptions = [
    { value: 'name_asc', label: 'Name (Ascending)' },
    { value: 'name_desc', label: 'Name (Descending)' },
    // { value: 'createdDate_asc', label: 'Created Date (Ascending)' },
    // { value: 'createdDate_desc', label: 'Created Date (Descending)' },
    // { value: 'lastAttemptedDate_asc', label: 'Last Attempted Date (Ascending)' },
    // { value: 'lastAttemptedDate_desc', label: 'Last Attempted Date (Descending)' }
];
interface StudentCourseScreemProps {
    // Define your props here
}

const StudentCourseScreem: React.FC<StudentCourseScreemProps> = (props) => {

    const studentCourse: StudentCourse[] = useSelector((state: any) => state?.api?.GetCourseDetails?.data) || []
    const userRole = useSelector((state: any) => state?.ui?.userMetadata)
    const userProfileData = useSelector((state: any) => state?.api?.getUserProfile?.data)
    const [timeCount, setTimeCount] = useState<number>(0)
    const [sortOrder, setSortOrder] = useState<string>('name_asc');
    const [sortedCourses, setSortedCourses] = useState<StudentCourse[]>([]);
    const [allSortedCourses, setAllSortedCourses] = useState<StudentCourse[]>([]);
    const [searchValue, setSearchValue] = useState<string>("")

    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getTeacherCourses())
        // dispatch(getDepartmentClasses())
        // dispatch(getStudentCourses())

        dispatch(getUserProfile({ userId: userRole.id }))

    }, [])

    useEffect(() => {
        if (userProfileData?.classId) {
            dispatch(getStudentsByClass({ classId: userProfileData.classId }))
        }
    }, [userProfileData?.classId])

    useEffect(() => {
        dispatch(GetCourseDetails({ studentId: userRole.id }))
    }, [timeCount, (timeCount > 0)])
    useEffect(() => {
        sortCourses(sortOrder);
    }, [studentCourse])
    const showScheduledDate = (data: string, activeTime?: string) => {
        if (data && data !== "null") {
            const array = data.split(" ")
            if (array[1] !== "NaN") {
                if (activeTime) {
                    if (array[2]) {
                        return convertStamptoDate(array[1], array[2])
                    } else {
                        return ""
                    }
                } else {
                    return convertStamptoDate(array[1])
                }
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    useEffect(() => {
        setTimeout(() => {
            const timeArray: any = []
            studentCourse?.map(((item: any) => {
                if (item.publishStatus == "1") {
                    const waitTimeMs = timeDiffrence(dateANdTimeInIso(showScheduledDate(item.task)))
                    if (waitTimeMs >= 0) {
                        timeArray.push(waitTimeMs)
                    }
                }
            }))
            if (timeArray.length) {
                setTimeCount(Math.min.apply(Math, timeArray))
                setSortedCourses(studentCourse)
                setAllSortedCourses(studentCourse)
            }
        }, (timeCount))
    }, [timeCount])

    const classClick = (item: any, department: string, courseStatus: any) => {
        if (courseStatus === "Nothing") {

        } else if (courseStatus === "modalOpen") {
            const jsonValue = (name: string) => {
                if (item.courseDataJson) {
                    const parseData = JSON.parse(item.courseDataJson)
                    return parseData[name]
                }
            }
            Swal.fire({
                text: "Clicking on yes will start the test timer immediately!",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#b3b2b2",
                confirmButtonText: "Yes",
                html: `<div class='test_confirmation'>
                            <ul class='username_pass'>
                                <li>
                                    <p>Course Name </p> : ${item.courseName}
                                </li>
                                <li>
                                    <p>Description </p>:  ${item.courseDescription}
                                </li>
                                <li>
                                    <p>Correct Scoring </p>:  ${item.courseDataJson ? JSON.parse(item.courseDataJson).correctScoring : ""}
                                </li>
                                <li>
                                    <p>Wrong Scoring </p>:  ${item.courseDataJson ? JSON.parse(item.courseDataJson).wrongScoring : ""}
                                </li>
                                <li>
                                    <p>Test Time </p>:  ${item.testTime ? item.testTime : ""}
                                </li>
                            </ul>
                            <p>Clicking on yes will start the test timer immediately!</p>
                        </div>`,
                title: "Are you sure, begin this test?",
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(setAppLocation({ pathname: `/creator-studio/${item.courseId}/${item.courseType}/${item.courseName}/${item.courseCode}/${item.courseDescription}/${item.courseDataJson ? JSON.parse(item.courseDataJson).testTime : 60}/${item.correctScoring}/${item.wrongScoring}/${item.leftAttempts}/${jsonValue("partialCorrect")}/${item.allowedAttempts}` }))
                    if (item.courseCode === "AI") {
                        dispatch(GetAiCourse({ courseId: item.courseId }))
                    } else {
                        dispatch(GetCoursePagesNew({ courseId: item.courseId }))
                    }
                    setStorageFunction("totalTime", ((item?.testTime) * 60 * 1000).toString())
                    setStorageFunction('timeLeft', (item?.testTime * 60 * 1000).toString());
                    const currentTime = new Date();
                    const endTime = new Date(currentTime.getTime() + (item?.testTime * 60 * 1000));
                    setStorageFunction('endTime', endTime.toString());
                    navigate("/student-test")
                }
            });

        }
        else {
            const jsonValue = (name: string) => {
                if (item.courseDataJson) {
                    const parseData = JSON.parse(item.courseDataJson)
                    return parseData[name]
                }
            }
            if (item.courseType === "Regular") {
                dispatch(setAppLocation({ pathname: `/creator-studio/${item.courseId}/${item.courseType}/${item.courseName}/${item.courseCode}/${item.courseDescription}////` }))
            } else {
                dispatch(setAppLocation({ pathname: `/creator-studio/${item.courseId}/${item.courseType}/${item.courseName}/${item.courseCode}/${item.courseDescription}/${item?.testTime[0] ? item?.testTime[0] : 60}/${item.correctScoring}/${item.wrongScoring}/${item.leftAttempts}/${jsonValue("partialCorrect")}` }))
            }

            if (item.courseCode === "AI") {
                dispatch(GetAiCourse({ courseId: item.courseId }))
            } else {
                dispatch(GetCoursePagesNew({ courseId: item.courseId }))
            }
            setStorageFunction("totalTime", ((item?.testTime) * 60 * 1000).toString())
            setStorageFunction('timeLeft', (item?.testTime * 60 * 1000).toString());
            const currentTime = new Date();
            const endTime = new Date(currentTime.getTime() + (item?.testTime * 60 * 1000));
            setStorageFunction('endTime', endTime.toString());
            navigate("/student-test")
        }
    }

    const validateCoursenameClick = (courstType: string, ledtAttepts: number, elem?: any) => {

        let returnNavigateStatus: boolean
        let returnVal: string;

        if (elem.publishStatus === 2) {
            returnNavigateStatus = false
        } else {
            returnNavigateStatus = true

        }
        if (elem.task && elem.task !== "null") {
            const splitArray = elem.task.split(" ")
            if (splitArray[1] && splitArray[1] !== "NaN") {
                const waitTimeMs = timeDiffrence(dateANdTimeInIso(showScheduledDate(elem.task)))
                if (waitTimeMs <= 0) {
                    if (splitArray[2]) {
                        let activeTimeMs: any = 0
                        activeTimeMs = +(splitArray[2] * 60 * 1000) + waitTimeMs
                        if (activeTimeMs >= 0 && waitTimeMs < 0) {
                            returnNavigateStatus = true
                        } else {
                            returnNavigateStatus = false
                        }
                    } else {
                        returnNavigateStatus = true
                    }

                } else {
                    returnNavigateStatus = false
                }
            } else {
                returnNavigateStatus = true
            }
        } else {
            returnNavigateStatus = true
        }


        if (returnNavigateStatus) {
            if (elem.publishStatus === 3 && elem.leftAttempts > 0) {
                returnVal = "published"
            } else {
                if (courstType === "Regular") {
                    returnVal = "navigate";
                } else if (courstType === "Test" && ledtAttepts > 0) {
                    returnVal = "modalOpen";
                }
                else {
                    returnVal = "Nothing";
                }
            }
        } else {
            returnVal = "Nothing";
        }

        // returnVal = "navigate";


        return returnVal;
    };

    const viewSubmissionClick = (e: any, item: any) => {
        e.stopPropagation()
        navigate(`/viewsubmission/${encryption(item.courseId)}/${(encryption(userRole.id))}/${encryption(item.courseCode)}/${encryption(item?.leftAttempts)}`)
    }

    const handleChange = (event: SelectChangeEvent) => {
        const selectedSort = event.target.value as string;
        setSortOrder(selectedSort);
        sortCourses(selectedSort);
    };

    const sortCourses = (sortBy: string) => {
        const sorted = [...studentCourse].sort((a, b) => {
            if (sortBy === 'name_asc') {
                return a.courseName.localeCompare(b.courseName);
            } else if (sortBy === 'name_desc') {
                return b.courseName.localeCompare(a.courseName);
            }
            // else if (sortBy === 'createdDate_asc') {
            //     return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
            // } else if (sortBy === 'createdDate_desc') {
            //     return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
            // } else if (sortBy === 'lastAttemptedDate_asc') {
            //     return new Date(a.lastAttemptedDate).getTime() - new Date(b.lastAttemptedDate).getTime();
            // } else if (sortBy === 'lastAttemptedDate_desc') {
            //     return new Date(b.lastAttemptedDate).getTime() - new Date(a.lastAttemptedDate).getTime();
            // }
            return 0;
        });
        setSortedCourses(sorted);
        setAllSortedCourses(sorted)
    };

    const sortClick = () => {
        if (sortOrder === "name_asc") {
            setSortOrder("name_desc");
            sortCourses("name_desc");
        } else {
            setSortOrder("name_asc");
            sortCourses("name_asc");
        }
    }

    const searchChange = (e: any) => {
        const searchQuery = e.target.value
        if(searchQuery){
            const filterData = searchOnlyParent(allSortedCourses, searchQuery)
            setSortedCourses([...filterData])
        }else{
            setSortedCourses([...allSortedCourses])
        }
        setSearchValue(searchQuery)
    }
    return (
        <>
            <div className="page_header">
                <div className="refresh_with_time header_with_search">
                    <TextField value={searchValue} onChange={(e: any) => searchChange(e)} className='search_field' size="small" id="outlined-basic" label="Search here" variant="outlined" />
                </div>
            </div>
            <div className="homeworkElementsContainer">
                <div className='st-title-cnt'>
                    <h2>Tests</h2><h2>-</h2><h2>{sortedCourses?.length}</h2>

                    {/* <div className='st-sort-cnt'>
                    <Button variant="contained" sx={{ backgroundColor: "var(--color-primary)" }} onClick={sortClick}>
                        <SortIcon sx={{ fontSize: 18 }} /> {sortOrder === "name_asc" ? "(A-Z)" : "(Z-A)"}
                    </Button>
                </div> */}

                </div>

                {/* <div className="counterContainer">
                <div className="counter addCounter">
                    <h2>{sortedCourses?.length}</h2>
                    <div>Courses</div>
                </div>
            </div> */}
                <div className="allCourses" style={{ marginTop: "10px" }}>
                    {sortedCourses?.map((item: any) => {
                        const courseStatus = validateCoursenameClick(
                            item.courseType,
                            item.leftAttempts,
                            item
                        )
                        return (
                            <StudentCourseBox scheduleEndTime={showScheduledDate(item.task, "activeTime")} scheduleStartTime={showScheduledDate(item.task)}  {...{ classClick, item, courseStatus, viewSubmissionClick }} name="All Course" />
                        )
                    })}
                </div>
            </div>
        </>
    );
};

export default StudentCourseScreem;