import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const PublishNowModal = () => {
    return (
        <div className="courseModalBody">
            <InfoOutlinedIcon />
            <p>Are you sure you want to make this course publish?</p>
        </div>
    )
}
export const UnPublishModal = () => {
    return (
        <div className="courseModalBody">
            <InfoOutlinedIcon />
            <p>Are you sure you want to make this course private?</p>
        </div>
    )
}