import Lottie, { LottieProps } from "react-lottie-player";
import * as animationData from "./bg.json";

interface AnimationProps {
    name: any;
}
const Animation: React.FC<AnimationProps> = ({ name }) => {
    const defaultOptions: LottieProps = {
        play: true,
        // animationData: animationData
        animationData: name
    };
    return (
        <div className="successAnimation">
            <Lottie {...defaultOptions} />
            {/* {name} */}
        </div>
    );
};


export default Animation;