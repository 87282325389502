export const initialClass = {
    classGrade: "",
    section: "",
    displayName: ""
};
export const initialDepartment = {
    deptName: "",
    deptCode: "",
};
export const initialTeacher = {
    firstName: "",
    middleName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    dateOfBirth: "",
}

export const courseInitialValue = {
    departments: [],
    correctScoring: "",
    wrongScoring: "",
    testTime: "",
    courseName: "",
    partialScoring:"",
    courseCode: "",
    allowedAttempts: "1",
    courseType: "Test",
    courseDataJson: "",
    classIds: [],
    teacherIds: [],
    courseDescription: "",
    publishStatus: 2,
    file: "",
    image: "",
}
export const initialRegister = {
    firstName: "",
    middleName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    dateOfBirth: "",
}