import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface DropdownAllSelectProps {
    onChange: (e: any, val: any, name: any, currentData: any) => void,
    options: any,
    name: string,
    value?: any,
    labelText: string
}

const DropdownAllSelect: React.FC<DropdownAllSelectProps> = ({ onChange, options, name, value, labelText }) => {
    return (
        <>
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={options}
                disableCloseOnSelect
                onChange={onChange}
                limitTags={3}
                value={value}
                getOptionLabel={(option) => option[name]}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option[name]}
                    </li>
                )}
                style={{ width: 350 }}
                renderInput={(params) => (
                    <TextField {...params} label={labelText} placeholder={labelText} />
                )}
            />
        </>
    );
};

export default DropdownAllSelect;