import React from "react";
import "./CommonButton.css"

interface CommonButtonProps {
  btnText: any;
  onClick: () => void;
  className : string;
  disabled : boolean;
}


const CommonButton: React.FC<CommonButtonProps> = ({ btnText, onClick, className , disabled = false}) => {
  return (
      <button disabled={disabled} className={`common_button  ${disabled ? "disable_btn" : ""} ${className}`} onClick={onClick}>{btnText}</button>
  );
};

export default CommonButton;