import { Sidebar } from 'react-pro-sidebar';
import { useEffect, useState } from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import "./index.css"

interface QuestionSidebarProps {
  children: any,
  collapsed: string, setCollapsed: any
}

const QuestionSidebar: React.FC<QuestionSidebarProps> = ({ children, collapsed, setCollapsed }) => {

  // const [collapsed, setCollapsed] = useState<string>("medium")
  const collapseClick = () => {
    if (collapsed === "full") {
      setCollapsed("medium")
    } else if (collapsed === "medium") {
      setCollapsed("small")
    } else if (collapsed === "small") {
      setCollapsed("full")

    }
  }

  const showWidth: any = () => {
    let width: any
    if (collapsed === "full") {
      width = "250px"
    } else if (collapsed === "medium") {
      width = "90px"
    } else if (collapsed === "small") {
      width = "0px"
    }
    return width
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (window.innerWidth < 768) {
        const sidebarElement = document.querySelector(`.${collapsed}_sidebar`);
        const sideIconElement = document.querySelector('.sideIcon');
        if (
          sidebarElement &&
          !sidebarElement.contains(event.target as Node) &&
          sideIconElement &&
          !sideIconElement.contains(event.target as Node)
        ) {
          setCollapsed("small");
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [collapsed, setCollapsed]);


  return (
    <>
      <div className={`${collapsed}_sidebar`} style={{ display: 'flex', transition: "0.8s", overflow: "hidden", width: showWidth() }}>
        <Sidebar collapsed={false}>
          {children}
        </Sidebar>
      </div>
      <div className={`sideIcon`} onClick={collapseClick}>
        <DoubleRightOutlined className={collapsed} />
      </div>
    </>
  );
};

export default QuestionSidebar;
