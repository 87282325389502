import React from 'react';
import { Tooltip } from 'antd';
import { Icon } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { Institution } from '../../../screens/institutions';

interface InstitutionBoxProps {
    editStatus: boolean;
    item: Institution;
    editClick?: (item: any, e: React.MouseEvent<HTMLElement>) => void;
    deleteClick?: (item: any, e: React.MouseEvent<HTMLElement>) => void;
}

const InstitutionBox: React.FC<InstitutionBoxProps> = ({ editStatus, item, editClick, deleteClick }) => {

    const userRole = useSelector((state: any) => state.ui.userMetadata)

    return (
        <div key={item.ID} className="subject"  >

            <div className="dateAndMore">
                <div className="facePileAndMore">
                    <div className="subjectFacePile">
                        {(editStatus && editClick) ?
                            <Tooltip title="Edit course">
                                <Icon
                                    iconName="Edit"
                                    className="analyticDlt"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => editClick(item, e)}
                                ></Icon>
                            </Tooltip> : null}

                        {(editStatus && deleteClick) ?
                            <Tooltip title="Delete course">
                                <Icon
                                    className="analyticDlt"
                                    iconName="Trash"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => deleteClick(item, e)}
                                ></Icon>
                            </Tooltip> : null}

                    </div>
                </div>
            </div>

            <div className="subjectDetails">
                <h2 className="boxHeading" style={{ fontWeight: "500" }}>
                    {item.InstitutionName}
                </h2>
                <p className="teacherName"><p> Student Size Limit </p> : {item?.StudentSizeLimit}</p>
                <p className="teacherName">Institution Code : {item.InstitutionCode}</p>
                <p className="teacherName">Institution Type : {item.InstitutionType}</p>


                <p className="teacherName">
                    Admin Size Limit : {item.AdminSizeLimit}
                </p>

                <div className="gradeDisplayName">
                    {item.Details}
                </div>

            </div>
        </div>
    );
};

export default InstitutionBox;