import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
interface ClipboardModalProps {
    copied: boolean;
    setCopied: any;
    encodedData?: any
    // Define your props here
}
const ClipboardModal: React.FC<ClipboardModalProps> = ({ copied, encodedData, setCopied }) => {

    const usermetaData = useSelector((state: any) => state.ui.userMetadata.token)
    const encToken: any = btoa(usermetaData)
    const [locationName , setLoactionName] = useState<string>("")
    useEffect(() => {
        setLoactionName(window.location.origin)
    }, [])
    return (
        <div className={`clipboardScreen ${copied ? "copiedText" : ""}`}>
            <CopyToClipboard text={`${locationName}/register/${encToken}${encodedData ? `/${encodedData}` : ""}`} onCopy={() => setCopied(true)}>
                <button>{copied ? 'Copied!' : 'Copy Link'}</button>
            </CopyToClipboard>
            <Tooltip title={`${locationName}/register/${encToken}${encodedData ? `/${encodedData}` : ""}`}>
                <p className='copyLink'>  {`${locationName}/register/${encToken}${encodedData ? `/${encodedData}` : ""}`} </p>
            </Tooltip>
        </div>
    )
}

export default ClipboardModal;
