import React, { useEffect, useState } from 'react';
import { DefaultButton, PrimaryButton, TextField } from '@fluentui/react';
import SelectWithCheckbox from '../../../components/Common/dropdownSelect';
import { addSelectedKey, matchedArray, returnMatchedArray, returnSelectedArray } from '../../../utils/CommonFunction';
import { useSelector } from 'react-redux';
import { FormControl, FormControlLabel, RadioGroup, SelectChangeEvent } from '@mui/material';
import Radio from '@mui/material/Radio';
import { message, Switch, UploadProps } from "antd";
import { uploadMedia } from '../../../utils/routePath';
import Button from '../../../components/Common/Button/Button';

interface AddEditCourseProps {
    values: any;
    errors: any;
    touched: any;
    handleBlur: any;
    handleChange: any;
    courseChange: any;
    setFieldValue: any;
    labelText: string;
    jeeChange: (e: any) => void;
    isJee: boolean;
    onSubmit?: any;
    isEditableCourse?: boolean;
    handleClose?: any;
}

const AddEditCourse: React.FC<AddEditCourseProps> = ({ values, errors, touched, handleBlur, handleChange, isEditableCourse, setFieldValue, labelText, courseChange, handleClose, jeeChange, isJee, onSubmit }) => {

    const allDepartmentData = useSelector((state: any) => state.api.getDepartmentClasses?.data)
    const getTeacherCourses = useSelector((state: any) => state.api.getTeacherCourses?.data)
    const userData = useSelector((state: any) => state.ui?.userMetadata)
    const [allDepartment, setAllDepartment] = useState<any[]>(addSelectedKey(allDepartmentData))
    const [allClass, setAllClass] = useState<any[]>([])
    const [allTeacher, setAllTeacher] = useState<any[]>(getTeacherCourses)

    const characterLength = 250

    const handleSelect = (event: React.ChangeEvent<{ value: unknown }>, name: string) => {
        const selectedValues = event.target.value as string[];
        if (name === "departments") {
            if (selectedValues.length === 0) {
                setAllClass([]);
                setFieldValue("classIds", []);
            } else {
                const arrays = matchedArray(allDepartment, selectedValues, "departmentId", "selected")
                setAllDepartment([...arrays])
                const data = returnSelectedArray(arrays, "classes")
                setAllClass([...data])
            }
        } else if (name === "classIds") {
            const arrays = matchedArray(allClass, selectedValues, "classId", "selected")
            setAllClass([...arrays])
        } else if (name === "teacherIds") {
            const arrays = matchedArray(allTeacher, selectedValues, "userId", "selected")
            setAllTeacher([...arrays])
        }
        setFieldValue(name, selectedValues)
    };

    const selectedLabel = (selected: any, options: any, value: any, name: any) => {
        const arrays = matchedArray(options, selected, value, "selected")
        const data = arrays.filter((item: any) => item.selected)
        const val = data.map((item: any) => item[name])
        return val.join(', ')
    }

    useEffect(() => {
        const currentClass = returnMatchedArray(allDepartmentData, values.departments)
        setAllClass([...currentClass])
    }, [])

    const handleFileChange = (info: any) => {
        const { status, response } = info.file;
        if (status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (status === "done") {
            message.success(`${info.file.name} file uploaded successfully.`);
            if (response && response.result) {
                setFieldValue("file", response.result)
            }
        } else if (status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const uploadProps: UploadProps = {
        name: "files",
        multiple: false,
        action: `${process.env.REACT_APP_API_URL}${uploadMedia}`,
        headers: {
            Authorization: userData.token,
        },
        onChange: handleFileChange,
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
        capture: undefined
    };


    return (
        <form onSubmit={onSubmit} className='add_edit_body'>
            <p>{labelText}</p>
            <TextField
                label="Course Name"
                placeholder="Enter Course Name"
                required={true}
                name="courseName"
                onChange={handleChange}
                value={values.courseName}
                onBlur={handleBlur}
                errorMessage={(touched.courseName && errors.courseName) ? errors.courseName : ""}
            />
            <TextField
                label="Course code"
                placeholder="Enter Course code"
                required={true}
                name="courseCode"
                onChange={handleChange}
                value={values.courseCode}
                onBlur={handleBlur}
                errorMessage={(touched.courseCode && errors.courseCode) ? errors.courseCode : ""}
            />
            <FormControl className='course_type_radio mui_drop_select'>
                <p>Course Type</p>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values.courseType}
                    onChange={courseChange}
                >
                    <FormControlLabel value="Regular" control={<Radio />} label="Regular" />
                    <FormControlLabel value="Test" control={<Radio />} label="Test" />
                </RadioGroup>
            </FormControl>
            {values.courseType === "Test" ?
                <>
                    <div className="score_section">
                        <TextField
                            label="Correct"
                            placeholder="Enter Correct"
                            required={true}
                            width={30}
                            name="correctScoring"
                            onChange={handleChange}
                            value={values.correctScoring}
                            onBlur={handleBlur}
                            errorMessage={(touched.correctScoring && errors.correctScoring) ? errors.correctScoring : ""}
                        />
                        <TextField
                            label="Wrong"
                            placeholder="Enter Wrong"
                            required={true}
                            name="wrongScoring"
                            onChange={handleChange}
                            value={values.wrongScoring}
                            onBlur={handleBlur}
                            errorMessage={(touched.wrongScoring && errors.wrongScoring) ? errors.wrongScoring : ""}
                        />
                        <TextField
                            label="Test Time"
                            placeholder="Enter Test Time"
                            required={true}
                            name="testTime"
                            onChange={handleChange}
                            value={values.testTime}
                            onBlur={handleBlur}
                            errorMessage={(touched.testTime && errors.testTime) ? errors.testTime : ""}
                        />
                    </div>
                    <div className="score_section" style={{ marginTop: 10 }}>
                        <label htmlFor='jee'>JEE Test</label>
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            defaultChecked={isJee}
                            onChange={jeeChange}
                            id='jee'
                        />

                    </div>
                    {isJee ?
                        <div className="score_section">
                            <TextField
                                label="Partial Correct"
                                placeholder="Enter Partial correct score"
                                required={true}
                                name="partialScoring"
                                onChange={handleChange}
                                value={values.partialScoring}
                                onBlur={handleBlur}
                                errorMessage={(touched.partialScoring && errors.partialScoring) ? errors.partialScoring : ""}
                            />

                        </div> : ""}
                    <TextField
                        label="Allowed Attempts"
                        placeholder="Allowed Attempts"
                        required={true}
                        name="allowedAttempts"
                        type="number"
                        onChange={handleChange}
                        value={values.allowedAttempts}
                        onBlur={handleBlur}
                        errorMessage={(touched.allowedAttempts && errors.allowedAttempts) ? errors.allowedAttempts : ""}
                    />
                </>
                : null}
            <div className="score_section">
                <SelectWithCheckbox labelText="Department" optionData={allDepartment} name="departments" label="deptName" value="departmentId" selectedOptions={values.departments} {...{ handleSelect, selectedLabel }} />
                <SelectWithCheckbox label2="section" labelText="Class" optionData={allClass} name="classIds" label="classGrade" value="classId" selectedOptions={values.classIds} {...{ handleSelect, selectedLabel }} />
            </div>
            <SelectWithCheckbox labelText="Teacher" optionData={allTeacher} name="teacherIds" label="firstName" value="userId" selectedOptions={values.teacherIds} {...{ handleSelect, selectedLabel }} />

            <TextField
                label="Course description"
                placeholder="Course description"
                name="courseDescription"
                className={`${values.courseDescription.length > characterLength ? "error_text" : ""}`}
                errorMessage={(touched.courseDescription && errors.courseDescription) ? errors.courseDescription : ""}
                onChange={handleChange}
                multiline={true}
                value={values.courseDescription}
            />
            <div className="footerBtn">
                <PrimaryButton
                    type='submit'
                    text={isEditableCourse ? "Update" : "Add"}
                />
                <DefaultButton
                    onClick={handleClose}
                    text={isEditableCourse ? "Cancel" : "No"}
                />
            </div>
            {/* <Button
                className='modal_ok_btn' btnText="update"
                types='submit'
                // onClick={confirmCourseClick}
            />
            <Button
                btnText="cancel"
            /> */}
        </form>
    );
};

export default AddEditCourse;