/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
import { useState, useEffect, useRef } from "react";
import {
    Tree,
    getBackendOptions,
    MultiBackend,
} from "@minoru/react-dnd-treeview";
import { DndProvider } from "react-dnd";
import { FaRegPaste } from "react-icons/fa6";

import { ThemeProvider, CssBaseline, Pagination, PaginationItem, Alert, TablePagination, Popover, ButtonBase, Select, MenuItem, Radio, RadioGroup, FormControlLabel, Autocomplete, Checkbox, IconButton, Tooltip as MuiTooltip, FormGroup } from "@mui/material";
import styles from "./CreatorStudioFunctional.module.css";
import { useDispatch, useSelector } from "react-redux";
import { GetCoursePagesNew, GetQuestionBank, MoveFolder, MovePage, addPages, deleteHeirarchy, deletePage, getTopics, updatePages } from "../redux/actions/API/courses";
import "./CreatorStudioFunctional.css"
import { Image, Tooltip, notification } from "antd";
import { DefaultButton, Icon, Panel, PrimaryButton, TextField } from "@fluentui/react";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import Camera from "react-html5-camera-photo";
import { Theme } from "./Sidebar/Theme";
import { CustomNode } from "./Sidebar/Customode";
import { CustomDragPreview } from "./Sidebar/CustomDragPreview";
import { Placeholder } from "./Sidebar/Placeholder";
import TextEditor from "./Common/Texteditor/TextEditor";
import ImageCropComponent from "./Common/ImageCropper/ImageCropComponent";
import jwt_decode from "jwt-decode";
import Animation from "./Common/Animation/Animation";
import Loader from "./Common/Loader/Loader";
import SwipeableViews from "react-swipeable-views";
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CommonModal from "./commonModal/CommonModal";
import { actionLoader, offlinePageData } from "../redux/actions/UI/spinnerStatus";
import InfoIcon from '@mui/icons-material/Info';
import correctAnimationData from "../components/Common/Animation/bg.json"
import failedAnimationData from "../components/Common/Animation/failed.json"
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Button from "./Common/Button/Button";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import PDFWritter from "./Common/PDFWritter";
import { autoSelectOption, checkAi, convertSubjectData, getAnswerFromScreen, handleButtonClick, isImageUrl, removeHtmlTag, setChapsValues, setStorageFunction, setSubjsValues, setTopsValues, validateTextWithTag } from "../utils/CommonFunction";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { setUnsavedChangesData } from "../redux/actions/UI/userMetadata";
import Popclick from "./Common/popClick";
import { uploadMedia } from "../utils/routePath";
import useNetworkStatus from "../utils/useNetworkStatus";
import QuestionSidebar from "./Common/proSidebarComp";
import SubjectSelection from "./Common/subjectSelection";
import { useParams } from "react-router-dom";
import { FilterSvg } from "../utils/ImagePath";
import QuestionBankFilter from "./questionBankFilter";
import { fetchPost } from "../API/Baseurl";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextFields from '@mui/material/TextField';
import { DeleteOutlined, NoteAddOutlined } from "@mui/icons-material";
import Swal from "sweetalert2";
import SuspenseLoader from "./suspenseLoader";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface treeDataType {
    data: { fileType: string },
    droppable: boolean,
    editable: boolean,
    id: any,
    parent: number | null,
    text: string,
    pageType: any,
    Media: any,
    selected: number | null,
    order: number | null,
    answered: boolean,
    correctAnswer: any,
    description: string,
    mcqData: string[],
    Chapter: number | null,
    Subject: number | null,
    Topic: number | null,
    difficulty: number | null,
    multiAnswer: any[],
    PageDataJson: any,
    rangeTo: any
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "10px"
};
let defaultMcqOption: string[] = ["", "", "", ""]

const folderNamelength = 250;
const preFilledData = ["a", "b", "c", "d"]
const defaultSubData = {
    name: "",
    id: "",
    data: []
}

function CreatorStudioFunctional() {
    const loginToken = useSelector((state: any) => state.api.login.data.token)
    const appLocation = useSelector((state: any) => state.ui.appLocation)
    const allCoursedata: any[] = appLocation.split("/")
    const [openedFolder] = useState<number[]>([])
    const [radioType, setRadioType] = useState("")
    const isOnline = useNetworkStatus();
    const [subjectId, setSubjectId] = useState<any>("")
    const [topicId, setTopicId] = useState<any>("")
    const [chapterId, setChapterId] = useState<any>("")
    const [subjectValue, setSubjectValue] = useState<any>(defaultSubData)
    const [chapterValue, setChapterValue] = useState<any>(defaultSubData)
    const [topicValue, setTopicValue] = useState<any>(defaultSubData)
    const [chapterData, setChapterData] = useState<any[]>([])
    const [collapsed, setCollapsed] = useState<string>("medium")
    const [topicData, setTopicData] = useState<any[]>([])
    const [jeeAvailable] = useState<string>(allCoursedata[8])
    const [questionType, setQuestionType] = useState<string>("single")
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isFiltered, setIsFiltered] = useState<boolean>(false)
    const [anchorElDlt, setAnchorElDlt] = useState<null | HTMLElement>(null);
    const openNotification = (
        status: string,
        message: string,
    ) => {
        (notification as any)[status]({
            message: status,
            description: message,
            placement: "topRight",
            duration: 2.5
        });
    };

    const [courseType, setCourseType] = useState("Test")
    const [treeData, setTreeData] = useState<any>([]);
    const [initialTreeData, setInitialTreeData] = useState<any>([]);
    const [isFocused, setIsFocused] = useState(false);
    const [newText, setNewtext] = useState("")
    const [activepage, setActivepage] = useState("Question")
    const [descriptionValue, setDescriptionValue] = useState<string>("")
    const [headerValue, setHeaderValue] = useState<string>("")
    const [textAnswerValue, setTextAnswerValue] = useState<string>("")
    const [rangreTo, setRangeTo] = useState<string>("")
    const [previewimage, setpreviewimage] = useState<string[]>([]);
    const [cameraValue, setcameraValue] = useState<File[]>([]);
    const [imageBase64, setimageBase64] = useState("")
    const [initialPreview, setinitialPreview] = useState("")
    const [cameraOpen, setcameraOpen] = useState(false)
    const [cameraDialouge, setcameraDialouge] = useState(true)
    const [previewDilouge, setpreviewDilouge] = useState(true)
    const [mcqData, setmcqData] = useState<string[]>([...defaultMcqOption]);
    const [createAndUpdateType, setCreateAndUpdatetype] = useState('')
    const [activeId, setActiveId] = useState<any>(0)
    const [editStatus, setEditStatus] = useState(true)
    const [courseId, setCourseId] = useState()
    const [activePaginationPage, setActivePaginationPage] = useState(1)
    const [currentMcqdata, setCurrentMcqdata] = useState<string[]>(defaultMcqOption)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isModified, setIsModified] = useState(false)
    const [submitAnswer] = useState<any>([])
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [correctAnimation, setCorrectAnitmation] = useState(false)
    const [failedAnimation, setFailedAnitmation] = useState(false)
    const [deletedimage, setDeletedimage] = useState<string[]>([])
    const [updatedImage, setUpdatedImage] = useState<string>("")
    const [activeEditIndex, setActiveImageIndex] = useState<number | null>(null)
    const [editImage, setEditImage] = useState<boolean>(false)
    const [hideExplanation, setHideExplanation] = useState(false)
    const [alertModal, setAlertModal] = useState(false)
    const [modalId, setModalid] = useState<any>()
    const [updatedHtml, setUpdatedHtml] = useState<string>("");
    const [addPageType, setAddPageType] = useState<string>("")
    const [changedModalData, setChangedModalData] = useState<any>()
    const [changedModalName, setChangedModalName] = useState<string>()
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
    const [currentEditImgUrl, setCurrentEditImgUrl] = useState("")
    const [alertData, setAlertData] = useState<any>({})
    const [chapterName, setChapterName] = useState<string>("")
    const [submissionError, setSubmissionError] = useState<any>({
        headerValue: "",
        checkIsCorrect: "",
        checkOption: "",
        descriptionValue: "",
        subject: "",
        chapter: "",
        topic: "",
        difficulty: "",
        mcqOption: "",
        correctAnswer: "",
    })
    const [showHideDescription, setShowHideDescription] = useState(false)
    const [editorOnFocus, setEditorOnFocus] = useState<any>("")
    const [count, setCount] = useState<number>(16);
    const [urlFalse, setUrlFalse] = useState<boolean>(false)
    const [driveURL, setDriveURL] = useState<string>("")
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [formatValue, setFormatValue] = useState<string>("")
    const [autofillAnswers, setAutofillAnswers] = useState<any>({})
    const allCoursesDataLocal = useSelector((state: any) => state.ui.allCoursesData)
    const questionBankData = useSelector((state: any) => state.api.getQuestionBank?.data) || {}

    const getTopicData = useSelector((state: any) => state?.api?.getTopics?.data)
    const [open, setOpen] = useState<boolean>(false)
    const [dltOpen, setDltOpen] = useState<boolean>(false)
    const [regularAnswerArray, setRegularAnswerArray] = useState<any[]>([])
    const [regularSelectedAns, setRegularSelectedAns] = useState<any>()
    const [subjectData, setSubjectData] = useState<any[]>([])
    const [difficulty, setDifficulty] = useState<number | null>(null);
    const isDeselecting = useRef(false);
    const dispatch = useDispatch()

    const params = useParams()
    const allCoursesData = useSelector((state: any) => state.api.getStudentCourses?.data?.courses) || []
    const [allCourse] = useState<any[]>(allCoursesData)
    const [selectedCourse, setSelectedCourse] = useState<any>({})
    const [selectedQuestionBank, setSelectedQuestionBank] = useState<any[]>([])

    useEffect(() => {
        dispatch(getTopics())
    }, [])

    useEffect(() => {
        if (getTopicData) {
            setSubjectData(convertSubjectData(getTopicData))
        }
    }, [getTopicData])
    const returnFocus = (type: any) => {
        if (type) {
            if (type === "header") {
                return "description"
            } else if (type === "description") {
                return "0"
            } else if (type === "0") {
                return "1"
            } else if (type == "1") {
                return "2"
            } else if (type == "2") {
                return "3"
            } else if (type == "3") {
                return "header"
            }
        }
    }

    const editorFocus = (e: any, type: any) => {
        setEditorOnFocus(type)
    }

    useEffect(() => {
        setHasSubmitted(false)
    }, [activePaginationPage, isModified])
    const isBase64Url = (url: any) => {
        if (url.startsWith('data:') && url.includes(';base64,')) {
            const base64Part = url.split(';base64,')[1];
            try {
                const decoded = atob(base64Part);
                const reencoded = btoa(decoded);
                if (reencoded === base64Part) {
                    return true;
                }
            } catch (error) {
                return false;
            }
        }
        return false;
    }

    const [activeCreateEditData, setActiveCreateEditData] = useState<treeDataType>({
        data: { fileType: "" },
        droppable: false,
        editable: false,
        id: `new_${treeData.length + 1}`,
        parent: null,
        text: "",
        pageType: "",
        Media: [],
        selected: null,
        order: null,
        answered: false,
        correctAnswer: "",
        multiAnswer: [],
        description: "",
        mcqData: [],
        Chapter: null,
        Subject: null,
        Topic: null,
        difficulty: null,
        PageDataJson: null,
        rangeTo: ""
    });

    const setMultiAnswers = (item: any) => {
        if (item?.PageDataJson) {
            const jsonData = JSON.parse(item?.PageDataJson)
            if (jsonData.questionType === "multiple") {
                return jsonData.answer.split(",")
            } else if (jsonData.questionType === "text") {
                return jsonData.answer
            }
        }
    }

    const showSubmitted = (id: any) => {
        const data = submitAnswer.findIndex((item: any) => item.PageId === id)
        return submitAnswer[data]?.answer
    }

    const fillTopicValue = (subId: number, chapId: number, topId: number, data: any[]) => {
        if (data && data.length) {
            // if (subId || chapId || topId) {
            setSubjectId(subId)
            setChapterId(chapId)
            setTopicId(topId)
            const subsData = setSubjsValues(subId, data)
            if (subsData) {
                setSubjectValue(subsData)
                setChapterData(subsData.data)
                const chapData = setChapsValues(subId, chapId, data)
                if (chapData) {
                    setChapterValue(chapData)
                    setTopicData(chapData.data)
                } else {
                    setChapterValue(defaultSubData)
                    setTopicData([])
                }
                const topicVal = setTopsValues(subId, chapId, topId, data)
                setTopicValue(topicVal ? topicVal : defaultSubData)
            } else {
                setSubjectValue(defaultSubData)
            }
            // }
        }
    }

    const checkEmpty = (data: any, type: string) => {
        if (data) {
            if (data.errorMessage) {
                if (type === "array") {
                    return []
                }
                else if (type === "object") {
                    return {}
                }
            } else {
                if (type === "array") {
                    if (data) {
                        return data
                    } else {
                        return []
                    }
                } else if (type === "object") {
                    if (data.data) {
                        return data
                    } else {
                        return {
                            data: {}
                        }
                    }
                }
            }

        } else if (!data) {
            if (type === "array") {
                return []
            }
            else if (type === "object") {
                return {}
            }
        }
    }
    let getCoursePagesNewData = checkEmpty(useSelector((state: any) => state.api.getCoursePagesNew?.data), "array")
    const loaderStatus = useSelector((state: any) => state.ui.loaderStatus.spinnerStatus)

    useEffect(() => {
        if (params.type === "questions") {
            dispatch(GetQuestionBank({
                "startRow": 0,
            }))
        }
    }, [])

    const checkFolder = (data: any, data2: any) => {
        if ((data[0].text === "defaultRootHierarchy") && (data2[1].text === "defaultRootHierarchy")) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        dispatch(offlinePageData({
            addPageData: [],
            updatePageData: []
        }))
    }, [])

    // drop Functoin
    const handleDropFunction = (newTreeData: any, data: any) => {
        const currentindex = newTreeData.findIndex((item: any) => item.id === data.dragSourceId)
        if (newTreeData[1].text === "defaultRootHierarchy") {
            const temp = newTreeData[1];
            newTreeData[1] = newTreeData[0];
            newTreeData[0] = temp;
        }

        setTreeData(newTreeData)
        if (data.dragSource.data.fileType) {
            const pageObj = {
                pageId: data.dragSourceId,
                previousParentHierarchyId: data.dragSource.hierarchyId,
                newParentHierarchyId: data.dropTargetId ? data.dropTargetId : treeData[0].id,
                newPreviousId: data.relativeIndex === 0 ? null : newTreeData[currentindex - 1]?.id ? newTreeData[currentindex - 1]?.id : null,
                isNewPreviousHierarchy: data.relativeIndex === 0 ? false : (newTreeData[currentindex - 1]?.data?.fileType === "file" ? false : true)
            }
            dispatch(MovePage({ pageObj, courseId: allCoursedata[2] }))
        } else {
            const folderObj = {
                hierarchyId: data.dragSourceId,
                previousParentHierarchyId: data.dragSource.parent === 0 ? treeData[0].id : data.dragSource.parent,
                newParentHierarchyId: checkFolder(treeData, newTreeData) ? treeData[0].id : (data.dropTargetId ? data.dropTargetId : treeData[0].id),
                newPreviousId: data.relativeIndex === 0 ? null : newTreeData[currentindex - 1]?.id ? newTreeData[currentindex - 1].id : null,
                isNewPreviousHierarchy: data.relativeIndex === 0 ? false : (newTreeData[currentindex - 1]?.data?.fileType === "file" ? false : true)
            }
            dispatch(MoveFolder({ folderObj, courseId: allCoursedata[2] }))
        }
    }
    const handleDrop = (newTreeData: any, data: any) => {
        if (!checkChangedValues(initialTreeData, treeData)) {
            setAlertModal(true)
            setDataForCheck(newTreeData, data, "dragAndDrop")
        } else {
            handleDropFunction(newTreeData, data)
        }
    };

    useEffect(() => {
        setCourseType(allCoursedata[3])
        setCourseId(allCoursedata[2])
        let parseData: any = allCoursesDataLocal

        const checkData = () => {
            if (Object.keys(parseData).length) {
                if (parseData[decoded_token.nameid]) {
                    if (parseData[decoded_token.nameid][allCoursedata[2]]) {
                        return parseData[decoded_token.nameid][allCoursedata[2]]
                    } else {
                        return [defaultData]
                        // return []
                    }
                } else {
                    return [defaultData]
                    // return []
                }
            } else {
                // return []
                return [defaultData]
            }
        }
        const decoded_token: any = jwt_decode(loginToken)
        const pagesData = params.type === "questions" ? (questionBankData?.data || []) : checkData()


        const dataForTree = pagesData.length ?
            pagesData?.map((item: any) => {
                const newObj = {} as treeDataType
                newObj.parent = 0
                newObj.editable = false
                newObj.id = item.ID
                newObj.description = item.description
                newObj.PageDataJson = item.PageDataJson
                newObj.mcqData = item.mcqData
                newObj.text = item.heading
                newObj.data = { fileType: 'file' }
                newObj.selected = item.pageId ? null : null
                newObj.order = item.PageOrder
                newObj.multiAnswer = setMultiAnswers(item) ? setMultiAnswers(item) : []
                newObj.Chapter = item.Chapter
                newObj.Subject = item.Subject
                newObj.Topic = item.Topic
                newObj.difficulty = item.Difficulty
                newObj.answered = false
                newObj.correctAnswer = item.Answer
                newObj.rangeTo = item.PageDataJson ? JSON.parse(item.PageDataJson)?.rangeTo : ""
                newObj.Media = item.Media ? item.Media : []
                return newObj
            }) : []
        const assendingData = dataForTree.sort((a: any, b: any) => a.order - b.order)
        const selectedAns = [...assendingData]
        const data = selectedAns.map((item: any) =>
        ({
            pageId: item.id,
            selectedAnswer: ""
        })
        )

        setRegularAnswerArray([...data])
        const result = assendingData[0]
        setActiveCreateEditData(result)
        const subData = convertSubjectData(getTopicData)
        if (result?.PageDataJson) {
            const jsonData = JSON.parse(result?.PageDataJson)
            setQuestionType(jsonData?.questionType)
        }
        setDifficulty(result?.difficulty)
        fillTopicValue(result?.Subject, result?.Chapter, result?.Topic, subData)
        setActiveId(result?.id)
        setpreviewimage(result?.Media)
        setHeaderValue(result?.text)
        setDescriptionValue(result?.description)
        setmcqData(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
        setRadioType(autoSelectOption(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption))
        setCurrentMcqdata(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)

        setTreeData([...assendingData])
        setInitialTreeData([...assendingData])

    }, [getCoursePagesNewData?.length, loaderStatus, getTopicData?.length, questionBankData?.data])

    const newPageActive = () => {
        const localData = localStorage.getItem("activeData")
        if (localData) {
            const parseData = JSON.parse(localData)
            let count2 = 0
            let result2 = 0
            if (parseData.length) {

                for (const item of treeData) {
                    if (item.data.fileType !== "") {
                        count2++
                        if (item.id === parseData[0].ID) {
                            result2 = count2;
                            break;
                        }
                    }
                }

                setActivePaginationPage(result2)
                // const currentIndex = treeData.findIndex((item: any) => item.id === parseData[0].ID)
                const result = treeData[result2 - 1]
                setActiveId(result?.id)
                if (result?.PageDataJson) {
                    const data = JSON.parse(result.PageDataJson)
                    setQuestionType(data?.questionType)
                }

                setActiveCreateEditData(result)
                setpreviewimage(result?.Media)
                setHeaderValue(result?.text)
                setChapterName(result?.hierarchyName)
                setDescriptionValue(result?.description)
                setmcqData(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
                setRadioType(autoSelectOption(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption))
                setCurrentMcqdata(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
                return (result2)
            }
        }
    }
    useEffect(() => {
        newPageActive()
    }, [getCoursePagesNewData, treeData])
    useEffect(() => {
        let count = 0
        let result: any = null;
        for (const item of treeData) {
            if (item.data.fileType !== "") {
                count++;
                if (count === activePaginationPage) {
                    result = item;
                    break;
                }
            }
        }
        localStorage.removeItem("activeData")
    }, [activePaginationPage])

    const fileData = {
        data: { fileType: '' },
        droppable: true,
        editable: false,
        hierarchyId: treeData[0]?.hierarchyId,
        id: 0,
        text: "",
        multiAnswer: [],
        Media: [],
        onFocus: false,
        parent: 0,
    }
    const setDataForCheck = (id: number, data: any, name: string, array?: any) => {
        setModalid(id)
        setChangedModalData(data)
        setChangedModalName(name)
    }
    const addFileFunction = (id: number, data: any, pageType: string) => {
        const checkUnsaveData = treeData.some((e: any) => (e.id).toString().includes("new"))
        if (!checkUnsaveData) {
            setIsChanged(true)
            localStorage.removeItem("activeData")
            setmcqData([...defaultMcqOption])
            setRadioType(autoSelectOption(defaultMcqOption))
            setCurrentMcqdata(defaultMcqOption)
            setEditStatus(false)
            setActivepage("Question")
            setHeaderValue("")
            setChapterName("")
            setpreviewimage([])
            setDescriptionValue("")
            setCreateAndUpdatetype("file")
            // order
            const pageOrder = () => {
                if (pageType === "clicktoAddBelow") {
                    const orderIndex = treeData.findIndex((item: any) => item.id === id)
                    const currentPageOrder = treeData[orderIndex].order
                    if (treeData[orderIndex - 1]) {
                        const nextPageOrder = treeData[orderIndex - 1].order
                        return ((currentPageOrder + nextPageOrder) / 2)
                    } else {
                        return currentPageOrder / 2
                    }

                } else {
                    return Math.ceil(treeData[treeData.length - 1] ? treeData[treeData.length - 1].order : 0) + 1
                }
            }
            const newActiveData = { ...data, order: pageOrder() }
            setActiveCreateEditData(newActiveData)
            setActiveId(`new_${pageOrder()}`)
            setNewtext("")
            setQuestionType("single")



            const newData = {
                id: `new_${pageOrder()}`,
                text: "New page",
                editable: true,
                onFocus: false,
                data: { fileType: "file" },
                parent: 0,
                multiAnswer: [],
                droppable: false,
                selected: null,
                correctAnswer: "",
                order: pageOrder(),
                description: "",
                questionType: "single"
            }
            const newTreeData = [...treeData, newData].sort((a: any, b: any) => a.order - b.order)
            const currentPage = newTreeData.findIndex((item: any) => item.id === `new_${pageOrder()}`)
            setActivePaginationPage(currentPage + 1)
            setTreeData([...newTreeData]);
        }
    }

    const clicktoAddBelow = () => {
        setIsModified(true)
        addFileFunction(alertData.id, fileData, "clicktoAddBelow")
    }

    const addFile = (id: number, data: any, pageType: string) => {
        if (!checkChangedValues(initialTreeData, treeData)) {
            setAlertModal(true)
            setDataForCheck(id, data, "addFile")
        } else {
            setIsModified(true)
            setAlertData({})
            addFileFunction(id, data, pageType)
        }
    }

    const onDelete = () => {
        const { node, id } = alertData
        setDltOpen(false)
        Swal.fire({
            text: `To delete this page?`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#b3b2b2",
            icon: "warning",
            confirmButtonText: "Delete",
            title: "Are you sure",
        }).then((result) => {
            if (result.isConfirmed) {
                if (alertData.id) {
                    if (node.data.fileType === "file") {
                        dispatch(deletePage({
                            pageObj: {
                                Pageid: node.id,
                                PageHierarchy: node.hierarchyId
                            }, courseId: allCoursedata[2], type: params.type === "questions" ? "questions" : ""
                        }))
                    } else {
                        dispatch(deleteHeirarchy({ id: id, courseId: allCoursedata[2] }))
                    }

                    setAlertData({})
                } else {
                }
            }
        });

    };
    const onChange = (e: any, id: number) => {
        if (e.target.value.length <= folderNamelength) {
            const currentIndex = treeData.findIndex((x: any) => x.id === id);
            treeData[currentIndex].text = e.target.value
            setTreeData([...treeData])
            setNewtext(e.target.value)
        }
    }
    const saveClick = (id: number) => {
        const currentIndex = treeData.findIndex((x: any) => x.id === id);
        if (newText) {
            treeData[currentIndex].editable = false
            treeData[currentIndex].onFocus = false
            treeData[currentIndex].text = newText
            setIsFocused(false)
            setTreeData([...treeData])
        }
    }

    const onBlur = (id: number) => {
    }
    const onFocusFunc = () => setIsFocused(true)

    const descriptionChange = (e: any, type: string, delta?: any) => {
        const currentIndex = treeData.findIndex((x: any) => x.id === activeId);
        const latestChange = delta.ops[delta.ops.length - 1];
        if (latestChange.insert && latestChange.insert.length === 1) {
            const keyCode = latestChange.insert.charCodeAt(0);
            const modifiedHTML = e.replaceAll("float: right", "vertical-align: middle");

            if (keyCode === 9) {
                setEditorOnFocus(returnFocus(type))
            } else {
                if (currentIndex >= 0) {
                    if (type === "header") {
                        if (e !== "<p><br></p>") {
                            treeData[currentIndex].text = modifiedHTML
                        }
                        setHeaderValue(modifiedHTML)
                    } else if (type === "description") {
                        setDescriptionValue(modifiedHTML)
                        if (e !== "<p><br></p>") {
                            treeData[currentIndex].description = modifiedHTML
                        } else {
                            treeData[currentIndex].description = ""
                        }
                    }
                }
            }
        } else {
            if (currentIndex >= 0) {
                if (type === "header") {
                    if (e !== "<p><br></p>") {
                        treeData[currentIndex].text = e
                    }
                    setHeaderValue(e)
                } else if (type === "description") {
                    if (e !== "<p><br></p>") {
                        treeData[currentIndex].description = e
                    } else {
                        treeData[currentIndex].description = ""
                    }
                    setDescriptionValue(e)
                }
            }
        }

    }

    const draggerChange = (e: any) => {
        const dataaaa = cameraValue.filter((item: any) => item.name === e.name)
        if (!dataaaa.length) {
            if (e) {
                const reader = new FileReader();
                reader.onload = () => {
                    const result = reader.result;

                    if (typeof result === 'string') {
                        setimageBase64(result)
                        setinitialPreview(result)
                    } else if (result instanceof ArrayBuffer) {
                        const textDecoder = new TextDecoder('utf-8');
                        const resultString = textDecoder.decode(result);
                        setimageBase64(resultString);
                        setinitialPreview(resultString);
                    }
                };
                reader.readAsDataURL(e);
            }
            setcameraOpen(false)
            if (e.type.includes("image")) {
                setpreviewDilouge(false)
            } else {
                setpreviewimage([...previewimage, e])
            }
            setimageBase64(e)
            setcameraDialouge(true)
            // setpreviewDilouge(false)
            setcameraValue([...cameraValue, e])
        }
    }
    const openCamera = () => {
        setcameraDialouge(false)
        setpreviewDilouge(true)
        setcameraOpen(true)
    }

    const handleImageLoad = (item: any) => {
        const canvas = document.createElement('canvas');
        const img = document.createElement('img');
        img.crossOrigin = 'Anonymous';
        let dataUrl: any
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx: any = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, img.width, img.height);

            dataUrl = canvas.toDataURL('image/png');
            setimageBase64(dataUrl)
            setinitialPreview(dataUrl)
        };
        img.src = item;
        return dataUrl
    };
    const editImageCrop = (item: any, index: number) => {
        setpreviewDilouge(false)
        setEditImage(true)
        setActiveImageIndex(index)
        handleImageLoad(item)
        setCurrentEditImgUrl(item)
    }
    const deleteCameraImg = (item: string, index: number) => {
        if (!isBase64Url(item)) {
            setDeletedimage([...deletedimage, item])
        }
        const deletedData = previewimage.filter((item: any, ind: number) => {
            return ind !== index
        })
        const deleteCameraValue = cameraValue.filter((item: any, ind: number) => {
            return ind !== index
        })
        setcameraOpen(false)
        setcameraValue([...deleteCameraValue])
        setpreviewimage([...deletedData])
    }
    const handleClose = (event: any, reason: any) => {
        if (reason === "backdropClick") {
            setpreviewDilouge(false)
        } else {
            setpreviewDilouge(true)
            setcameraDialouge(true)
        }
    }
    const closeModal = () => {
        setcameraOpen(false)
        setcameraDialouge(true)
        setpreviewDilouge(true)
    }
    const dataURLtoFile = (dataurl: any, filename: any) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    const handleTakePhoto = (data: any) => {
        const file = dataURLtoFile(data, 'hello.png')
        setcameraOpen(false)
        setcameraDialouge(true)
        setimageBase64(data)
        setinitialPreview(data)
        setpreviewDilouge(false)
        setcameraValue([...cameraValue, file])
    }
    const callBackFunction = (data: string) => {
        if (editImage) {
            setUpdatedImage(data)
        } else {
            setimageBase64(data)
        }
    }
    const croppedImage = () => {
        setcameraOpen(false)
        if (editImage) {
            if (activeEditIndex !== null && activeEditIndex >= 0) {
                previewimage[activeEditIndex] = updatedImage
                setDeletedimage([...deletedimage, currentEditImgUrl])
            }
        } else {
            setpreviewimage([...previewimage, imageBase64])
        }
        setcameraDialouge(true)
        setpreviewDilouge(true)
    }

    const optionFieldChange = (e: any, type: string, delta?: any) => {
        const latestChange = delta.ops[delta.ops.length - 1];
        if (latestChange.insert && latestChange.insert.length === 1) {
            const keyCode = latestChange.insert.charCodeAt(0);
            if (keyCode === 9) {
                setEditorOnFocus(returnFocus(type))
            } else {
                const index = Number(type)
                mcqData[index] = e
                setmcqData(mcqData)
                // setCurrentMcqdata(mcqData)
            }
        }
        else {
            const index = Number(type)
            mcqData[index] = e
            setmcqData(mcqData)
            // setCurrentMcqdata(mcqData)
        }
    }
    const onToggleOneChange = (ind: string) => {
        const currentIndex = treeData.findIndex((item: any) => item.id === activeId)
        if (questionType === "single") {
            if (treeData[currentIndex].correctAnswer === ind) {
                activeCreateEditData.correctAnswer = ""
                treeData[currentIndex].correctAnswer = ""

                setActiveCreateEditData(activeCreateEditData)
                setTreeData([...treeData])
            } else {
                activeCreateEditData.correctAnswer = ind
                treeData[currentIndex].correctAnswer = ind

                setActiveCreateEditData(activeCreateEditData)
                setTreeData([...treeData])
            }
        } else if (questionType === "multiple") {
            const array = treeData[currentIndex].multiAnswer
            if (treeData[currentIndex].multiAnswer.includes(ind)) {
                const filterAnswer = array.filter((item: any) => item !== ind)
                treeData[currentIndex].multiAnswer = filterAnswer
                activeCreateEditData.multiAnswer = filterAnswer

                setActiveCreateEditData(activeCreateEditData)
                setTreeData([...treeData])
            } else {
                treeData[currentIndex].multiAnswer.push(ind)
                activeCreateEditData.multiAnswer.push(ind)
                setTreeData([...treeData])

                setActiveCreateEditData(activeCreateEditData)
            }
        }

    }


    const validateMcqValidation: any = (array: any) => {
        const emptyValue = "";
        const placeholderValue = "<p><br></p>";

        let allEmptyOrPlaceholder = true;
        let allNonEmptyAndNonPlaceholder = true;

        for (let value of array) {
            if (value !== emptyValue && value !== placeholderValue) {
                allEmptyOrPlaceholder = false;
            }
            if (value === emptyValue || value === placeholderValue) {
                allNonEmptyAndNonPlaceholder = false;
            }
        }

        return allEmptyOrPlaceholder || allNonEmptyAndNonPlaceholder;
    }

    const answers = (item?: any) => {
        if (jeeAvailable === "jee_true") {
            if (questionType === "single") {
                return activeCreateEditData?.correctAnswer
            } else if (questionType === "multiple") {
                const val = activeCreateEditData?.multiAnswer.toString()
                return val
            } else if (questionType === "text") {

                return textAnswerValue ? textAnswerValue.toString() : activeCreateEditData?.correctAnswer
            }
        } else {
            if (questionType === "single") {
                return activeCreateEditData?.correctAnswer
            }
        }
    }
    const questionBankAnswers = (item?: any) => {
        if (jeeAvailable === "jee_true") {
            if (questionType === "single") {
                return activeCreateEditData?.correctAnswer
            } else if (questionType === "multiple") {
                const val = activeCreateEditData?.multiAnswer.toString()
                return val
            } else if (questionType === "text") {

                return textAnswerValue ? textAnswerValue.toString() : activeCreateEditData?.correctAnswer
            }
        } else {
            if (questionType === "single") {
                return activeCreateEditData?.correctAnswer
            }
        }
    }

    useEffect(() => {
        if (hasSubmitted) {

            if (!validateTextWithTag(headerValue) && !previewimage.length) {
                setSubmissionError((prevState: any) => ({
                    ...prevState,
                    headerValue: "Please enter header"
                }))
            } else {
                setSubmissionError((prevState: any) => ({
                    ...prevState,
                    headerValue: ""
                }))
            }
            // if (!subjectId) {
            //     setSubmissionError((prevState: any) => ({
            //         ...prevState,
            //         subject: "Please select subject"
            //     }))
            // } else {
            //     setSubmissionError((prevState: any) => ({
            //         ...prevState,
            //         subject: ""
            //     }))
            // }
            // if (!chapterId) {

            //     setSubmissionError((prevState: any) => ({
            //         ...prevState,
            //         chapter: "Please select chapter"
            //     }))
            // } else {
            //     setSubmissionError((prevState: any) => ({
            //         ...prevState,
            //         chapter: ""
            //     }))
            // }
            // if (!topicId) {

            //     setSubmissionError((prevState: any) => ({
            //         ...prevState,
            //         topic: "Please select topic"
            //     }))
            // } else {
            //     setSubmissionError((prevState: any) => ({
            //         ...prevState,
            //         topic: ""
            //     }))
            // }
            // if (difficulty !== null || difficulty !== undefined || difficulty < 0) {

            //     setSubmissionError((prevState: any) => ({
            //         ...prevState,
            //         difficulty: ""
            //     }))
            // } else {
            //     setSubmissionError((prevState: any) => ({
            //         ...prevState,
            //         difficulty: "Please select difficulty level"
            //     }))
            // }
            if (courseType === "Test") {
                if (jeeAvailable === "jee_true") {
                    if (questionType === "single" || questionType === "multiple") {
                        if (mcqData.includes("")) {
                            setSubmissionError((prevState: any) => ({
                                ...prevState,
                                mcqOption: "Please Fill MCQ options"
                            }))
                        } else {
                            setSubmissionError((prevState: any) => ({
                                ...prevState,
                                mcqOption: ""
                            }))
                        }
                        if (answers() === undefined || answers() === "") {
                            setSubmissionError((prevState: any) => ({
                                ...prevState,
                                correctAnswer: "Please Select correct answer"
                            }))
                        } else {
                            setSubmissionError((prevState: any) => ({
                                ...prevState,
                                correctAnswer: ""
                            }))
                        }
                    } else if (questionType === "text") {
                        if (activeCreateEditData.correctAnswer !== undefined || activeCreateEditData.correctAnswer !== "") {
                            setSubmissionError((prevState: any) => ({
                                ...prevState,
                                correctAnswer: ""
                            }))
                        } else {
                            setSubmissionError((prevState: any) => ({
                                ...prevState,
                                correctAnswer: "Please fill from value"
                            }))
                        }
                        if (activeCreateEditData.rangeTo !== undefined || activeCreateEditData.rangeTo !== "") {
                            setSubmissionError((prevState: any) => ({
                                ...prevState,
                                correctAnswer: ""
                            }))
                        } else {
                            setSubmissionError((prevState: any) => ({
                                ...prevState,
                                correctAnswer: "Please fill range"
                            }))
                        }
                    }


                } else {
                    if (mcqData.includes("")) {
                        setSubmissionError((prevState: any) => ({
                            ...prevState,
                            mcqOption: "Please Fill MCQ options"
                        }))
                    } else {
                        setSubmissionError((prevState: any) => ({
                            ...prevState,
                            mcqOption: ""
                        }))
                    }
                    if (answers() === undefined || answers() === "") {
                        setSubmissionError((prevState: any) => ({
                            ...prevState,
                            correctAnswer: "Please Select correct answer"
                        }))
                    } else {
                        setSubmissionError((prevState: any) => ({
                            ...prevState,
                            correctAnswer: ""
                        }))
                    }
                }

            }
        }
    }, [headerValue, previewimage, subjectId, chapterId, topicId, difficulty, hasSubmitted, answers(), JSON.stringify(mcqData)])

    const checkValidate = () => {
        if (validateTextWithTag(headerValue) || previewimage.length) {
            // if ((subjectId >= 0 && subjectId !== "") && (chapterId >= 0 && chapterId !== "") && (topicId >= 0 && topicId !== "") && (difficulty !== null || difficulty !== undefined) && headerValue) {
            if (courseType === "Test") {
                if (answers() !== undefined && answers() !== "") {
                    if (((questionType === "single" || questionType === "multiple"))) {
                        if (!mcqData.includes("")) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            }
            else {
                return true
            }
        }
        // if (headerValue && (subjectId >= 0 && subjectId !== "") && (chapterId >= 0 && chapterId !== "") && (topicId >= 0 && topicId !== "") && (difficulty !== null || difficulty !== undefined) && (courseType === "Test" && !mcqData.includes("")) && (answers() !== undefined && answers() !== "")) {
        //     if(&& ((courseType === "Test" && questionType !== "text") && !mcqData.includes("")) )
        //     return true
        // } else {
        //     return false
        // }
    }

    const makeQuestions = async () => {

        if (selectedQuestionBank.length) {
            let SuccessCount: number = 0
            let failedCount: number = 0
            for (const item of selectedQuestionBank) {
                const payloadData = {
                    courseId: (selectedCourse.courseId).toString(),
                    answer: questionBankAnswers(item),
                    files: item?.Media,
                    heading: item?.text,
                    description: item.description,
                    mcqData: item?.mcqData,
                    pageOrder: null,
                    "subject": item.Subject ? item?.Subject : null,
                    "chapter": item?.Chapter ? item?.Chapter : null,
                    "topic": item?.Topic ? item?.Topic : null,
                    difficulty: difficulty,
                    PageDataJson: item?.PageDataJson
                };


                try {
                    const response = await fetchPost("CreatorStudios/AddPage", payloadData);
                    if (response?.result === "Success") {
                        // Handle success if necessary
                        SuccessCount = SuccessCount + 1
                    } else {
                        // openNotification("error", "Something went wrong");
                        failedCount = failedCount + 1
                        setIsSuccess(false);
                    }
                } catch (error) {
                    // Handle error if necessary
                }
            }
            const text = () => {
                if (SuccessCount && failedCount) {
                    return `${SuccessCount} ${SuccessCount > 1 ? "Questions" : "Question"} have been added successfully and 
                    ${failedCount} ${failedCount > 1 ? "Questions" : "Question"} have not been added.`
                } else if (SuccessCount) {
                    return `${SuccessCount} ${SuccessCount > 1 ? "Questions" : "Question"} have been added successfully`
                } else if (failedCount) {
                    return `${failedCount} ${failedCount > 1 ? "Questions" : "Question"} have not been added.`
                }else{
                    return ""
                }
            }

            openNotification("success", text());
        }
    }

    const saveClickcc = async (text?: any) => {
        setHasSubmitted(true)
        if (checkValidate()) {
            const formdata: any = new FormData()
            const array = previewimage.map((item: any, index: number) => {
                if (typeof item === "string") {
                    if (isBase64Url(item)) {
                        return dataURLtoFile(item, `image${index}.png`);
                    }
                } else {
                    return item
                }
            }).filter((elem: any) => elem)

            for (let i = 0; i < array.length; i++) {
                const element = array[i];
                if (element) {
                    formdata.append("files", element)
                }
            }
            if (isOnline) {
                const requestOptions: any = {
                    method: 'POST',
                    headers: { Authorization: `${loginToken}` },
                    body: formdata
                };
                dispatch(actionLoader({ spinnerStatus: true }))
                if (array.length) {
                    fetch(`${process.env.REACT_APP_API_URL}${uploadMedia}`, requestOptions)
                        .then(response => response.json())
                        .then(async (resData: any) => {
                            if (text === "makeQuestion") {
                                const stringData = {
                                    isJee: jeeAvailable === "jee_true",
                                    questionType,
                                    answer: answers(),
                                    rangeTo: questionType === "text" ? activeCreateEditData.rangeTo : ""
                                }

                                const payloadData: any = {
                                    courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                                    answer: answers(),
                                    files: resData.result,
                                    heading: headerValue,
                                    description: descriptionValue,
                                    mcqData: JSON.stringify(mcqData),
                                    pageOrder: activeCreateEditData.order,
                                    "subject": subjectId ? subjectId : null,
                                    "chapter": chapterId ? chapterId : null,
                                    "topic": topicId ? topicId : null,
                                    difficulty: difficulty
                                }
                                if (jeeAvailable === "jee_true") {
                                    payloadData.PageDataJson = JSON.stringify(stringData)
                                }

                                try {
                                    setIsSuccess(true)
                                    const response = await fetchPost("CreatorStudios/AddPage", payloadData)
                                    if (response?.result === "Success") {
                                        if (params.type === "questions") {
                                            filterClick()
                                        } else {
                                            dispatch(GetCoursePagesNew({ courseId: allCoursedata[2], auto: checkAi(allCoursedata[5]), type: params.type === "questions" ? "questions" : "" }))
                                        }
                                        setIsChanged(false)
                                        setHasSubmitted(false)
                                        setEditStatus(true)
                                        setIsSuccess(false)
                                        setAlertData({})
                                    } else {
                                        openNotification("error", "Somwthing wet wrong")
                                        setIsSuccess(false)
                                    }

                                } catch (error) {
                                }
                            } else {
                                if (activeCreateEditData?.id) {
                                    const deletedString = deletedimage.map((item: any) => {
                                        const data = item.split('?')[0]
                                        return data
                                    })
                                    const currentIndex = (params.type === "questions" ? (questionBankData?.data) : getCoursePagesNewData).findIndex((item: any) => item.ID === activeCreateEditData.id)
                                    const compareData = (params.type === "questions" ? (questionBankData?.data) : getCoursePagesNewData)[currentIndex]
                                    const stringData = {
                                        isJee: jeeAvailable === "jee_true",
                                        questionType,
                                        answer: answers()
                                    }
                                    const payloadData: any = {
                                        pageId: activeCreateEditData.id,
                                        addedFiles: resData.result,
                                        deletedFiles: deletedString,
                                        courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                                        answer: activeCreateEditData?.correctAnswer ? answers() : "",
                                        heading: headerValue ? headerValue : "",
                                        description: descriptionValue ? descriptionValue : "",
                                        mcqData: JSON.stringify(mcqData) ? JSON.stringify(mcqData) : "",
                                        pageOrder: activeCreateEditData.order ? activeCreateEditData.order : "",
                                        difficulty: difficulty,
                                        "subject": subjectId ? subjectId : -1,
                                        "chapter": chapterId ? chapterId : -1,
                                        "topic": topicId ? topicId : -1,
                                    }
                                    if (jeeAvailable === "jee_true") {
                                        payloadData.PageDataJson = JSON.stringify(stringData)
                                    }
                                    try {
                                        setIsSuccess(true)
                                        const response = await fetchPost("CreatorStudios/UpdatePage", payloadData)
                                        if (response?.result === "Success") {
                                            if (params.type === "questions") {
                                                dispatch(GetQuestionBank({
                                                    "startRow": 0,
                                                }))
                                            } else {
                                                dispatch(GetCoursePagesNew({ courseId: allCoursedata[2], auto: checkAi(allCoursedata[5]), type: params.type === "questions" ? "questions" : "" }))
                                            }
                                            setIsChanged(false)
                                            setEditStatus(true)
                                            setHasSubmitted(false)
                                            setIsFocused(false)
                                            setIsSuccess(false)
                                            setAlertData({})


                                        } else {
                                            setIsSuccess(false)
                                            openNotification("error", "Somwthing wet wrong")
                                        }

                                    } catch (error) {
                                    }



                                    // dispatch(updatePages({ data: payloadData, courseId: allCoursedata[2], type: params.type === "questions" ? "questions" : "" }))
                                    // setEditStatus(true)
                                    // setHasSubmitted(false)
                                    // setIsFocused(false)
                                } else {
                                    const stringData = {
                                        isJee: jeeAvailable === "jee_true",
                                        questionType,
                                        answer: answers(),
                                        rangeTo: questionType === "text" ? activeCreateEditData.rangeTo : ""
                                    }
                                    const payloadData: any = {
                                        courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                                        answer: answers(),
                                        files: resData.result,
                                        heading: headerValue,
                                        description: descriptionValue,
                                        mcqData: JSON.stringify(mcqData),
                                        pageOrder: activeCreateEditData.order,
                                        "subject": subjectId ? subjectId : null,
                                        "chapter": chapterId ? chapterId : null,
                                        "topic": topicId ? topicId : null,
                                        difficulty: difficulty
                                    }
                                    if (jeeAvailable === "jee_true") {
                                        payloadData.PageDataJson = JSON.stringify(stringData)
                                    }
                                    try {
                                        setIsSuccess(true)
                                        const response = await fetchPost("CreatorStudios/AddPage", payloadData)
                                        if (response?.result === "Success") {
                                            if (params.type === "questions") {
                                                filterClick()
                                            } else {
                                                dispatch(GetCoursePagesNew({ courseId: allCoursedata[2], auto: checkAi(allCoursedata[5]), type: params.type === "questions" ? "questions" : "" }))
                                            }
                                            setIsChanged(false)
                                            setHasSubmitted(false)
                                            setEditStatus(true)
                                            setIsSuccess(false)
                                            setAlertData({})

                                        } else {
                                            openNotification("error", "Somwthing wet wrong")
                                            setIsSuccess(false)
                                        }

                                    } catch (error) {
                                    }
                                }
                            }

                        })
                } else {
                    const deletedString = deletedimage.map((item: any) => {
                        const data = item.split('?')[0]
                        return data
                    })
                    if (text === "makeQuestion") {
                        const stringData = {
                            isJee: jeeAvailable === "jee_true",
                            questionType,
                            answer: answers(),
                            rangeTo: questionType === "text" ? activeCreateEditData.rangeTo : ""
                        }
                        const payloadData: any = {
                            courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                            answer: answers(),
                            files: [],
                            description: descriptionValue,
                            pageOrder: activeCreateEditData.order,
                            mcqData: JSON.stringify(mcqData),
                            heading: headerValue,
                            "subject": subjectId ? subjectId : null,
                            "chapter": chapterId ? chapterId : null,
                            "topic": topicId ? topicId : null,
                            difficulty: difficulty
                        }
                        if (jeeAvailable === "jee_true") {
                            payloadData.PageDataJson = JSON.stringify(stringData)
                        }
                        try {
                            setIsSuccess(true)
                            const response = await fetchPost("CreatorStudios/AddPage", payloadData)
                            if (response?.result === "Success") {
                                if (params.type === "questions") {
                                    filterClick()
                                } else {
                                    dispatch(GetCoursePagesNew({ courseId: allCoursedata[2], auto: checkAi(allCoursedata[5]), type: params.type === "questions" ? "questions" : "" }))
                                }
                                setHasSubmitted(false)
                                setIsChanged(false)
                                defaultMcqOption = ["", "", "", ""]
                                setEditStatus(true)
                                setIsSuccess(false)
                                setAlertData({})

                            } else {
                                openNotification("error", "Somwthing wet wrong")
                                setIsSuccess(false)
                            }

                        } catch (error) {
                        }
                    } else {
                        if (activeCreateEditData?.id) {
                            const currentIndex = (params.type === "questions" ? (questionBankData?.data) : getCoursePagesNewData).findIndex((item: any) => item.ID === activeCreateEditData.id)
                            const compareData = (params.type === "questions" ? (questionBankData?.data) : getCoursePagesNewData)[currentIndex]
                            const stringData = {
                                isJee: jeeAvailable === "jee_true",
                                questionType,
                                answer: answers(),
                                rangeTo: questionType === "text" ? activeCreateEditData.rangeTo : ""
                            }
                            const payloadData: any = {
                                pageId: activeCreateEditData.id,
                                courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                                addedFiles: [],
                                deletedFiles: deletedString,
                                answer: compareData.Answer === activeCreateEditData?.correctAnswer ? "" : answers(),
                                heading: compareData.heading === headerValue ? "" : headerValue,
                                description: compareData.description === descriptionValue ? "" : descriptionValue,
                                mcqData: compareData.mcqData === JSON.stringify(mcqData) ? "" : JSON.stringify(mcqData),
                                pageOrder: compareData.PageOrder === activeCreateEditData.order ? "" : activeCreateEditData.order,
                                difficulty: difficulty,
                                "subject": subjectId ? subjectId : -1,
                                "chapter": chapterId ? chapterId : -1,
                                "topic": topicId ? topicId : -1,
                            }
                            if (jeeAvailable === "jee_true") {
                                payloadData.PageDataJson = JSON.stringify(stringData)
                            }
                            for (let key in payloadData) {
                                if ((payloadData[key] === "")) {
                                    delete payloadData[key];
                                }
                            }

                            try {
                                setIsSuccess(true)
                                const response = await fetchPost("CreatorStudios/UpdatePage", payloadData)
                                if (response?.result === "Success") {
                                    if (params.type === "questions") {
                                        dispatch(GetQuestionBank({
                                            "startRow": 0,
                                        }))
                                    } else {
                                        dispatch(GetCoursePagesNew({ courseId: allCoursedata[2], auto: checkAi(allCoursedata[5]), type: params.type === "questions" ? "questions" : "" }))
                                    }
                                    setIsChanged(false)
                                    setHasSubmitted(false)
                                    defaultMcqOption = ["", "", "", ""]
                                    setEditStatus(true)
                                    setIsSuccess(false)
                                    setAlertData({})

                                } else {
                                    openNotification("error", "Somwthing wet wrong")
                                    setIsSuccess(false)
                                }

                            } catch (error) {
                            }

                            // dispatch(updatePages({ data: payloadData, courseId: allCoursedata[2], type: params.type === "questions" ? "questions" : "" }))
                            // setHasSubmitted(false)
                            // defaultMcqOption = ["", "", "", ""]
                            // setEditStatus(true)
                        } else {

                            const stringData = {
                                isJee: jeeAvailable === "jee_true",
                                questionType,
                                answer: answers(),
                                rangeTo: questionType === "text" ? activeCreateEditData.rangeTo : ""
                            }
                            const payloadData: any = {
                                courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                                answer: answers(),
                                files: [],
                                description: descriptionValue,
                                pageOrder: activeCreateEditData.order,
                                mcqData: JSON.stringify(mcqData),
                                heading: headerValue,
                                "subject": subjectId ? subjectId : null,
                                "chapter": chapterId ? chapterId : null,
                                "topic": topicId ? topicId : null,
                                difficulty: difficulty
                            }
                            if (jeeAvailable === "jee_true") {
                                payloadData.PageDataJson = JSON.stringify(stringData)
                            }
                            try {
                                setIsSuccess(true)
                                const response = await fetchPost("CreatorStudios/AddPage", payloadData)
                                if (response?.result === "Success") {
                                    if (params.type === "questions") {
                                        filterClick()
                                    } else {
                                        dispatch(GetCoursePagesNew({ courseId: allCoursedata[2], auto: checkAi(allCoursedata[5]), type: params.type === "questions" ? "questions" : "" }))
                                    }
                                    setHasSubmitted(false)
                                    setIsChanged(false)
                                    defaultMcqOption = ["", "", "", ""]
                                    setEditStatus(true)
                                    setIsSuccess(false)
                                    setAlertData({})

                                } else {
                                    openNotification("error", "Somwthing wet wrong")
                                    setIsSuccess(false)
                                }

                            } catch (error) {
                            }
                            // dispatch(addPages({ data: payloadData, courseId: allCoursedata[2], auto: checkAi(allCoursedata[5]), type: params.type === "questions" ? "questions" : "" }))
                        }
                    }

                }
            } else {
                if (array.length) {
                    const offlinePageData = localStorage.getItem("offlineData")
                    if (activeCreateEditData?.id) {
                        const deletedString = deletedimage.map((item: any) => {
                            const data = item.split('?')[0]
                            return data
                        })
                        const payloadData = {
                            pageId: activeCreateEditData.id,
                            deletedFiles: deletedString,
                            courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                            answer: answers(),
                            heading: headerValue,
                            description: descriptionValue,
                            mcqData: JSON.stringify(mcqData),
                            pageOrder: activeCreateEditData.order,
                            upload: true,
                            uploadData: JSON.stringify(previewimage),
                            "subject": subjectId ? subjectId : null,
                            "chapter": chapterId ? chapterId : null,
                            "topic": topicId ? topicId : null,
                        }

                        if (offlinePageData) {
                            const offlinePage = JSON.parse(offlinePageData)
                            const findIndex = offlinePage?.updatePageData.findIndex((item: any) => item.heading === payloadData.heading)
                            if (findIndex < 0) {
                                offlinePage?.updatePageData?.push(payloadData)
                                setStorageFunction("offlineData", JSON.stringify(offlinePage))
                            }
                        }

                    } else {
                        const payloadData = {
                            courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                            answer: answers(),
                            heading: headerValue,
                            description: descriptionValue,
                            mcqData: JSON.stringify(mcqData),
                            pageOrder: activeCreateEditData.order,
                            upload: true,
                            uploadData: JSON.stringify(previewimage),
                            "subject": subjectId ? subjectId : null,
                            "chapter": chapterId ? chapterId : null,
                            "topic": topicId ? topicId : null,
                        }
                        if (offlinePageData) {
                            const offlinePage = JSON.parse(offlinePageData)
                            const findIndex = offlinePage?.addPageData.findIndex((item: any) => item.heading === payloadData.heading)
                            if (findIndex < 0) {
                                offlinePage?.addPageData?.push(payloadData)
                                setStorageFunction("offlineData", JSON.stringify(offlinePage))
                                setIsChanged(false)
                            }
                        }
                    }
                } else {
                    const deletedString = deletedimage.map((item: any) => {
                        const data = item.split('?')[0]
                        return data
                    })
                    const offlinePageData = localStorage.getItem("offlineData")
                    if (activeCreateEditData?.id) {
                        const payloadData = {
                            deletedFiles: deletedString,
                            courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                            answer: answers(),
                            heading: headerValue,
                            description: descriptionValue,
                            mcqData: JSON.stringify(mcqData),
                            pageOrder: activeCreateEditData.order,
                            "subject": subjectId ? subjectId : null,
                            "chapter": chapterId ? chapterId : null,
                            "topic": topicId ? topicId : null,
                        }
                        if (offlinePageData) {
                            const offlinePage = JSON.parse(offlinePageData)
                            const findIndex = offlinePage?.updatePageData.findIndex((item: any) => item.heading === payloadData.heading)
                            if (findIndex < 0) {
                                offlinePage?.updatePageData?.push(payloadData)
                                setStorageFunction("offlineData", JSON.stringify(offlinePage))
                                setIsChanged(false)
                            }
                        }

                    } else {
                        const payloadData = {
                            courseId: (text === "makeQuestion") ? selectedCourse.courseId : courseId,
                            answer: answers(),
                            files: [],
                            heading: headerValue,
                            description: descriptionValue,
                            mcqData: JSON.stringify(mcqData),
                            pageOrder: activeCreateEditData.order,
                            "subject": subjectId ? subjectId : null,
                            "chapter": chapterId ? chapterId : null,
                            "topic": topicId ? topicId : null,
                        }
                        if (offlinePageData) {
                            const offlinePage = JSON.parse(offlinePageData)
                            const findIndex = offlinePage?.addPageData.findIndex((item: any) => item.heading === payloadData.heading)
                            if (findIndex < 0) {
                                offlinePage?.addPageData?.push(payloadData)
                                setStorageFunction("offlineData", JSON.stringify(offlinePage))
                                setIsChanged(false)
                            }
                        }
                    }
                }
            }
        }
        else {
            // alert("Flll or remove mcq option")
        }

    }

    const setActivePageData = (id: number, data: any) => {
        if (data?.PageDataJson) {
            const jsonData = JSON.parse(data?.PageDataJson)
            setQuestionType(jsonData?.questionType)
        } else {
            setQuestionType("single")
        }
        setActiveId(id)
        const currentIndex = regularAnswerArray.findIndex(item => item.pageId === id)
        fillTopicValue(data?.Subject, data?.Chapter, data?.Topic, subjectData)
        setDifficulty(data?.difficulty)
        if (currentIndex >= 0) {
            setRegularSelectedAns(regularAnswerArray[currentIndex].selectedAnswer)
        } else {
            setRegularSelectedAns("")

        }
        setpreviewimage(data?.Media)

        setActivePaginationPage(activePageId(data) + 1)

        setActiveCreateEditData(data)
        setChapterName(data?.hierarchyName)
        setHeaderValue(data?.text)
        setDescriptionValue(data?.description)
        setRadioType(autoSelectOption(data?.mcqData ? JSON.parse(data?.mcqData) : defaultMcqOption))
        setmcqData(data?.mcqData ? JSON.parse(data?.mcqData) : defaultMcqOption)
        setCurrentMcqdata(data?.mcqData ? JSON.parse(data?.mcqData) : defaultMcqOption)
    }


    const clickOnPage = (data: any, id: number) => {
        if (!checkChangedValues(initialTreeData, treeData)) {
            setAlertModal(true)
            setDataForCheck(id, data, "pageClicked")
        } else {
            setActivePageData(id, data)
        }
    }

    const paginationClick = (data: any, val: any) => {
        setRegularSelectedAns(regularAnswerArray[val - 1].selectedAnswer)

        setActivePaginationPage(val)
        let count = 0;
        let result = null;
        for (const item of treeData) {
            if (item.data.fileType !== "") {
                count++;
                if (count === val) {
                    result = item;
                    break;
                }
            }
        }
        setActiveId(result.id)

        setActiveCreateEditData(result)
        setpreviewimage(result?.Media)
        setHeaderValue(result?.text)
        setChapterName(result?.hierarchyName)
        if (result?.PageDataJson) {
            const jsonData = JSON.parse(result?.PageDataJson)
            setQuestionType(jsonData?.questionType)
        }
        setDescriptionValue(result?.description)
        fillTopicValue(result?.Subject, result?.Chapter, result?.Topic, subjectData)
        setDifficulty(result?.difficulty)
        setRadioType(autoSelectOption(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption))
        setmcqData(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
        setCurrentMcqdata(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
    }
    const paginationChange = (data: any, val: any) => {
        if (!checkChangedValues(initialTreeData, treeData)) {
            setAlertModal(true)
            setDataForCheck(data, val, "paginationClicked")
        } else {
            paginationClick(data, val)
        }
    }

    const checkActive = () => {
        let count = 0
        let result = null;
        for (const item of treeData) {
            if (item.data.fileType !== "") {
                count++;
                if (count === activePaginationPage) {
                    result = item;
                    break;
                }
            }
        }
        return result

    }
    const activePageId = (data: any) => {
        let count2 = 0
        let result2 = 0
        for (const item of treeData) {
            if (item.data.fileType !== "") {
                count2++
                if (item.id === data.id) {
                    result2 = count2;
                    break;
                }
            }
        }

        return (result2 - 1)
    }

    const paginationCount = () => {
        const length = treeData.filter(((item: any) => item.data.fileType)).length
        if (length) {
            return length
        } else {
            return 0
        }
    }
    const modifyClick = () => {
        if (!checkChangedValues(initialTreeData, treeData)) {
            setChangedModalName("modifyClick")
            setAlertModal(true)
            setEditStatus(true)
        } else {
            setAlertModal(false)
            setIsModified(!isModified)
            setEditStatus(true)
        }
    }



    const selectAnswer = (e: React.ChangeEvent<HTMLInputElement>, index: number, name?: string) => {
        if (isDeselecting.current) {
            isDeselecting.current = false; // Reset the flag after handling deselection
            return;
        }
        if (name === "multiple") {
            let val: any
            if (regularSelectedAns) {
                if (typeof regularSelectedAns === "string") {
                    val = regularSelectedAns.split(",")
                } else {
                    val = regularSelectedAns
                }
            } else {
                val = []
            }
            if (val.includes(index.toString())) {
                const data = val.filter((item: any) => item !== index.toString())
                setRegularSelectedAns(data)
            } else {
                setRegularSelectedAns([...val, index.toString()])
            }
        } else {
            if (index.toString() !== regularSelectedAns) {
                setRegularSelectedAns(index.toString())
            } else {
                setRegularSelectedAns("")
            }
        }
    }

    const removeAnswer = (index: number, name?: string, e?: any) => {
        e?.stopPropagation();
        if (name === "multiple") {
            let val: any
            if (regularSelectedAns) {
                if (typeof regularSelectedAns === "string") {
                    val = regularSelectedAns.split(",")
                } else {
                    val = regularSelectedAns
                }
            } else {
                val = []
            }
            if (val.includes(index.toString())) {
                const data = val.filter((item: any) => item !== index.toString());
                isDeselecting.current = true;
                setRegularSelectedAns(data)
            }
        } else {
            if (index.toString() === regularSelectedAns) {
                isDeselecting.current = true;
                setRegularSelectedAns("");

            }
        }
    }

    const saveAndNextClick = () => {
        const currentTreeIndex = treeData.findIndex((vale: any) => vale.id === activeId)
        regularAnswerArray[currentTreeIndex].selectedAnswer = regularSelectedAns
        if (regularAnswerArray.length > (currentTreeIndex + 1)) {

            setRegularSelectedAns(regularAnswerArray[currentTreeIndex + 1].selectedAnswer)
            setRegularAnswerArray([...regularAnswerArray])
            setTreeData([...treeData])

            const nextActiveData = treeData[currentTreeIndex + 1]
            setActiveId(nextActiveData.id)

            setActiveCreateEditData(nextActiveData)

            fillTopicValue(nextActiveData?.Subject, nextActiveData?.Chapter, nextActiveData?.Topic, subjectData)
            setDifficulty(nextActiveData?.difficulty)
            setActivePaginationPage(currentTreeIndex + 2)
            setHeaderValue(nextActiveData?.text)
            setDescriptionValue(nextActiveData?.description)
            if (nextActiveData?.PageDataJson) {
                const jsonData = JSON.parse(nextActiveData?.PageDataJson)
                setQuestionType(jsonData?.questionType)
            }
            setRadioType(autoSelectOption(nextActiveData?.mcqData ? JSON.parse(nextActiveData?.mcqData) : defaultMcqOption))
            setmcqData(nextActiveData?.mcqData ? JSON.parse(nextActiveData?.mcqData) : defaultMcqOption)
            setCurrentMcqdata(nextActiveData?.mcqData ? JSON.parse(nextActiveData?.mcqData) : defaultMcqOption)
        }
        // }
    }

    function checkChangedValues(arr1: any, arr2: any) {
        // if (isModified) {
        //     const array2 = JSON.parse(JSON.stringify(arr2))
        //     array2.forEach(function (item: any) {
        //         if (item.mcqData) {
        //             item.mcqData.forEach(function (mcq: any) {
        //                 delete mcq.selected;
        //             });
        //         }
        //     });
        //     const array1 = JSON.parse(JSON.stringify(arr1))
        //     array1.forEach(function (item: any) {
        //         if (item.mcqData) {
        //             item.mcqData.forEach(function (mcq: any) {
        //                 delete mcq.selected;
        //             });
        //         }
        //     });
        //     const str1 = JSON.stringify(array1.sort());
        //     const str2 = JSON.stringify(array2.sort());
        //     if (str1 !== str2) {
        //         const currentIndex = treeData.findIndex((item: any) => item.id === activeId)
        //         if (currentIndex >= 0) {
        //             if (treeData[currentIndex].text !== "New page") {
        //                 dispatch(setUnsavedChangesData(treeData[currentIndex]))
        //             }
        //         }
        //     }
        //     return str1 === str2;
        // } else {
        //     return true
        // }
        return true

    }
    const dataa = {
        data: { fileType: '' },
        droppable: true,
        editable: false,
        hierarchyId: 212,
        id: 212,
        Media: [],
        onFocus: false,
        pageType: "",
        parent: 0,
        text: "defaultRootHierarchy"
    }
    const showDisabled = () => {
        const data = currentMcqdata?.filter((item: any) => item.selected)
        return data.length
    }


    const regularSubmitClick = () => {
        // const check = currentMcqdata?.filter((item: any) => item.selected)
        // const correctAnswer = currentMcqdata?.findIndex((item: any) => item.isCorrect && item.selected)
        const currentindex = treeData.findIndex((item: any) => item.id === activeId)
        regularAnswerArray[currentindex].selectedAnswer = regularSelectedAns
        setRegularAnswerArray([...regularAnswerArray])
        if (courseType !== "Test") {
            if (activeCreateEditData.correctAnswer === regularSelectedAns) {
                setCorrectAnitmation(true)
                // setDisableSubmit(true)
                setTimeout(() => {
                    setCorrectAnitmation(false)
                }, 2000);
            } else {
                setFailedAnitmation(true)
                setTimeout(() => {
                    setFailedAnitmation(false)
                }, 2000);
            }
        }
    }
    const showExplanation = () => {
        setHideExplanation(!hideExplanation)
    }
    const handleSwipe = (val: any) => {
        setRegularSelectedAns(regularAnswerArray[val].selectedAnswer)

        setActivePaginationPage(val + 1)
        let count = 0;
        let result = null;
        for (const item of treeData) {
            if (item.data.fileType !== "") {
                count++;
                if (count === val + 1) {
                    result = item;
                    break;
                }
            }
        }

        if (result) {
            setActiveId(result.id)

            setActiveCreateEditData(result)
            setHeaderValue(result?.text)
            setChapterName(result?.hierarchyName)
            setDescriptionValue(result?.description)
            if (result?.PageDataJson) {
                const jsonData = JSON.parse(result?.PageDataJson)
                setQuestionType(jsonData?.questionType)
            }
            fillTopicValue(result?.Subject, result?.Chapter, result?.Topic, subjectData)
            setDifficulty(result?.difficulty)
            setRadioType(autoSelectOption(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption))
            setmcqData(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
            setCurrentMcqdata(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
            setpreviewimage(result?.Media)
        }
    }

    const submittedPagination = (item: any) => {
        if (item.type === "previous" || item.type === "next") {
            return ""
        } else {
            if (submitAnswer[item.page - 1]?.answer) {
                return "submittedPage"
            }
        }
    }

    const setUnsavedChanges = () => {
        const currentIndex = treeData.findIndex((item: any) => item.id === activeId)
        if (currentIndex >= 0) {
            treeData[currentIndex].text = headerValue
            treeData[currentIndex].text = headerValue
            // setStorageFunction("unsavedChanges", JSON.stringify(treeData[currentIndex]))
            dispatch(setUnsavedChangesData(treeData[currentIndex]))
        }
    }

    const confirmChange = () => {
        setAlertModal(false)
        setUnsavedChanges()
        if (changedModalName === "addFile") {
            setAlertData({})
            addFileFunction(modalId, changedModalData, addPageType)
            setInitialTreeData([...treeData])
            setAlertModal(false)
        } else if (changedModalName === 'dragAndDrop') {
            handleDropFunction(modalId, changedModalData)
            setInitialTreeData([...treeData])
            setAlertModal(false)
        } else if (changedModalName === "pageClicked") {
            setActivePageData(modalId, changedModalData)
            setInitialTreeData([...treeData])
            setAlertModal(false)
        } else if (changedModalName === "paginationClicked") {
            paginationClick(modalId, changedModalData)
            setInitialTreeData([...treeData])
            setAlertModal(false)
        } else if (changedModalName === "modifyClick") {

            setIsModified(!isModified)
            setTreeData([...initialTreeData])
            setAlertModal(false)
        } else if (changedModalName === "breadcrumbClick") {
            setAlertModal(false)
        } else if (changedModalName === "backClick") {
            // backClickFunction()
            setAlertModal(false)
        }
    }
    const closeAlert = () => {
        setAlertModal(false)
    }


    const preFilledChange = (e: any) => {
        if (e.target.checked) {
            mcqData[0] = mcqData[0].replace(/(<p>)(.*?)(<\/p>)/, '$1a $2$3')
            mcqData[1] = mcqData[1].replace(/(<p>)(.*?)(<\/p>)/, '$1b $2$3')
            mcqData[2] = mcqData[2].replace(/(<p>)(.*?)(<\/p>)/, '$1c $2$3')
            mcqData[3] = mcqData[3].replace(/(<p>)(.*?)(<\/p>)/, '$1d $2$3')
            setmcqData(preFilledData)
            setRadioType("text")
        } else {
            const data2 = mcqData.map(str => {
                let textOnly = str.replace(/<[^>]*>/g, "");
                let words = textOnly.split(" ").slice(1).join(" ");
                return str.replace(/<[^>]*>/g, "") === textOnly ? str.replace(textOnly, words) : words;
            });
            setRadioType("")
            setmcqData(data2)
        }
    }

    const addTextOption = (text: any, val: string) => {
        let result = text ? text.replace(/(<p>)(.*?)(<\/p>)/, `$1${val} $2$3`) : `<p>${val}</p>`;
        return result

    }
    const radioChange = (type: any, e: any) => {
        if (type === "text" && radioType !== "text") {
            if (radioType === "number") {
                const result = mcqData.map(str => {
                    let textOnly = str.replace(/<[^>]*>/g, "");
                    let words = textOnly.split(" ").slice(1).join(" ");
                    return str.replace(/<[^>]*>/g, "") === textOnly ? str.replace(textOnly, words) : words;
                });
                result[0] = addTextOption(result[0], "a)")
                result[1] = addTextOption(result[1], "b)")
                result[2] = addTextOption(result[2], "c)")
                result[3] = addTextOption(result[3], "d)")
                setmcqData(result)
            } else {
                mcqData[0] = addTextOption(mcqData[0], "a)")
                mcqData[1] = addTextOption(mcqData[1], "b)")
                mcqData[2] = addTextOption(mcqData[2], "c)")
                mcqData[3] = addTextOption(mcqData[3], "d)")
                setmcqData(mcqData)
            }
        } else if (type === "number" && radioType !== "number") {
            if (radioType === "text") {
                const result = mcqData.map(str => {
                    let textOnly = str.replace(/<[^>]*>/g, "");
                    let words = textOnly.split(" ").slice(1).join(" ");
                    return str.replace(/<[^>]*>/g, "") === textOnly ? str.replace(textOnly, words) : words;
                });
                result[0] = addTextOption(result[0], "1)")
                result[1] = addTextOption(result[1], "2)")
                result[2] = addTextOption(result[2], "3)")
                result[3] = addTextOption(result[3], "4)")
                setmcqData(result)
            }
            else {
                mcqData[0] = addTextOption(mcqData[0], "1)")
                mcqData[1] = addTextOption(mcqData[1], "2)")
                mcqData[2] = addTextOption(mcqData[2], "3)")
                mcqData[3] = addTextOption(mcqData[3], "4)")
                setmcqData(mcqData)
            }
        }
        setRadioType(type)
    }


    // useEffect(() => {
    //     if (activePaginationPage === 0) {
    //         
    //         setActivePaginationPage(1)
    //     }
    // }, [activePaginationPage === 0])

    const checkErrorOnValidation = () => {
        if ((hasSubmitted && (submissionError.headerValue && !previewimage.length)) || (hasSubmitted && submissionError.checkOption) || (hasSubmitted && submissionError.checkIsCorrect)) {
            return true
        } else {
            return false
        }
    }


    const showMore = () => {
        setShowHideDescription(!showHideDescription)
    }

    useEffect(() => {
        if (submitAnswer.length) {
            if (submitAnswer[activePaginationPage - 1]?.selectedAns >= 0) {
                currentMcqdata?.map((item: any, index: number) => {
                    if (index === submitAnswer[activePaginationPage - 1].selectedAns) {
                        item.selected = true
                    } else {
                        item.selected = false
                    }
                    return item
                })
                setCurrentMcqdata(currentMcqdata)
            }
        }
    }, [activePaginationPage])


    const increment = () => {
        setCount((prevCount: number) => {
            if (prevCount < 50) {
                return prevCount + 1; // Return the new state value
            }
            return prevCount;
        });
    };
    const decrement = () => {
        setCount((prevCount: number) => {
            if (prevCount > 16) {
                return prevCount - 1; // Return the new state value
            }
            return prevCount;
        });
    };


    const startIncrementing = () => {
        if (!intervalId && count < 50) {
            const id = setInterval(increment, 200); // Adjust interval as needed
            setIntervalId(id);
        }
    };
    const startDecrement = () => {
        if (!intervalId && count > 16) {
            const id = setInterval(decrement, 200); // Adjust interval as needed
            setIntervalId(id);
        }
    };
    const decreaseFont = () => {
        if (count > 16) {
            setCount(count - 1)
        }
    }
    const increaseFont = () => {
        if (count < 50) {
            setCount(count + 1)
        }
    }

    const stopIncrementing = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };
    const showExplanationBtn = () => {
        return submitAnswer[activePaginationPage - 1]?.answer
    }

    const downloadFileClick = (elem: any) => {
        if (typeof elem === "string") {
            window.open(elem)
        } else {
            const link = document.createElement('a')
            link.download = elem.name
            link.href = URL.createObjectURL(elem)
            link.click()
        }

    }

    const pasteClick = async () => {
        const response2 = await handleButtonClick()
        if (response2) {
            fetch(response2)
                .then(response => response.blob())
                .then(blob => {
                    const file = new File([blob], (new Date().getTime()).toString(), {
                        lastModified: new Date().getTime(),
                        type: blob.type,
                    });
                    draggerChange(file)
                })
                .catch(error => {
                    console.error('Error fetching image:', error);
                });
        } else {
            openNotification("error", "There is no copied image")
        }
    }



    const checkArrayLength = () => {
        const arrLength = treeData.filter((item: any) => item.data.fileType)
        return arrLength.length
    }
    const uploadDriveUrl = (e: any) => {
        const text = e.target.value
        if (text.length > 0) {
            if (text.includes("drive.google.com")) {
                const rest = text.substring(0, text.lastIndexOf("/") + 1);
                setUrlFalse(false)
                setDriveURL(`${rest}preview`)
            }
            else {
                setDriveURL("")
                setUrlFalse(true)
            }
        } else {
            setUrlFalse(false)
        }
    }
    const fillValue = (text?: string) => {
        const value = text ? text : formatValue
        if (value) {
            if (getAnswerFromScreen(value, headerValue).answers.length) {
                setAutofillAnswers(getAnswerFromScreen(value, headerValue))
            }
            else {
                setAutofillAnswers({})
            }
        } else {
            setAutofillAnswers({})
        }
    }
    const autofillBtnClick = () => {
        fillValue()
    }

    const changeDifficult = (e: any) => {
        setDifficulty(e)
    }
    const PopoverContent = () => {

        const onBlurInput = (e: any) => {
            setFormatValue(() => e.target.value)
            if (e.relatedTarget.className === "common_button autofillBtn ") {
                fillValue(e.target.value)
            }
        }
        const btnClick = () => {
            fillValue()
        }

        const fillAnswer = () => {
            if (Object.keys(autofillAnswers).length) {
                setmcqData(autofillAnswers?.answers)
                setRadioType(autoSelectOption(autofillAnswers?.answers))
                setHeaderValue(autofillAnswers?.question)
            }
            setOpen(false)
        }

        return (
            <div className="answerPopover">
                <div className="autofillInputContainer">
                    <p>Enter Format</p>
                    <TextField defaultValue={formatValue} onBlur={onBlurInput} />
                    <Button id="autofillBtn" className="autofillBtn" onClick={btnClick} btnText="Save" />
                </div>
                {Object.keys(autofillAnswers).length ?
                    autofillAnswers.answers.length ?
                        <div className="resultQuestion">
                            <div className="detected_question">
                                <p className="dq_heaing">Detected Questions are</p>
                                <p dangerouslySetInnerHTML={{ __html: autofillAnswers.question }}></p>
                            </div>
                            <div className="detected_question">
                                <p className="dq_heaing">Detected Answers are</p>
                                <ul>
                                    {autofillAnswers?.answers.map((item: any) => {
                                        return (
                                            <li key={item} dangerouslySetInnerHTML={{ __html: item }}></li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <Button className="autofillBtn" btnText="AutofIll Answer" onClick={fillAnswer} />
                        </div> : <div className="resultQuestion">
                            <p className="dq_heaing">No Answer have been detected</p>
                        </div> :
                    <div className="resultQuestion">
                        <p className="dq_heaing">No Answer have been detected</p>
                    </div>
                }
            </div>
        )
    }

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };
    const [page, setPage] = useState<number>(0)
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        dispatch(GetQuestionBank({
            "startRow": newPage * 30,
        }))
    };
    const [popOpen, setPopOpen] = useState<boolean>(Boolean(anchorEl))

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setPopOpen(Boolean(event.currentTarget))
    };

    const handleClosePop = () => {
        setAnchorEl(null);
        setPopOpen(false)
    };

    const id = popOpen ? 'simple-popover' : undefined

    const [selectedSubject, setSelectedSubject] = useState<any[]>([])
    const [questionChapterData, setQuestionChapterData] = useState<any[]>([])
    const [questionSelectedChapter, setQuestionSelectedChapter] = useState<any[]>([])
    const [questointopicData, setQuestionTopicData] = useState<any[]>([])
    const [selectedTopic, setSelectedTopic] = useState<any[]>([])
    const [selectedDifficulty, setSelectedDifficulty] = useState<any[]>([])


    const subChange = (e: any, val: any) => {
        setSelectedSubject([...val])
        const chapter = val.map((item: any) => item.data).flat()
        setQuestionChapterData([...chapter])
        if (questionSelectedChapter.length) {
            const chapterNames = questionSelectedChapter.map((item: any) => item.name)
            const chapterValue = chapter.filter((item: any) => chapterNames.includes(item.name))
            setQuestionSelectedChapter([...chapterValue])
            const topicvalue = chapterValue.map((item: any) => item.data).flat()
            setQuestionTopicData([...topicvalue])
            if (selectedTopic.length) {
                const topicNames = topicvalue.map((item: any) => item.name)
                const selectedTopicValue = topicvalue.filter((item: any) => topicNames.includes(item.name))
                setSelectedTopic([...selectedTopicValue])
            }
        }
    }

    const chapterChange = (e: any, val: any) => {
        setQuestionSelectedChapter([...val])
        const topic = val.map((item: any) => item.data).flat()
        if (selectedTopic.length) {
            const topicNames = selectedTopic.map((item: any) => item.name)
            const data = topic.filter((item: any) => topicNames.includes(item.name))
            setSelectedTopic([...data])
        }
        setQuestionTopicData([...topic])
    }
    const topicChange = (e: any, val: any) => {
        setSelectedTopic([...val])
    }
    const difficultyChange = (e: any, val: any) => {
        setSelectedDifficulty([...val])
    }

    const filterClick = () => {
        const subjectIds = selectedSubject.map((val: any) => val.id)
        const chapterIds = questionSelectedChapter.map((val: any) => val.id)
        const topicIds = selectedTopic.map((val: any) => val.id)
        const difficultyIds = selectedDifficulty.map((val: any) => val.id)

        let payload: any = {
            startRow: 0,
        }
        if (subjectIds.length > 0) {
            payload.subjectLst = subjectIds;
        }

        if (chapterIds.length > 0) {
            payload.chapterLst = chapterIds;
        }

        if (topicIds.length > 0) {
            payload.topicLst = topicIds;
        }
        if (difficultyIds.length > 0) {
            payload.difficultyLst = difficultyIds;
        }
        dispatch(GetQuestionBank(payload))
        setIsFiltered(true)
        setPopOpen(false)
    }

    const answerChange = (val: string) => {
        setQuestionType(val)
    }

    const checkString = (val: any) => {
        if (val) {
            return val.toString()
        } else {
            return val
        }
    }

    const switchSelect = (index: string) => {
        if (questionType === "single") {
            if (activeCreateEditData?.correctAnswer !== "" && activeCreateEditData?.correctAnswer !== null) {
                return (checkString(activeCreateEditData?.correctAnswer)) === index
            } else {
                return false
            }
        } else if (questionType === "multiple") {
            return activeCreateEditData.multiAnswer.includes(index)
        } else if (questionType === "text") {
            if (index === "from") {
                return activeCreateEditData ? activeCreateEditData.correctAnswer : ""
            } else if (index === "rangeTo") {
                return activeCreateEditData ? activeCreateEditData?.rangeTo : ""
            }
        }
    }

    const optionOnchange = (e: any, name: string) => {
        const inputValue = e.target.value;
        const regex = /^[+-]?\d*\.?\d*$/;

        if (regex.test(inputValue) || inputValue === '') {
            const currentIndex = treeData.findIndex((item: any) => item.id === activeId)
            if (name === "from") {
                treeData[currentIndex].correctAnswer = inputValue
                activeCreateEditData.correctAnswer = inputValue

                setActiveCreateEditData(activeCreateEditData)
                setTextAnswerValue(inputValue)
                setTreeData([...treeData])
            } else if (name === "to") {
                treeData[currentIndex].rangeTo = inputValue
                activeCreateEditData.rangeTo = inputValue
                setActiveCreateEditData(activeCreateEditData)
                setRangeTo(inputValue)
                setTreeData([...treeData])
            }

        }
    }

    const textAnswerChange = (e: any) => {
        const inputValue = e.target.value;
        const regex = /^[+-]?\d*\.?\d*$/;
        if (regex.test(inputValue) || inputValue === '') {
            const currentIndex = treeData.findIndex((item: any) => item.id === activeId)
            treeData[currentIndex].selectedAnswer = inputValue
            setRegularSelectedAns(inputValue)
            setTreeData([...treeData])
        }
    }
    const textAnsValue = () => {
        if (regularSelectedAns) {
            return regularSelectedAns
        } else {
            return ""
        }
    }

    const fillAnswersValue = (name: string) => {
        if (name === "multiple") {
            if (regularSelectedAns) {
                if (typeof regularSelectedAns === "string") {
                    return regularSelectedAns.split(",")
                } else {
                    return regularSelectedAns
                }
            } else {
                return []
            }
        }

    }

    const autofillClick = () => {
        setOpen(true)
    }

    const courseSelect = (e: any, data: any) => {
        const payloadData: any = {
            courseId: courseId,
            answer: answers(),
            // files: resData.result,
            heading: headerValue,
            description: descriptionValue,
            mcqData: JSON.stringify(mcqData),
            pageOrder: treeData.length,
            "subject": subjectId ? subjectId : null,
            "chapter": chapterId ? chapterId : null,
            "topic": topicId ? topicId : null,
            difficulty: difficulty
        }
        setSelectedCourse(data)
    }

    const showOptions = () => {
        if (currentMcqdata && currentMcqdata.length) {
            const arrayLen = currentMcqdata.filter((item: any) => !item)
            if (arrayLen.length) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const dltClose = () => {
        setDltOpen(false)
        // setAlertData({})
    }

    const deleteClick = (e: any, id: any, node: any, index: number) => {
        e.stopPropagation()
        setAnchorElDlt(e.currentTarget)
        setAlertData({
            id,
            node,
            index: index + 1
        })
        setDltOpen(true)
    }
    const DeleteContent = () => {
        return (
            <ul className="dltComponent">
                <li >
                    <MuiTooltip title={`Delete page ${alertData.index}`}>
                        <IconButton onClick={onDelete}>
                            <DeleteOutlined sx={{ color: "red" }} fontSize="small" />
                        </IconButton>
                    </MuiTooltip>
                </li>
                <li >
                    <MuiTooltip title="Add page above">
                        <IconButton onClick={clicktoAddBelow}>
                            <NoteAddOutlined fontSize="small" />
                        </IconButton>
                    </MuiTooltip>
                </li>

            </ul>
        )
    }



    const extractDimensionsAndUpdateHTML = async () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(headerValue, "text/html");
        const imgTags = doc.querySelectorAll("img");

        const dimensionPromises = Array.from(imgTags).map((imgTag) => {
            return new Promise<void>((resolve) => {
                const src = imgTag.getAttribute("src");
                if (src?.startsWith("data:image")) {
                    const img = new window.Image();

                    img.onload = () => {
                        // debugger
                        imgTag.setAttribute("width", (img.width + ((count - 16) * 10)).toString());
                        // imgTag.setAttribute("height", img.height.toString());
                        resolve();
                    };
                    img.onerror = () => resolve(); // Resolve even if the image fails to load
                    img.src = src;
                } else {
                    resolve();
                }
            });
        });

        await Promise.all(dimensionPromises);
        setUpdatedHtml(doc.body.innerHTML);
        return doc.body.innerHTML
    };
    useEffect(() => {
        extractDimensionsAndUpdateHTML()
    }, [headerValue, count]);


    const selectQuestion = (array: any[]) => {
        setSelectedQuestionBank([...array])
    }
    const selectAllChnage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedQuestionBank([...treeData])
        } else {
            setSelectedQuestionBank([])
        }

    }

    const isAllSelected = () => {
        return selectedQuestionBank.length === treeData.length
    }

    return (
        loaderStatus ? <SuspenseLoader /> :
            <>
                <Popover
                    id={alertData?.id}
                    open={dltOpen}
                    anchorEl={anchorElDlt}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={dltClose}
                >
                    <DeleteContent />
                </Popover>

                {params.type === "questions" ?
                    <Popover
                        id={id}
                        open={popOpen}
                        anchorEl={anchorEl}
                        onClose={handleClosePop}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <QuestionBankFilter {...{
                            subjectData, selectedSubject, questionChapterData, questionSelectedChapter,
                            questointopicData, selectedTopic, selectedDifficulty, subChange, chapterChange,
                            topicChange, difficultyChange, filterClick
                        }} />
                    </Popover> : ""}


                {params.type === "questions" ? allCoursedata[4] ? <p style={{ marginBottom: "10px", fontSize: "18px", fontWeight: 600 }}> {allCoursedata[4]} </p> : "" : ""}

                <div style={{ padding: "6px 20px 6px 0px" }} className={`breadcrumb_header creatorBreadcrumb ${params.type === "questions" ? "question_bank_header" : ""} `}>
                    <Box  sx={{ display: "flex", gap: "10px" }}>
                        {params.type === "questions" ?
                            <button className={`filter_btn ${selectedSubject.length ? "filter_edit " : ""}`} aria-describedby={id} onClick={handleClick}>
                                <FilterSvg color={selectedSubject.length ? "#000000" : ""} />
                            </button> : ""}
                        {params.type === "questions" ?
                            <div className="questionbank_header">
                                <Autocomplete
                                    id="checkboxes-tags-demo"
                                    options={allCourse}
                                    value={selectedCourse}
                                    onChange={courseSelect}
                                    size="small"
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => (option?.courseName ? option.courseName : '')}
                                    renderOption={(props, option, { selected }) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <li key={key} {...optionProps}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.courseName}
                                            </li>
                                        );
                                    }}
                                    style={{ width: "100%", maxWidth: "300px" }}
                                    renderInput={(params) => (
                                        <TextFields {...params} label="Course" placeholder="Course" />
                                    )}
                                />
                                <Button disabled={(Object.keys(selectedCourse).length && selectedQuestionBank.length) ? false : true} className={`filter_btn ${(Object.keys(selectedCourse).length && selectedQuestionBank.length) ? "" : "disabledQuestion"}`} onClick={makeQuestions} btnText="Add Question" />
                            </div>
                            : ""}
                    </Box>
                    <div className="csActionBtn">
                        {checkArrayLength() ? <PDFWritter treeData={treeData} /> : ""}
                        <Button btnText={
                            <>
                                <Icon
                                    iconName="Edit"
                                    className="homeworkIcons"
                                    title={isModified ? "View" : "Edit"}
                                />
                                <span>{isModified ? "View" : "Edit"}</span>
                            </>
                        } onClick={modifyClick} className={`${isModified ? "buttonWithicon activeEdit " : " buttonWithicon enableEdit"}`} disabled={false} />
                    </div>

                </div>
                <div className={`homeworkElementsContainer creatorStudioParentBody`}>
                    <div className="creatorStudio">
                        <Modal
                            open={!cameraDialouge}
                            onClose={(event: any, reason: any) => handleClose(event, reason)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} className="camera_modal">
                                {cameraOpen &&
                                    <div className="camera_parent mobile_camera_parent">
                                        <Icon iconName="Cancel" onClick={closeModal}></Icon>
                                        <Camera
                                            onTakePhoto={(dataUri: any) => { handleTakePhoto(dataUri) }}
                                        />
                                    </div>
                                }
                            </Box>
                        </Modal>

                        <CommonModal modalOpen={alertModal} handleClose={closeAlert}>
                            <>
                                <div className="courseModalBody">
                                    <AnnouncementOutlinedIcon />
                                    <p>There are some unsaved changes. Do you want to continue without saving?</p>
                                </div>
                                <div className="footerBtn">
                                    <PrimaryButton
                                        onClick={confirmChange}
                                        text="Yes"
                                    />
                                    <DefaultButton
                                        onClick={closeAlert}
                                        text="No"
                                    />
                                </div>
                            </>
                        </CommonModal>
                        <div className="preview_modal">
                            <Modal
                                open={!previewDilouge}
                                onClose={(event: any, reason: any) => handleClose(event, reason)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style} className="camera_modal preview_camera_modal">
                                    <div className="camera_parent">
                                        <Icon iconName="Cancel" onClick={closeModal}></Icon>
                                        <ImageCropComponent images={imageBase64} initialPreview={initialPreview} backFunction={callBackFunction} />
                                        <div className="modalFooterBtn">
                                            <Button btnText="OK" onClick={croppedImage} className="okBtn" disabled={false} />
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                        <div className="courseDiscription">
                            <div>
                                <div className="courseName">
                                    {params.type === "questions" ? "" : allCoursedata[4] ? allCoursedata[4].toUpperCase() : ""}
                                    {params.type === "questions" ? "" :
                                        <>
                                            ({allCoursedata[5]}) <span className="moreBtn" onClick={showMore}>{showHideDescription ? "less..." : "more..."}</span>
                                        </>
                                    }

                                </div>
                                <div className={`hiddenDescription ${showHideDescription ? "showDescription" : ""} `}>
                                    <p className="courseTypeName"> {`(${courseType})`} </p>
                                    <div className="courseDesc">
                                        {allCoursedata[6]}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="cs_filters">
                            {isModified ?
                                <>
                                    <SubjectSelection {...{
                                        subjectId, setSubjectId, topicId, setTopicId, chapterId, setChapterId, subjectValue,
                                        setSubjectValue, chapterValue, setChapterValue, topicValue, setTopicValue, chapterData, setChapterData,
                                        topicData, setTopicData, difficulty, changeDifficult
                                    }} />
                                </> : ""
                            }
                        </div>
                        {selectedSubject.length && isFiltered ?
                            <FormGroup sx={{ width: "fit-content" }}>
                                <FormControlLabel control={<Checkbox className="qb_checkbox" onChange={selectAllChnage} checked={isAllSelected()} />} label="Select All" />
                            </FormGroup> : ""}
                        <div className="creatorStudioPageBody">
                            <div className="creatorStudioSidebar">

                                <div className="collapsible_sidebar">
                                    <div className={`creatorStudioSidebars ${collapsed}_parent`}>
                                        {(loaderStatus && treeData.length <= 1) ?
                                            <div className="loadingSidebar">
                                                <Loader />
                                            </div>
                                            : ""}

                                        <QuestionSidebar {...{ collapsed, setCollapsed }}>
                                            <ThemeProvider theme={Theme}>
                                                <CssBaseline />
                                                <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                                                    <div className={styles.app}>
                                                        <Tree
                                                            tree={treeData}
                                                            rootId={0}
                                                            initialOpen={openedFolder}
                                                            render={(node, { depth, isOpen, onToggle }) => {
                                                                const index = treeData.findIndex((item: any) => item.id === node.id);

                                                                return (
                                                                    <CustomNode {...{
                                                                        node, depth, isOpen, onChange, onToggle,
                                                                        onFocusFunc, onDelete, saveClick, onBlur, isFocused, addFile,
                                                                        clickOnPage, checkActive, isModified, showSubmitted, index, deleteClick, selectQuestion, selectedQuestionBank, isFiltered, selectedSubject
                                                                    }} />
                                                                )
                                                            }}
                                                            dragPreviewRender={(monitorProps) =>
                                                                <CustomDragPreview monitorProps={monitorProps} />
                                                            }
                                                            onDrop={handleDrop}
                                                            // canDrag={isModified ? () => true : () => false}
                                                            canDrag={() => false}
                                                            classes={{
                                                                root: styles.treeRoot,
                                                                draggingSource: styles.draggingSource,
                                                                placeholder: styles.placeholderContainer
                                                            }}
                                                            sort={false}
                                                            insertDroppableFirst={false}
                                                            canDrop={(tree, { dragSource, dropTargetId }) => {
                                                                if (dragSource?.parent === dropTargetId) {
                                                                    return true;
                                                                }
                                                            }}
                                                            dropTargetOffset={5}
                                                            placeholderRender={(node, { depth }) => (
                                                                <Placeholder node={node} depth={depth} />
                                                            )}
                                                        />
                                                    </div>
                                                </DndProvider>
                                            </ThemeProvider>
                                        </QuestionSidebar>

                                        {(isModified) ? <div className="outerActionbtn" >
                                            <Tooltip title={isChanged ? "Please save the changes" : "Add Page"}>
                                                <Icon
                                                    iconName="Page"
                                                    title="Add Page"
                                                    onClick={() => addFile(0, fileData, "rootPage")}
                                                ></Icon>
                                            </Tooltip>

                                        </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                            {/* {params.type === "questions" && (questionBankData?.total ?
                                <TablePagination
                                    component="div"
                                    count={questionBankData.total}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={30}
                                    rowsPerPageOptions={[]}
                                /> : "")} */}

                            <div className="questions-outer-cnt">

                                <div className="creatorStudioQuestionSection">
                                    {
                                        (treeData.findIndex((item: any) => item.data.fileType) > -1 && isModified) ?
                                            <div className="creatorStudioPageSection">
                                                <div className="textEditorHeader">
                                                    <p className="chapterName">{chapterName === "defaultRootHierarchy" ? "" : chapterName}</p>
                                                    <TextEditor labelText="Add Question here" onChange={(e: any, val: any) => descriptionChange(e, "header", val)} {...{ editorFocus, editorOnFocus }} type="header" value={headerValue} />
                                                    <TextEditor {...{ editorFocus, editorOnFocus }} labelText="Add explanation here" onChange={(e: any, val: any) => descriptionChange(e, "description", val)} type="description" value={descriptionValue} />
                                                </div>
                                                <div className="swipe_content">
                                                    <div className="imageWithText">
                                                        <div className="mediaWithImages">
                                                            <div className="mediaAndDetails">
                                                                <div className="media">
                                                                    <Popclick handleOpenChange={handleOpenChange} open={open} content={<PopoverContent />}>
                                                                        <Button onClick={autofillBtnClick} btnText="Autofill answer" />
                                                                    </Popclick>
                                                                    <div className="mediaAndName">
                                                                        <Dragger onChange={(e: any) => draggerChange(e.file.originFileObj)}>
                                                                            <p className="ant-upload-drag-icon">
                                                                                <InboxOutlined />
                                                                            </p>
                                                                            <p className="ant-upload-hint">
                                                                                Click or drag file to this area to upload, Support for a
                                                                                single file upload.
                                                                            </p>
                                                                            <p className="ant-upload-hint-mobile">
                                                                                <span> Upload</span> / <Icon
                                                                                    iconName="Camera"
                                                                                /> <span> Camera </span>
                                                                            </p>
                                                                        </Dragger>
                                                                    </div>
                                                                    <div className="pasteImageSection">


                                                                        <Button className={`openCameraBtn`}
                                                                            onClick={openCamera}
                                                                            btnText={<>
                                                                                <Icon
                                                                                    iconName="Camera"
                                                                                />
                                                                                <span>Picture</span>
                                                                            </>} />
                                                                        <Button className="pasteImageButton"
                                                                            onClick={pasteClick}
                                                                            btnText={<>
                                                                                <FaRegPaste />
                                                                                <span style={{ marginLeft: 5 }}>Paste</span>
                                                                            </>} />
                                                                    </div>
                                                                    {/* <div className="uploadDriveUrl">
                                                                <TextField
                                                                    label="Drive URL"
                                                                    placeholder="Enter Drive video URL"
                                                                    errorMessage={urlFalse ? "Please Enter valid drive url" : ""}
                                                                    onChange={uploadDriveUrl}
                                                                ></TextField>
                                                                {driveURL ? <iframe src={driveURL} title="Drive" width="100%" height="100%" allow="autoplay"></iframe> : ""}
                                                            </div> */}
                                                                </div>
                                                                <div className="preview_body">

                                                                    {previewimage && (previewimage.length ?
                                                                        previewimage.map((item: string, index: number) => {
                                                                            return (

                                                                                <div className="preview_img" key={index}>
                                                                                    {isImageUrl(item) ?
                                                                                        <Image width="100%" src={item} /> : <Button className="downloadBtn" onClick={() => downloadFileClick(item)} btnText={<><InsertDriveFileOutlinedIcon />
                                                                                            Download File</>} />}
                                                                                    <div className="image_action">
                                                                                        <Icon iconName="Edit" onClick={() => editImageCrop(item, index)}></Icon>
                                                                                        <Icon iconName="Delete" onClick={() => deleteCameraImg(item, index)}></Icon>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : "")

                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="textEditorHeader">
                                                            {jeeAvailable === "jee_true" ?
                                                                <div className="questionFields">
                                                                    <p>Select answer type</p>

                                                                    <div className="checkbox_toggle">
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            name="radio-buttons-group"
                                                                            // defaultValue={questionType}
                                                                            value={questionType}

                                                                        >
                                                                            <div className="mcqCheckbox">
                                                                                <FormControlLabel
                                                                                    value="single"
                                                                                    control={<Radio checked={questionType === "single"} sx={{
                                                                                        color: "#306eb3",
                                                                                        '&.Mui-checked': {
                                                                                            color: "#306eb3",
                                                                                        },
                                                                                    }} />}
                                                                                    onChange={() => answerChange("single")}
                                                                                    label="Single"
                                                                                />
                                                                                <FormControlLabel
                                                                                    value="multiple"
                                                                                    control={<Radio checked={questionType === "multiple"} sx={{
                                                                                        color: "#306eb3",
                                                                                        '&.Mui-checked': {
                                                                                            color: "#306eb3",
                                                                                        },
                                                                                    }} />}
                                                                                    onChange={() => answerChange("multiple")}
                                                                                    label="Multiple"
                                                                                />
                                                                                <FormControlLabel
                                                                                    value="text"
                                                                                    control={<Radio checked={questionType === "text"} sx={{
                                                                                        color: "#306eb3",
                                                                                        '&.Mui-checked': {
                                                                                            color: "#306eb3",
                                                                                        },
                                                                                    }} />}
                                                                                    onChange={() => answerChange("text")}
                                                                                    label="Numerical"
                                                                                />
                                                                            </div>
                                                                        </RadioGroup>
                                                                    </div>
                                                                </div>

                                                                : ""}

                                                            <div className="questionFields">
                                                                {questionType === "text" ?
                                                                    <div>
                                                                        <p style={{ marginBottom: 10 }}>Please enter a valid range for the answer.</p>
                                                                        <div className="numerical_ans">
                                                                            <input type="number" placeholder="Enter minimum value" className="cs_input_range" onChange={(e: any) => optionOnchange(e, "from")} value={switchSelect("from")} />
                                                                            <p>To</p>
                                                                            <input type="number" placeholder="Enter maximum value" className="cs_input_range" onChange={(e: any) => optionOnchange(e, "to")} value={switchSelect("rangeTo")} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <p>Enter option (If the option is correct, please toggle the button as "Correct")</p>
                                                                        <div className="checkbox_toggle">
                                                                            <label htmlFor="prefill">Select box for fill option values.</label>
                                                                            <input id="prefill" type="checkbox" checked={autoSelectOption(mcqData) !== ""} onChange={preFilledChange} />
                                                                        </div>
                                                                        <div className="checkbox_toggle">
                                                                            <input type="radio" checked={(radioType === "text" || autoSelectOption(mcqData) === "text")} onChange={(e: React.ChangeEvent<HTMLInputElement>) => radioChange("text", e)} id="text" name="option" value="text" />
                                                                            <label htmlFor="text">(a,b,c,d)</label>
                                                                            <input type="radio" checked={(radioType === "number" || autoSelectOption(mcqData) === "number")} onChange={(e: React.ChangeEvent<HTMLInputElement>) => radioChange("number", e)} id="numver" name="option" value="number" />
                                                                            <label htmlFor="numver">(1,2,3,4)</label>
                                                                        </div>
                                                                        {mcqData.length ? mcqData?.map((item: any, index: number) => {
                                                                            return (
                                                                                <div className="mcqFields" key={index}>
                                                                                    <TextEditor key={item} {...{ editorFocus, editorOnFocus }} className="textEditDiv" onChange={(e: any, val: any) => optionFieldChange(e, index.toString(), val)} type={index.toString()} value={item} />
                                                                                    <Switch
                                                                                        checkedChildren={<CheckOutlined />}
                                                                                        unCheckedChildren={<CloseOutlined />}
                                                                                        onChange={() => onToggleOneChange(index.toString())}
                                                                                        checked={switchSelect(index.toString())}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }) : ""}
                                                                    </>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            treeData.findIndex((item: any) => item.data.fileType) > -1 ?
                                                <div className={`studentTestSection`}>


                                                    <div className="fontBtn">
                                                        <button
                                                            onMouseDown={startDecrement}
                                                            onMouseUp={stopIncrementing}
                                                            onMouseLeave={stopIncrementing}
                                                            disabled={count <= 16}
                                                            onClick={decreaseFont}
                                                        >
                                                            <ZoomOutIcon />

                                                        </button>
                                                        <button
                                                            disabled={count >= 50}
                                                            onMouseDown={startIncrementing}
                                                            onMouseUp={stopIncrementing}
                                                            onMouseLeave={stopIncrementing}
                                                            onClick={increaseFont}
                                                        >
                                                            <ZoomInIcon />
                                                        </button>
                                                    </div>
                                                    <SwipeableViews index={activePaginationPage - 1} enableMouseEvents onChangeIndex={handleSwipe} className="creatorStudioSwip">
                                                        {treeData.filter((item: any) => item.data.fileType).map((val: any, index: number) => {
                                                            return (
                                                                <div className="checkShadow" key={index}>
                                                                    <div className={`image_parent swipeableCard`}>
                                                                        <p className="chapterName" dangerouslySetInnerHTML={{ __html: (val.hierarchyName === "defaultRootHierarchy" ? "" : val.hierarchyName) }} ></p>
                                                                        {validateTextWithTag(val?.text) ?
                                                                            <p className="heading_text questionHeading" style={{ fontSize: `${count}px` }} dangerouslySetInnerHTML={{ __html: val.text?.replace(/\\n\\t/g, '<br />') }} ></p> :
                                                                            val?.Media?.length ?
                                                                                <div className="image_section">
                                                                                    {val.Media.map((elem: any) => {
                                                                                        return (
                                                                                            <>
                                                                                                {isImageUrl(elem) ?
                                                                                                    <Image
                                                                                                        width="100%"
                                                                                                        src={elem}
                                                                                                        key={elem}
                                                                                                    /> :
                                                                                                    <Button className="downloadBtn"
                                                                                                        onClick={() => downloadFileClick(elem)}
                                                                                                        btnText={<><InsertDriveFileOutlinedIcon /> Download File</>} />
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div> : ""
                                                                        }
                                                                        {(courseType === "Test") && (val.description && <div className="test_heading">
                                                                            <div className="explanation_section">  <p className="questionHeading" dangerouslySetInnerHTML={{ __html: val.description?.replace(/\\n\\t/g, '<br />') }}></p> <Tooltip title="Note: This section should contain detailed explanation for answers and will be shown to the students at the time of results ">  <InfoIcon /> </Tooltip></div>
                                                                        </div>)}
                                                                        {(courseType !== "Test" && activepage !== "Question") && (val.description && <div className="test_heading">
                                                                            <div className="explanation_section"><p className="questionHeading" dangerouslySetInnerHTML={{ __html: val.description?.replace(/\\n\\t/g, '<br />') }}></p> </div>
                                                                        </div>)}
                                                                        <div className="image_feild_parent">
                                                                            {validateTextWithTag(val.text) ? val?.Media?.length ?
                                                                                <div className="image_section">
                                                                                    {val.Media.map((elem: any) => {
                                                                                        return (
                                                                                            <>
                                                                                                {isImageUrl(elem) ?
                                                                                                    <Image
                                                                                                        width="100%"
                                                                                                        src={elem}
                                                                                                        key={elem}
                                                                                                    /> :
                                                                                                    <Button className="downloadBtn"
                                                                                                        onClick={() => downloadFileClick(elem)}
                                                                                                        btnText={<><InsertDriveFileOutlinedIcon /> Download File</>} />
                                                                                                }

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div> : "" : ""}
                                                                            {treeData.length ?
                                                                                <div className="studentMcqOptions" style={{ width: val?.Media?.length ? "40%" : "100%" }}>
                                                                                    {questionType === "single" ?
                                                                                        <RadioGroup
                                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                                            name="radio-buttons-group"
                                                                                        >
                                                                                            {showOptions() ? currentMcqdata?.map((item: any, index: number) => {
                                                                                                return (
                                                                                                    <div className="mcqCheckbox" key={index}>
                                                                                                        <FormControlLabel
                                                                                                            value={item}
                                                                                                            onClick={(e) => removeAnswer(index, 'single', e)}
                                                                                                            control={
                                                                                                                <Radio
                                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectAnswer(e, index, "single")}
                                                                                                                    checked={index.toString() === regularSelectedAns}
                                                                                                                    sx={{
                                                                                                                        color: "#306eb3",
                                                                                                                        '&.Mui-checked': {
                                                                                                                            color: "#306eb3",
                                                                                                                        },
                                                                                                                    }} />
                                                                                                            }
                                                                                                            label={<span dangerouslySetInnerHTML={{ __html: item }} />}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }) : null}
                                                                                        </RadioGroup>
                                                                                        : ""}
                                                                                    {questionType === "multiple" ? currentMcqdata?.map((item: any, index: number) => {
                                                                                        return (
                                                                                            <div className="mcqCheckbox" key={index}>
                                                                                                <input type="checkbox"
                                                                                                    disabled={disableSubmit}
                                                                                                    checked={fillAnswersValue("multiple").includes(index.toString())}
                                                                                                    // checked={(regularSelectedAns !== "") ? (index === regularSelectedAns) : false}
                                                                                                    id={item}
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectAnswer(e, index, "multiple")}
                                                                                                />
                                                                                                <label htmlFor={item} dangerouslySetInnerHTML={{ __html: item }} />
                                                                                            </div>
                                                                                        )
                                                                                    }) : ""}
                                                                                    {questionType === "text" ?
                                                                                        <input className="cs_input_range" onChange={textAnswerChange} placeholder="Enter answer range" value={textAnsValue()} />
                                                                                        : ""}
                                                                                    {correctAnimation ? <Animation name={correctAnimationData} /> : ""}
                                                                                    {failedAnimation ? <Animation name={failedAnimationData} /> : ""}
                                                                                    {courseType !== "Test" && treeData.length ?
                                                                                        <div className="mcqButtons">
                                                                                            {(activepage === "Question" && params.type !== "questions") &&
                                                                                                <PrimaryButton text="Submit" disabled={disableSubmit} onClick={regularSubmitClick} className={disableSubmit ? "disabledbtn" : ""}>
                                                                                                </PrimaryButton>}
                                                                                            {showExplanationBtn() && ((descriptionValue && activepage === "Question") ?
                                                                                                <PrimaryButton text={`${hideExplanation ? "Hide" : "Show"} Explanation`} onClick={showExplanation} className="explanationBtn">
                                                                                                </PrimaryButton> : "")}
                                                                                        </div> : ""
                                                                                    }
                                                                                </div> : ""}
                                                                        </div>
                                                                        {hideExplanation ?
                                                                            descriptionValue &&
                                                                            <div className="test_heading">
                                                                                <div dangerouslySetInnerHTML={{ __html: descriptionValue }}></div>
                                                                            </div>
                                                                            : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </SwipeableViews>



                                                </div> : <div className="noPagesFound">
                                                    <p>Please Add Pages!</p>
                                                    {(isModified) ?
                                                        <div className="outerActionbtn">
                                                            <Tooltip title={isChanged ? "Please save the changes" : "Add Page"}>
                                                                <Icon
                                                                    iconName="Page"
                                                                    title="Add Page"
                                                                    onClick={() => addFile(0, fileData, "rootPage")}
                                                                ></Icon>
                                                            </Tooltip>
                                                        </div> : ""}
                                                </div>
                                    }
                                    {loaderStatus ? "" :
                                        <>
                                            {(hasSubmitted && submissionError.headerValue) ? <Alert severity="error">{submissionError.headerValue}</Alert> : ""}
                                            {(hasSubmitted && submissionError.subject) ? <Alert severity="error">{submissionError.subject}</Alert> : ""}
                                            {(hasSubmitted && submissionError.chapter) ? <Alert severity="error">{submissionError.chapter}</Alert> : ""}
                                            {(hasSubmitted && submissionError.topic) ? <Alert severity="error">{submissionError.topic}</Alert> : ""}
                                            {(hasSubmitted && submissionError.difficulty) ? <Alert severity="error">{submissionError.difficulty}</Alert> : ""}
                                            {(hasSubmitted && submissionError.mcqOption) ? <Alert severity="error">{submissionError.mcqOption}</Alert> : ""}
                                            {(hasSubmitted && submissionError.correctAnswer) ? <Alert severity="error">{submissionError.correctAnswer}</Alert> : ""}
                                            <div className="saveBtnParent">
                                                <Pagination className="finalPagination" onChange={paginationChange} count={paginationCount()} boundaryCount={1} siblingCount={0} page={activePaginationPage} variant="outlined" color="primary"
                                                    renderItem={(item: any) => (
                                                        <PaginationItem
                                                            {...item}
                                                            className={submittedPagination(item)}
                                                        />
                                                    )
                                                    }
                                                />

                                                {treeData.findIndex((item: any) => item.data.fileType) > -1 &&
                                                    isModified ?
                                                    <>
                                                        <Button className={`${checkErrorOnValidation() ? "savePageBtn validationError" : "savePageBtn"}`}
                                                            onClick={saveClickcc}
                                                            btnText={editStatus ? "Save" : "Create"} />

                                                        {editStatus && <Button className={`${checkErrorOnValidation() ? " savePageBtn validationError" : "savePageBtn"}`}
                                                            onClick={() => addFile(0, fileData, "rootPage")}
                                                            btnText="Create new Page" />}
                                                    </>
                                                    :
                                                    ""
                                                }
                                            </div>
                                            {params.type === "questions" && (questionBankData?.total ?
                                                <TablePagination
                                                    component="div"
                                                    count={questionBankData.total}
                                                    page={page}
                                                    className="q_pagination"
                                                    onPageChange={handleChangePage}
                                                    rowsPerPage={30}
                                                    rowsPerPageOptions={[]}
                                                /> : "")}
                                        </>}
                                </div>


                            </div>

                        </div>
                    </div>
                </div >
            </>
    );
}
export default CreatorStudioFunctional


const defaultData = {
    text: "",
    description: "",
    mcqData: '["","","",""]',
    Answer: "",
    PageOrder: 0
}

