import { Icon } from '@fluentui/react';
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { getRandomColor } from '../../../utils/CommonFunction';

interface DepartmentBodyProps {
    // Define your props here
    departmentCourseData: any;
    addDepartmentClick: () => void;
    editStatus: boolean;
    addClassClick: (item: any) => void;
    editClick: (item: any, e: React.MouseEvent<HTMLElement>) => void;
    deleteClassClick: (item: any, name: string, e: React.MouseEvent<HTMLElement>) => void;
    editDepartmentClick: (item: any, name: string) => void;
    deleteDepartmentClick: (item: any, name: string) => void;
    bodyClick: (elem: any, event: React.MouseEvent<HTMLElement>) => void;
}

const DepartmentBody: React.FC<DepartmentBodyProps> = ({ departmentCourseData, addDepartmentClick, editStatus, addClassClick, deleteClassClick, editClick, editDepartmentClick, deleteDepartmentClick, bodyClick }) => {
    return (
        <>
            {/* <div className="show_label" style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <h2>Total Departments</h2> -
                <p className="">{departmentCourseData.length}</p>
            </div> */}
            <div className="manageTeacherBody">

                <Tooltip title="Click to add department">
                    <div
                        className="addTeacher"
                        onClick={addDepartmentClick}
                    >
                        <Icon
                            iconName="Add"
                            className="homeworkIcons"
                            title="Add Department"
                        />
                        <div className="addteacherLabel">Add</div>
                    </div>
                </Tooltip>
            </div>

            {departmentCourseData.map((item: any, index: number) => {
                return (
                    <div className="departmentBody" key={index} style={{ backgroundColor: getRandomColor(item.deptName).color }}>
                        <div className="department">
                            <h3>{item.deptName}</h3>
                            <div className="subjectFacePile">
                                <Tooltip title="Edit Department">
                                    <Icon
                                        iconName="Edit"
                                        style={{ marginLeft: "15px" }}
                                        className="analyticDlt"
                                        onClick={() => editDepartmentClick(item, "Edit Department")}
                                    ></Icon>
                                </Tooltip>
                                <Tooltip title="Delete Department">
                                    <Icon
                                        className="analyticDlt"
                                        iconName="Trash"
                                        style={{ marginLeft: "15px" }}
                                        onClick={() => deleteDepartmentClick(item, "Delete Department")}
                                    ></Icon>
                                </Tooltip>

                            </div>
                        </div>
                        <div className="classContainer">
                            {
                                item?.classes.map((elem: any, index: number) => {
                                    return (
                                        <div className="class" key={index} onClick={(e: React.MouseEvent<HTMLElement>) => bodyClick(elem, e)} style={{ background: getRandomColor(item.courseName).color }}>
                                            <div className="dateAndMore">
                                                <div className="facePileAndMore">
                                                    <div className="subjectFacePile">

                                                        <Tooltip title="Edit Class">
                                                            <Icon
                                                                iconName="Edit"

                                                                className="analyticDlt"
                                                                onClick={(e: React.MouseEvent<HTMLElement>) => editClick(elem, e)}
                                                            ></Icon>
                                                        </Tooltip>

                                                        <Tooltip title="Delete Class">
                                                            <Icon
                                                                className="analyticDlt"
                                                                iconName="Trash"

                                                                onClick={(e: React.MouseEvent<HTMLElement>) => deleteClassClick(elem, "Delete Class", e)}
                                                            ></Icon>
                                                        </Tooltip>

                                                    </div>

                                                </div>
                                            </div>
                                            {/* <div className="subjectDetails"> */}
                                            <Link
                                                to={"/"}
                                                replace={true}
                                            >
                                                <h2 className="boxHeading" style={{ fontWeight: "500" }}>
                                                    {elem.courseName}
                                                </h2>
                                            </Link>

                                            <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                <div className="gradeName">{elem.classGrade} - {elem.section}</div>
                                            </div>
                                            <div className="gradeDisplayName">{elem.displayName}</div>

                                            {/* </div> */}
                                        </div>
                                    )
                                })
                            }
                            <Tooltip title="Click to add class">
                                <div
                                    className="class addClass"
                                    style={{ background: "#EAEAEA" }}
                                    onClick={() => addClassClick(item)}
                                >
                                    <div>
                                        <Icon
                                            iconName="Add"
                                            className="homeworkIcons"
                                            title="Add Class"
                                        />
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )
            })}
        </>
    );
};

export default DepartmentBody;