import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    TextField,
    Grid,
    Typography,
    Container,
    MenuItem, Select, Switch, TextareaAutosize
} from '@mui/material';
import { Institution } from '../index'; // Make sure to import the interface
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import Dragger from "antd/es/upload/Dragger";
import { notifications, openNotification } from "../../../utils/CommonFunction";

// Validation schema using Yup
const validationSchema = Yup.object({
    InstitutionType: Yup.string().required('Institution Type is required'),
    Details: Yup.string().required('Details are required'),
    TierCode: Yup.number().required('Tier Code is required').min(0, 'Tier Code must be 0 or greater'),
    StudentSizeLimit: Yup.number().required('Student Size Limit is required').min(0, 'Must be 0 or greater'),
    TeacherSizeLimit: Yup.number().required('Teacher Size Limit is required').min(0, 'Must be 0 or greater'),
    AdminSizeLimit: Yup.number().required('Admin Size Limit is required').min(0, 'Must be 0 or greater'),
    InstitutionCode: Yup.string().required('Institution Code is required'),
    InstitutionName: Yup.string().required('Institution Name is required'),
    IsActive: Yup.number().required('IsActive is required').oneOf([0, 1], 'Must be 0 or 1'),
    InstitutionPicture: Yup.string().required('Institution Picture URL is required'),
    Username: Yup.string().required('Username is required'),
    FirstName: Yup.string().required('First Name is required'),
    MiddleName: Yup.string(),
    LastName: Yup.string().required('Last Name is required'),
    PhoneNumber: Yup.string().required('Phone Number is required'),
    EmailId: Yup.string().email('Invalid email format').required('Email is required'),
    PersonalAddress: Yup.string().required('Personal Address is required'),
    DOB: Yup.date().required('Date of Birth is required'),
    UserPassword: Yup.string().required('Password is required'),
});

interface AddEditInstitutionsProps {
    item?: Institution;
    onSubmit: (values: Institution) => void;
    isEditMode: boolean;
    handleClose: () => void
    onImageSelect: (img: File) => void
}

const AddEditInstitutions: React.FC<AddEditInstitutionsProps> = ({
    item,
    onSubmit,
    isEditMode,
    handleClose,
    onImageSelect
}) => {
    const [selectedImg, setSelectedImg] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState<string | undefined>(item?.InstitutionPicture);

    useEffect(() => {
        if (item?.InstitutionPicture) {
            setPreviewImage(item?.InstitutionPicture)
        }
    }, [item])

    const onClose = (e: any) => {
        e.stopPropagation();
        handleClose();
    }

    const draggerChange = (e: Blob) => {
        if (e) {
            if (e.size > 300 * 1024) {
                openNotification("topRight", "Image size exceeds the 300KB limit. Please upload a smaller image.", "error", "error");
                return;
            }
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result;
                if (typeof result === 'string') {
                    const file = dataURLtoFile(result, 'hello.png');
                    const previewImageUrl = URL.createObjectURL(file);
                    setSelectedImg(file);
                    setPreviewImage(previewImageUrl);
                    onImageSelect(file)
                }
            };
            reader.readAsDataURL(e);
        }
    }

    const dataURLtoFile = (dataurl: string, filename: string) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)?.[1];
        let bstr = atob(arr[arr.length - 1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) u8arr[n] = bstr.charCodeAt(n);
        const file = new File([u8arr], filename, { type: mime });
        return file;
    };
    return (
        <Container maxWidth="lg">
            {/* <Box sx={{ mt: 4 }}> */}
            <Typography variant="h4" gutterBottom>
                {isEditMode ? 'Edit Institution' : 'Add New Institution'}
            </Typography>
            <Formik
                initialValues={item || {} as Institution}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values);
                    setSubmitting(false);
                }}
            >
                {({ isSubmitting, touched, errors, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Field
                                    name="InstitutionName"
                                    as={TextField}
                                    label="Institution Name"
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.InstitutionName && <ErrorMessage name="InstitutionName" />}
                                    error={touched.InstitutionName && Boolean(errors.InstitutionName)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="InstitutionType"
                                    as={TextField}
                                    label="Institution Type"
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.InstitutionType && <ErrorMessage name="InstitutionType" />}
                                    error={touched.InstitutionType && Boolean(errors.InstitutionType)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="Details"
                                    as={TextareaAutosize}
                                    minRows={4}
                                    label="Details"
                                    style={{ width: '99.4%' }}
                                    variant="outlined"
                                    placeholder="Details"
                                    helperText={<ErrorMessage name="Details" />}
                                    error={Boolean(<ErrorMessage name="Details" />)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="TierCode"
                                    as={TextField}
                                    label="Tier Code"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.TierCode && <ErrorMessage name="TierCode" />}
                                    error={touched.TierCode && Boolean(errors.TierCode)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="StudentSizeLimit"
                                    as={TextField}
                                    label="Student Size Limit"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.StudentSizeLimit && <ErrorMessage name="StudentSizeLimit" />}
                                    error={touched.StudentSizeLimit && Boolean(errors.StudentSizeLimit)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="TeacherSizeLimit"
                                    as={TextField}
                                    label="Teacher Size Limit"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.TeacherSizeLimit && <ErrorMessage name="TeacherSizeLimit" />}
                                    error={touched.TeacherSizeLimit && Boolean(errors.TeacherSizeLimit)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="AdminSizeLimit"
                                    as={TextField}
                                    label="Admin Size Limit"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.AdminSizeLimit && <ErrorMessage name="AdminSizeLimit" />}
                                    error={touched.AdminSizeLimit && Boolean(errors.AdminSizeLimit)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="InstitutionCode"
                                    as={TextField}
                                    label="Institution Code"
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.InstitutionCode && <ErrorMessage name="InstitutionCode" />}
                                    error={touched.InstitutionCode && Boolean(errors.InstitutionCode)}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Field name="IsActive" type="switch">
                                    {({ field }: { field: any }) => (
                                        <Switch
                                            {...field}
                                            color="primary"
                                            checked={field.value === 1}
                                            onChange={(event) => setFieldValue('IsActive', event.target.checked ? 1 : 0)}
                                        />
                                    )}
                                </Field>
                                <Typography variant="body1">Is Active</Typography>
                            </Grid>
                            {!item ? <>  <Grid item xs={6}>
                                <Field
                                    name="Username"
                                    as={TextField}
                                    label="Username"
                                    fullWidth
                                    variant="outlined"
                                    helperText={touched.Username && <ErrorMessage name="Username" />}
                                    error={touched.Username && Boolean(errors.Username)}
                                />
                            </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        name="FirstName"
                                        as={TextField}
                                        label="First Name"
                                        fullWidth
                                        variant="outlined"
                                        helperText={touched.FirstName && <ErrorMessage name="FirstName" />}
                                        error={touched.FirstName && Boolean(errors.FirstName)}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        name="MiddleName"
                                        as={TextField}
                                        label="Middle Name"
                                        fullWidth
                                        variant="outlined"
                                        helperText={touched.MiddleName && <ErrorMessage name="MiddleName" />}
                                        error={touched.MiddleName && Boolean(errors.MiddleName)}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        name="LastName"
                                        as={TextField}
                                        label="Last Name"
                                        fullWidth
                                        variant="outlined"
                                        helperText={touched.LastName && <ErrorMessage name="LastName" />}
                                        error={touched.LastName && Boolean(errors.LastName)}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        name="PhoneNumber"
                                        as={TextField}
                                        label="Phone Number"
                                        fullWidth
                                        variant="outlined"
                                        helperText={touched.PhoneNumber && <ErrorMessage name="PhoneNumber" />}
                                        error={touched.PhoneNumber && Boolean(errors.PhoneNumber)}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        name="EmailId"
                                        as={TextField}
                                        label="Email"
                                        type="email"
                                        fullWidth
                                        variant="outlined"
                                        helperText={touched.EmailId && <ErrorMessage name="EmailId" />}
                                        error={touched.EmailId && Boolean(errors.EmailId)}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        name="PersonalAddress"
                                        as={TextField}
                                        label="Personal Address"
                                        fullWidth
                                        variant="outlined"
                                        helperText={touched.PersonalAddress && <ErrorMessage name="PersonalAddress" />}
                                        error={touched.PersonalAddress && Boolean(errors.PersonalAddress)}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        name="DOB"
                                        as={TextField}
                                        label="Date of Birth"
                                        type="date"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        helperText={touched.DOB && <ErrorMessage name="DOB" />}
                                        error={touched.DOB && Boolean(errors.DOB)}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name="UserPassword"
                                        as={TextField}
                                        label="Password"
                                        type="password"
                                        fullWidth
                                        variant="outlined"
                                        helperText={touched.UserPassword && <ErrorMessage name="UserPassword" />}
                                        error={touched.UserPassword && Boolean(errors.UserPassword)}
                                    />
                                </Grid> </> : ""}
                            <Grid item xs={6}>

                                <Dragger multiple={false} maxCount={1} onChange={(e: any) => draggerChange(e.file.originFileObj)}>
                                    {
                                        previewImage ? <img className="institute-img" src={previewImage} alt="" /> :


                                            <p className="ant-upload-hint">
                                                Click or drag file to this area to upload, Support for a
                                                single file upload.
                                            </p>


                                    }
                                </Dragger>

                                {/* <Field
                                        name="InstitutionPicture"
                                        as={TextField}
                                        label="Institution Picture URL"
                                        fullWidth
                                        variant="outlined"
                                        helperText={<ErrorMessage name="InstitutionPicture" />}
                                        error={Boolean(<ErrorMessage name="InstitutionPicture" />)}
                                    /> */}
                            </Grid>
                            <Grid item xs={12}>
                                <div className="footerBtn">
                                    <PrimaryButton
                                        disabled={isSubmitting}
                                        onClick={() => onSubmit(values)}
                                        text={isEditMode ? "Update" : "Add"}
                                    />
                                    <DefaultButton
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                        text={isEditMode ? "Cancel" : "No"}
                                    />
                                </div>

                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            {/* </Box> */}
        </Container>
    );
};

export default AddEditInstitutions;
