/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef } from 'react'
import "./ImageCropComponent.css"

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop'

import { useDebounceEffect } from './useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import { CanvasPreview } from './CanvasPreview'
import { Slider } from 'antd'
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}
interface ImageCropProps {
    images: any;
    initialPreview: any,
    backFunction: (data: any) => void;
}

export default function ImageCropComponent({ images, initialPreview, backFunction }: ImageCropProps) {
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [imageUrl, setImageUrl] = useState("")
    const [aspect, setAspect] = useState<number | undefined>()
    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    //   function onDownloadCropClick() {
    //     if (!previewCanvasRef.current) {
    //       throw new Error('Crop canvas does not exist')
    //     }
    //     previewCanvasRef.current.toBlob((blob) => {
    //       if (!blob) {
    //         throw new Error('Failed to create blob')
    //       }
    //       if (blobUrlRef.current) {
    //         URL.revokeObjectURL(blobUrlRef.current)
    //       }
    //       blobUrlRef.current = URL.createObjectURL(blob)
    //       hiddenAnchorRef.current!.href = blobUrlRef.current
    //       hiddenAnchorRef.current!.click()
    //     })
    //   }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                CanvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
                const data = await CanvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
                setImageUrl(data)
                backFunction(data)
            }
        },
        100,
        [completedCrop, scale, rotate],
    )
    const scaleChange = (event: number) => {
        setScale((event / 10) + 1)
    }
    const rotateChange = (event: number) => {
        if (rotate === 0 && (event === -90)) {
            setRotate(360 + event)
        } else if (rotate === 360 && event === 90) {
            setRotate(0 + event)
        } else {
            setRotate(rotate + event)
        }
    }
    return (


        <div className="App">

            <div className="Crop-Controls">
                <div className='rotateAndCrop'>
                    <label htmlFor="scale-input">Zoom: </label>
                    <Slider onChange={scaleChange} defaultValue={scale} />
                    <span>{(scale - 1).toFixed(2)} x</span>
                </div>
                <div className='rotateAndCrop'>
                    <label htmlFor="rotate-input">Rotate: </label>
                    <Rotate90DegreesCcwIcon onClick={() => rotateChange(-90)} />
                    <Rotate90DegreesCwIcon onClick={() => rotateChange(90)} />
                    {/* <Slider onChange={rotateChange} defaultValue={rotate} /> */}
                    <span>{rotate.toFixed(2)}&deg;</span>
                </div>
            </div>
            <p>Please hold and drag on image to crop</p>
            {!!images && (
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => {
                        setCrop(percentCrop)
                    }}
                    onComplete={(c) => {
                        setCompletedCrop(c)
                    }}
                    aspect={aspect}
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={initialPreview}
                        style={{ transition: "0.5s", transform: `scale(${scale}) rotate(${rotate}deg)` }}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )}
            {!!completedCrop && (
                <>
                    <div className='croppedimagePreview'>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </div>
                    <div>
                        <a href="#hidden"
                            ref={hiddenAnchorRef}
                            download
                            style={{
                                position: 'absolute',
                                top: '-200vh',
                                visibility: 'hidden',
                            }}
                        >
                            Hidden download
                        </a>
                    </div>
                </>
            )}
        </div>


    )
}
