// window.katex = katex;
import katex from "katex";
import "katex/dist/katex.css";
import React, { useEffect } from "react";
import "./jquery";
import "./index.css"

import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import mathquill4quill from "mathquill4quill";
import "mathquill4quill/mathquill4quill.css";
import { CUSTOM_OPERATORS, toolbarOptions } from "../../../utils/quillEditorData";
import { Action, AlignAction, DeleteAction, ImageSpec, ResizeAction } from "quill-blot-formatter";


window.katex = katex;

// class CustomImageSpec extends ImageSpec {
//   getActions() {
//       return [AlignAction, ResizeAction, DeleteAction];
//   }
// }

const QuillEditor = ({ isFocus, value, type, onChange, key }) => {
  const reactQuill = React.createRef();
  const options = {
    displayHistory: true, operators: CUSTOM_OPERATORS, displayDeleteButtonOnHistory: true
  }
  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
    enableMathQuillFormulaAuthoring(
      reactQuill.current.editor,
      options
    );

  }, [])

  // const getActions = ()=> {
  //   return [AlignAction, ResizeAction, DeleteAction];
  // };

  useEffect(() => {
    reactQuill.current.editor.focus();
  }, [isFocus])
  useEffect(() => {
    if (isFocus && reactQuill.current) {
      const quill = reactQuill.current.getEditor();
      quill.focus(); // Focus the editor
      const length = quill.getLength(); // Get the length of the editor content
      quill.setSelection(length); // Set the cursor at the end
    }
  }, [isFocus]);
  return (

    reactQuill &&
    <ReactQuill
      key={key}
      ref={reactQuill}
      id="editor"
      value={value}
      onChange={onChange}
      modules={{
        blotFormatter: {},
        formula: true,
        toolbar: toolbarOptions,
      }}
      theme="snow"
    />
  );
};

export default QuillEditor;