import { Icon } from '@fluentui/react';
import { Tooltip } from 'antd';
import React from 'react';
import { dateFormat } from '../../../utils/CommonFunction';
import PopoverComp from '../../../components/Common/popoverComp';
import Button from '../../../components/Common/Button/Button';
import { Button as MuiButton } from '@mui/material';
import "../index.css"
interface StudentTeacherListProps {
    data: any[];
    name: string;
    teacherEdit: (item: any, name: string) => void;
    handleClose: () => void;
    modalOpen: boolean;
    teacherAddClick: () => void;
    deleteClick: (item: any, name: string) => void;
    importClick: () => void,
    generateClick?: () => void,
    generatePaidClick?: () => void,
    classId?: any
}

const StudentTeacherList: React.FC<StudentTeacherListProps> = ({ data, name, teacherEdit, teacherAddClick, deleteClick, importClick, classId, generateClick, generatePaidClick }) => {

    const popoverContent = <div className="publishPop" >
        <ul style={{ flexDirection: "column" }}>
            <MuiButton variant="outlined" onClick={generateClick}>Generate Register Link</MuiButton>
            {name === "Students" ?
                <MuiButton variant="outlined" onClick={generatePaidClick}>Generate paid Register Link</MuiButton> : ""}
        </ul>
    </div>

    return (
        <>
            <div className="student_heading" style={{ display: "flex", justifyContent: "space-between" , flexWrap:"wrap" , gap:"10px" }}>
                <div className="show_label" style={{ display: "flex", alignItems: "center", gap: 5 }}>
                    <h3>Total {name}</h3> -
                    <h3 className="">{data?.length}</h3>
                </div>
                <div style={{display:"flex" , gap:"10px"}}>

                    {/* <div className='import_container' onClick={importClick}>
                        <Icon
                            iconName="Down"
                            className="import_icon"
                            title={`Import ${name}`}
                        />
                        <div className="">Import</div>
                    </div> */}
                    <Tooltip title="Generate Link">
                        <PopoverComp showTool={true} placement="left" content={popoverContent}>
                            <div className='import_container' >
                                <Icon
                                    iconName="ChevronUpMed"
                                    className="import_icon"
                                    title={`Import ${name}`}
                                />
                                <div className="">Generate Link</div>
                            </div>
                        </PopoverComp>
                    </Tooltip>
                </div>
            </div>

            <div className="manageTeacherBody">

                {/* <div className="addTeacher">
                    <h2>
                        {data?.length}
                    </h2>
                    <div className="addteacherLabel">{name}</div>
                </div> */}

                <div className="addTeacher" onClick={teacherAddClick}>
                    <Icon
                        iconName="Add"
                        className="homeworkIcons"
                        title={`Add ${name}`}

                    />
                    <div className="addteacherLabel">Add</div>
                </div>

                {/* <div className="importTeacher" onClick={importClick}>
                    <Icon
                        iconName="Down"
                        className="homeworkIcons"
                        title={`Import ${name}`}

                    />
                    <div className="addteacherLabel">Import</div>
                </div> */}
                {/* <div className="addTeacher" onClick={generateClick}>
                    <Icon
                        iconName="Add"
                        className="homeworkIcons"
                        title={`Generate Link`}

                    />
                    <div className="addteacherLabel">Generate Register Link</div>
                </div>
                {name === "Students" ?
                    <div className="addTeacher" onClick={generatePaidClick}>
                        <Icon
                            iconName="Add"
                            className="homeworkIcons"
                            title={`Generate Paid Link`}

                        />
                        <div className="addteacherLabel">Generate paid Register Link</div>
                    </div> : ""} */}
            </div>
            {data.length ? data?.map((item: any, index: number) => {
                return (

                    <div className="teacher" key={index}>
                        <div className="facePile">
                            <Icon
                                iconName="Contact"
                                className="homeworkIcons"
                                title="Add Department"
                            />
                        </div>
                        <div className="teacherDetails">
                            <div className="teacherName nameWithDate">
                                <p> {(item.firstName) + " " + (item.middleName) + " " + (item.lastName)}</p> <p> <span className='studentCreatedDateLabel'>Joined on</span> - <span className='studentCreatedDate'>{dateFormat(item?.createdDate)} </span> </p>
                            </div>
                            <Tooltip title={item.emailId}>
                                <p className="teacher_email">{item.emailId}</p>
                            </Tooltip>
                        </div>
                        <div className="teacherActions">
                            <Tooltip title={`Edit ${name}`}>
                                <Icon
                                    iconName="Edit"
                                    title={`Edit ${name}`}
                                    onClick={() => teacherEdit(item, name)}
                                />
                            </Tooltip>
                            <Tooltip title={`Delete ${name}`}>
                                <Icon
                                    iconName="Delete"
                                    title={`Delete ${name}`}
                                    onClick={() => deleteClick(item, name)}
                                />
                            </Tooltip>
                        </div>
                    </div>
                )
            }) : ""}
        </>
    );
};

export default StudentTeacherList;