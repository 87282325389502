import { TextField } from '@fluentui/react';
import { DatePicker } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import Button from '../../components/Common/Button/Button';
import { useParams } from 'react-router-dom';
import { load } from '@cashfreepayments/cashfree-js';
import logo from "../../assets/webodhi.png";
import { useFormik } from 'formik';
import { RegisterSchema } from '../../utils/validationSchema';
import { initialRegister } from '../../utils/formikInitial';
import "./index.css"
import { decryptText, encryptText, setStorageFunction } from '../../utils/CommonFunction';
import { fetchPost } from '../../API/Baseurl';
import jwt_decode from "jwt-decode";
import { openNotification } from '../../redux/sagas/sagas';
interface AddStudentProps {

}

const UserRegister: React.FC<AddStudentProps> = (props) => {
    const [institutionsData, setInstitutionsData] = useState<any>({})
    const params = useParams()

    const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } = useFormik({
        initialValues: initialRegister,
        validationSchema: RegisterSchema,
        onSubmit: () => loginApiCall(),
    });

    const timeStamp = new Date().getTime()


    useEffect(() => {
        if (params.token) {
            const decoded_token: any = jwt_decode(atob(params.token))
            setInstitutionsData(decoded_token)
        }
    }, [params.token])


    const loginApiCall = async () => {
        const verifyData = {
            "emailId": values.emailId,
            "phoneNumber": values.phoneNumber
        }
        if (params.token) {
            const verifyResponse = await fetchPost("Login/VerifyContactInfo", verifyData, atob(params.token))
            if (verifyResponse?.errorMessage) {
                openNotification("error", "Email or phone number is already registered.")
            } else {
                const payload: any = {
                    ...values,
                    token: params.token,
                    classId: params.id,
                    order_id: `${values?.phoneNumber}_${timeStamp}`
                }

                const queryString = new URLSearchParams(payload).toString();
                const encData = encryptText(queryString)
                const userData = {
                    "order_amount": process.env.REACT_APP_ORDER_AMOUNT,
                    "name": `${values.firstName} ${values.middleName ? values.middleName : ""} ${values.lastName}`,
                    "phone": values.phoneNumber.replace(/\D/g, ''),
                    "email": values.emailId,
                    "returnUrl": `${window.location.origin}/payment_success`,
                    "orderId": `${values?.phoneNumber}_${timeStamp}`,

                }

                if (params.token) {
                    const response = await fetchPost("Payment/GetSessionId", userData, atob(params.token))
                    setStorageFunction("registerData", JSON.stringify(payload))
                    const cashfree = await load({
                        mode: process.env.REACT_APP_CASHFREE_MODE //or production
                    })
                    let checkoutOptions = {
                        paymentSessionId: response.payment_session_id,
                        returnUrl: `${window.location.origin}/payment_success`,
                    };
                    cashfree.checkout(checkoutOptions).then(function (result: any) {
                        if (result.error) {
                            alert(result.error.message)
                        }
                        if (result.redirect) {
                            console.log("Redirection")
                        }
                    });
                }
            }
        }
    }


    const phoneChange = (e: any, name: string) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        if (numericValue.length <= 10) {
            setFieldValue(name, numericValue);
        }
    }

    const dateChange = (e: any) => {
        setFieldValue("dateOfBirth", e)
    }


    const registerClick = async (e: any) => {
        e.preventDefault()
        handleSubmit()
    }


    return (
        <div style={{ padding: "20px" }} className='addStudentContainer'>
            <img className='logo_img' src={logo} alt="logo"></img>
            <h2> Student for {institutionsData?.InstitutionName}</h2>
            <div className="addDepartmentForm">
                <TextField
                    label="First Name"
                    placeholder="First Name"
                    className="loginField"
                    name='firstName'
                    required
                    value={values.firstName}
                    onBlur={handleBlur}
                    errorMessage={(touched.firstName && errors.firstName) ? errors.firstName : ""}
                    onChange={handleChange}
                />
                <TextField
                    label="Middle Name"
                    placeholder="Middle Name"
                    className="loginField"
                    name='middleName'
                    value={values.middleName}
                    onChange={handleChange}
                />
                <TextField
                    label="Last Name"
                    placeholder="Last Name"
                    className="loginField"
                    name='lastName'
                    required
                    value={values.lastName}
                    onBlur={handleBlur}
                    errorMessage={(touched.lastName && errors.lastName) ? errors.lastName : ""}
                    onChange={handleChange}
                />
                <TextField
                    label="Contact number"
                    placeholder="Contact number"
                    className="loginField"
                    name='phoneNumber'
                    onBlur={handleBlur}
                    errorMessage={(touched.phoneNumber && errors.phoneNumber) ? errors.phoneNumber : ""}
                    required
                    value={values.phoneNumber}
                    onChange={(e: any) => phoneChange(e, "phoneNumber")}
                />
                <TextField
                    label="Email"
                    placeholder="Email"
                    className="loginField"
                    name='emailId'
                    onBlur={handleBlur}
                    errorMessage={(touched.emailId && errors.emailId) ? errors.emailId : ""}
                    required
                    value={values.emailId}
                    onChange={handleChange}
                />
                <div>
                    <DatePicker
                        isRequired
                        label="Date of birth"
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        value={values.dateOfBirth as any}
                        onSelectDate={(e: any) => dateChange(e)}
                    />
                    {(errors.dateOfBirth && touched.dateOfBirth) ? <p className='date_error'>{errors.dateOfBirth}</p> : ""}
                </div>
                <div className="modal_footer_btn">
                    <Button className='modal_ok_btn' onClick={registerClick} btnText="Continue to Pay 199 RS" />
                </div>
            </div>

        </div>
    );
};

export default UserRegister;