import { SelectionMode } from '@fluentui/react';
import { DetailsList, DetailsListLayoutMode } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import "./index.css"
import DropdownAllSelect from '../../components/Common/dropdownAllSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalytic, getStudentCourses } from '../../redux/actions/API/courses';
import { Tooltip } from 'antd';
import { getClassnameById } from '../../utils/CommonFunction';
import Button from '../../components/Common/Button/Button';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { getDepartmentClasses } from '../../redux/actions/API/departmentClasses';

interface ScoreboardProps {
    // Define your props here
}



const Scoreboard: React.FC<ScoreboardProps> = () => {
    const courseData = useSelector((state: any) => state.api.getStudentCourses?.data?.courses) || []
    const departmentData = useSelector((state: any) => state.api.getDepartmentClasses.data)
    const loaderStatus = useSelector((state: any) => state.ui.loaderStatus.spinnerStatus)
    const classData = departmentData?.map((item: any) => item.classes)
    // const testCourses = courseData?.filter((item: any) => item.courseType === "Test")
    const [options , setOptions] = useState<any>([])
    const [courseList, setCourseList] = useState<any>([])
    const [selectedCourse, setSelectedCourse] = useState<any>([])
    const [classList , setClassList] = useState<any[]>([])
    const [selectedClassList, setSelectedClassList] = useState<any>([])
    const [tableData, setTableData] = useState<any>([])
    const dispatch = useDispatch()
    const onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn) => {}

    useEffect(()=>{
        if(classData){
            setClassList(classData.flat())
        }
    },[classData])

    useEffect(() => {
        dispatch(getStudentCourses())
        dispatch(getDepartmentClasses())
    }, [])

    useEffect(()=>{
        if(courseData){
            const testCourses = courseData?.filter((item: any) => item?.courseType === "Test")
            setOptions([...testCourses])
        }
    },[courseData.length])

    const columns = [
        {
            key: "column1",
            name: "S. No",
            fieldName: "sno",
            minWidth: 70,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: () => onColumnClick,
        },
        {
            key: "column6",
            name: "Class",
            fieldName: "classname",
            minWidth: 100,
            isResizable: true,
            isSorted: true,
            // isSortedDescending: false,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: () => onColumnClick,
            // onColumnClick: (event: React.MouseEvent<HTMLElement>, column: IColumn)=>onColumnClick(event , column),
        },
        {
            key: "column2",
            name: "Name",
            fieldName: "userName",
            minWidth: 100,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: onColumnClick,
        },
        {
            key: "column3",
            name: "Score",
            fieldName: "scoreCount",
            minWidth: 100,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: onColumnClick,
        },
        {
            key: "column4",
            name: "Total score",
            fieldName: "totalScoreCount",
            minWidth: 100,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: onColumnClick,
        },
        {
            key: "column5",
            name: "Percentage Score",
            fieldName: "percantage",
            minWidth: 100,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: onColumnClick,
        },

    ];

    const onChangeClass = (e: any, val: any, name: any, currentData: any) => {
        setSelectedClassList(val)
        const dataa = val.map((item: any) => {
            const data2 = options.filter((val: any) => val.classIds.includes(item.classId))
            return data2
        })

        const uniqueSet = new Set(dataa.flat().map((obj: any) => JSON.stringify(obj)));
        const uniqueArr = Array.from(uniqueSet).map((str: any) => JSON.parse(str));
        const selectedCourseData = uniqueArr.filter((obj1: any) =>
            selectedCourse.find((obj2: any) => obj1.courseId === obj2.courseId)
        );
        setCourseList(uniqueArr)
        setSelectedCourse(selectedCourseData)
    }

    const onChange = (e: any, val: any, name: any, currentData: any) => {
        const scoreCardData = localStorage.getItem("scoreboard")
        setSelectedCourse(val)
        if (currentData) {
            if (scoreCardData) {
                const parseData = JSON.parse(scoreCardData)
                const availbleData = parseData.some((obj: any) => obj.courseId === currentData?.option?.courseId)
                if (availbleData) {
                    console.log("already exist")
                }
                else {
                    const data = {
                        courseId: currentData.option.courseId,
                        classId: null
                    }
                    if (options.length) {
                        dispatch(getAnalytic(data))
                    }
                }
            } else {
                const data = {
                    courseId: currentData.option.courseId,
                    classId: null
                }
                dispatch(getAnalytic(data))
            }
        }
    }
    const getCoursename = (id: number) => {
        const currentIndex = options.findIndex((item: any) => item.courseId === id)
        return options[currentIndex].courseName
    }

    const Showtooltip = (array: any, score: string) => {
        return (
            <ul className='scoreTooltip'>
                {array.array?.map((item: any) => {
                    return (
                        <li>{getCoursename(item.courseId)} - {item.score}</li>
                    )
                })}
            </ul>
        )
    }
    const ShowTotaltooltip = (array: any, score: string) => {
        return (
            <ul className='scoreTooltip'>
                {array.array?.map((item: any, index: number) => {
                    return (
                        <li key={index}>{getCoursename(item.courseId)} - {item.totalScore}</li>
                    )
                })}
            </ul>
        )
    }

    useEffect(() => {
        const scoreboard = localStorage.getItem("scoreboard")
        if (scoreboard) {
            const parseData = JSON.parse(scoreboard)
            const filteredData1 = parseData.filter((obj1: any) => selectedCourse.some((obj2: any) => obj2.courseId === obj1.courseId));
            const summaryMap: any = {};

            filteredData1.forEach((item: any) => {
                const userId = item.userId as number;

                if (!summaryMap[userId]) {
                    summaryMap[userId] = {
                        userId: userId,
                        userName: item.userName as string,
                        scoreCount: 0,
                        totalScoreCount: 0,
                        scoresByCourse: []
                    };
                }

                summaryMap[userId].scoreCount += item.score as number;
                summaryMap[userId].totalScoreCount += item.totalScore as number;

                // Find if this course already exists in scoresByCourse array
                const courseIndex = summaryMap[userId].scoresByCourse.findIndex((course: any) => course.courseId === item.courseId);
                if (courseIndex !== -1) {
                    // If course exists, update the score and totalScore values
                    summaryMap[userId].scoresByCourse[courseIndex].score += item.score as number;
                    summaryMap[userId].scoresByCourse[courseIndex].totalScore += item.totalScore as number;
                } else {
                    // If course does not exist, add it to the scoresByCourse array
                    summaryMap[userId].scoresByCourse.push({
                        courseId: item.courseId as number,
                        score: item.score as number,
                        totalScore: item.totalScore as number
                    });
                }

                // Preserve other keys from the original data
                Object.keys(item).forEach(key => {
                    if (!(key in summaryMap[userId])) {
                        summaryMap[userId][key] = item[key];
                    }
                });
            });

            const summaryArray = Object.values(summaryMap);

            const percantage = summaryArray.map((item: any, index: number) => {
                const percantage = (item.scoreCount * 100) / (item.totalScoreCount || 1)
                const scores = <Tooltip title={<Showtooltip array={item.scoresByCourse} score="score" />}>
                    {item.scoreCount}
                </Tooltip>
                const totalscores = <Tooltip title={<ShowTotaltooltip array={item.scoresByCourse} score="score" />}>
                    {item.totalScoreCount}
                </Tooltip>
                return { ...item, percantage: (percantage ? percantage.toFixed(2) : 0), classname: getClassnameById(item.classId, departmentData), totalScoreCount: totalscores, scoreCount: scores, sno: index + 1 }
            })

            setTableData(percantage)
        }
    }, [loaderStatus, selectedCourse])


    let currentIndex = 0;

    const refreshClick = () => {
        if (currentIndex < selectedCourse.length) {
            const item = selectedCourse[currentIndex];
            const data = {
                courseId: item.courseId,
                classId: null
            };
            dispatch(getAnalytic(data));
            currentIndex++;
            setTimeout(refreshClick, 1000);
        }
    };


    return (
        <div className='homeworkElementsContainer'>
            <div className="filter_container">
                <div className='scoreFilter'>
                    <DropdownAllSelect labelText="Select class" value={selectedClassList} onChange={onChangeClass} options={classList} name="classGrade" />
                    <DropdownAllSelect labelText="Select Course" value={selectedCourse} onChange={onChange} options={courseList} name="courseName" />
                </div>

                <Button className='refresh_btn' onClick={refreshClick} btnText="Refresh" />
            </div>

            {selectedCourse.length ?
                <DetailsList
                    items={tableData}
                    className="analytic_table scorebaord_table"
                    selectionMode={SelectionMode.none}
                    columns={columns}
                    layoutMode={DetailsListLayoutMode.justified}
                />
                :
                <p className='emptyText'>Please Select course first</p>}
        </div>
    );
};

export default Scoreboard;