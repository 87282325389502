import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import "./index.css"
import { convertSubjectData } from '../../../utils/CommonFunction';
import { useDispatch, useSelector } from 'react-redux';
import { postAPiCall } from '../../../API/Baseurl';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getTopics } from '../../../redux/actions/API/courses';


const difficulityValue = [
    {
        name: "Easy",
        id: 0
    },
    {
        name: "Medium",
        id: 1
    },
    {
        name: "Hard",
        id: 2
    },
]

const filter = createFilterOptions<FilmOptionType>();
interface SubjectSelectionProps {
    subjectId: any; setSubjectId: any; topicId: any; setTopicId: any; chapterId: any; setChapterId: any;
    subjectValue: any; setSubjectValue: any;
    chapterValue: any; setChapterValue: any;
    topicValue: any; setTopicValue: any;
    chapterData: any; setChapterData: any;
    topicData: any; setTopicData: any;
    difficulty: any; changeDifficult: any;
}
const SubjectSelection: React.FC<SubjectSelectionProps> = ({ subjectId, setSubjectId, topicId, setTopicId, chapterId, setChapterId,
    subjectValue, setSubjectValue, chapterValue, setChapterValue, topicValue, setTopicValue,
    chapterData, setChapterData, topicData, setTopicData, difficulty, changeDifficult }) => {
    const getTopicData = useSelector((state: any) => state?.api?.getTopics?.data)

    const dispatch = useDispatch()
    const [subjectData, setSubjectData] = useState<any[]>([])
    const [diffValue, setDiffValue] = useState<any>({})

    useEffect(() => {
        setSubjectData(convertSubjectData(getTopicData))
    }, [getTopicData])

    const listClick = async (option: any, name: string) => {

        if (option) {
            if (name === "subject") {
                if (option?.inputValue) {
                    const response = await postAPiCall("/CreatorStudios/AddTopics", { Name: option?.inputValue })
                    if (response?.status === 200) {
                        const newObj = {
                            name: option?.inputValue,
                            id: response.data.result,
                            data: []
                        }
                        setChapterData([])
                        setTopicData([])
                        setSubjectId(response.data.result)
                        setSubjectValue(newObj)
                        setSubjectData([...subjectData, newObj])
                        setChapterId("")
                        setTopicId("")
                    }
                } else {
                    setChapterData(option.data)
                    setTopicData([])
                    setChapterValue(defaultData)
                    setTopicValue(defaultData)
                    setSubjectValue(option)
                    setSubjectId(option.id)
                    setChapterId("")
                    setTopicId("")
                }
            } else if (name === "chapter") {
                if (option?.inputValue) {
                    const response = await postAPiCall("/CreatorStudios/AddTopics", { Name: option?.inputValue, SubjectID: subjectId, })
                    const newObj = {
                        id: response.data.result,
                        name: option?.inputValue,
                        data: []
                    }
                    const currentIndex = subjectData.findIndex((item: any) => item.id === subjectId)
                    subjectData[currentIndex].data.push(newObj)
                    setChapterValue(newObj)
                    setChapterId(response.data.result)
                    setTopicData([])
                    setChapterData([...chapterData, newObj])
                    setTopicValue(defaultData)
                    setTopicId("")
                    setSubjectData([...subjectData])
                } else {
                    setChapterValue(option)
                    setChapterId(option.id)
                    setTopicData(option.data)
                    setTopicValue(defaultData)
                    setTopicId("")
                }
            } else if (name === "topic") {
                if (option?.inputValue) {
                    const response = await postAPiCall("/CreatorStudios/AddTopics", { Name: option?.inputValue, SubjectID: subjectId, ChapterId: chapterId })
                    const newObj = {
                        id: response.data.result,
                        name: option?.inputValue
                    }
                    const subjectIndex = subjectData.findIndex((item: any) => item.id === subjectId)
                    const chapterIndex = subjectData[subjectIndex].data.findIndex((item: any) => item.id === chapterId)
                    subjectData[subjectIndex].data[chapterIndex].data.push(newObj)
                    setTopicValue(newObj)
                    setTopicData([...topicData, newObj])
                    setTopicId(response.data.result)
                    setSubjectData([...subjectData])
                    dispatch(getTopics())
                } else {
                    setTopicId(option.id)
                    setTopicValue(option)
                }
            }
        }
    }

    const handleClear = (name: string) => {
        if (name === "subject") {
            setChapterData([])
            setTopicData([])
            setChapterValue(defaultData)
            setTopicValue(defaultData)
            setSubjectValue(defaultData)
            setSubjectId("")
            setChapterId("")
            setTopicId("")
        } else if (name === "chapter") {
            setTopicData([])
            setChapterValue(defaultData)
            setTopicValue(defaultData)
            setChapterId("")
            setTopicId("")
        } else if (name === "topic") {
            setTopicValue(defaultData)
            setTopicId("")
        }
    }


    const difficultyChange = (event: any, newValue: any) => {
        if (newValue) {
            changeDifficult(newValue.id)
            setDiffValue(newValue)
        } else {
            changeDifficult(null)
            setDiffValue(defaultDifficulty)
        }
    }

    useEffect(() => {
        if (difficulty >= 0) {
            setDiffValue(difficulityValue[difficulty])
        }else{
            setDiffValue(defaultDifficulty)
        }
    }, [difficulty])

    return (
        <div className='subject_dropdown'>
            <Autocomplete
                value={subjectValue}
                clearOnEscape
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                size="small"
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.name);
                    if (inputValue !== "" && !isExisting) {
                        filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                // selectOnFocus
                // clearOnBlur
                // handleHomeEndKeys
                id="subject-autocomplete"
                options={subjectData}
                getOptionLabel={(option) => {
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name || "";
                }}
                onChange={(event, newValue) => {
                    if (newValue === null || newValue === "") {
                        // Call your custom function here when the clear button is clicked
                        handleClear("subject");
                    }
                    else if (newValue && newValue.inputValue) {
                        listClick(newValue, "subject");
                    } else {
                        listClick(newValue, "subject");
                    }
                }}
                // onInputChange={(event, newInputValue) => {
                //     // Update state when the input changes (including clearing the input)
                //     setSubjectValue(newInputValue);
                // }}
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Subject" />
                )}
                freeSolo
            />



            <Autocomplete
                value={chapterValue}
                disabled={!subjectId}
                clearOnEscape
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                size='small'
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option: any) => inputValue === option.name);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`, // Display "Add" text
                        });
                    }

                    return filtered;
                }}
                // selectOnFocus
                // clearOnBlur
                // handleHomeEndKeys
                id="chapter-autocomplete"
                options={chapterData}
                getOptionLabel={(option) => {
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name || "";
                }}
                onChange={(event, newValue) => {
                    if (newValue === null || newValue === "") {
                        // Call your custom function here when the clear button is clicked
                        handleClear("chapter");
                    }
                    if (newValue && newValue.inputValue) {
                        listClick(newValue, "chapter");
                    } else {
                        listClick(newValue, "chapter");
                    }
                }}
                // onInputChange={(event, newInputValue) => {
                //     // Update state when the input changes (including clearing the input)
                //     setChapterValue(newInputValue);
                // }}
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Chapter" />
                )}
                freeSolo
            />

            <Autocomplete
                value={topicValue}
                disabled={!subjectId || !chapterId}
                clearOnEscape
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                size='small'
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option: any) => inputValue === option.name);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`, // Display "Add" text
                        });
                    }

                    return filtered;
                }}
                // selectOnFocus
                // clearOnBlur
                // handleHomeEndKeys
                id="subject-autocomplete"
                options={topicData}
                getOptionLabel={(option) => {
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name || "";
                }}
                onChange={(event, newValue) => {
                    if (newValue === null || newValue === "") {
                        // Call your custom function here when the clear button is clicked
                        handleClear("topic");
                    }
                    if (newValue && newValue.inputValue) {
                        listClick(newValue, "topic");
                    } else {
                        listClick(newValue, "topic");
                    }
                }}
                // onInputChange={(event, newInputValue) => {
                //     // Update state when the input changes (including clearing the input)
                //     setTopicValue(newInputValue);
                // }}
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Topic" />
                )}
                freeSolo
            />

            <Autocomplete
                disablePortal
                options={difficulityValue}
                size="small"
                value={diffValue}
                onChange={(event: any, newValue: any) => difficultyChange(event, newValue)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Difficulty level" />}
            />


            {/* <FormControl>
                <InputLabel id="demo-simple-select-label">Difficulty level</InputLabel>
                <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={(difficulty !== null || difficulty !== undefined) ? difficulty : ""}
                    className="difficulty_container"
                    label="Difficulty level"
                    onChange={changeDifficult}
                >
                    {difficulityValue.map((item: any, index: number) => {
                        return (
                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl> */}
        </div>
    );
}

export default SubjectSelection

interface FilmOptionType {
    inputValue?: string;
    name: string;
    id?: any;
    data?: any[];
}
const defaultData = {
    name: "",
    id: "",
    data: []
}
const defaultDifficulty = {
    name: "",
    id: "",

}

