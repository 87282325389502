import Button from "../../../components/Common/Button/Button"


interface DeleteModalContentProps {
    // Define your props here
    confirmDlt: () => void;
    handleClose: () => void;
    modalName: string
}

export const DeleteModalContent: React.FC<DeleteModalContentProps> = ({ confirmDlt, handleClose, modalName }) => {
    return (
        <>
            <p className="modal_heading">{modalName}</p>
            <p>Are you sure ? This action will delete the course.</p>
            <div className="modal_footer_btn">
                <Button onClick={confirmDlt} className='modal_ok_btn' btnText="Delete Course" />
                <Button onClick={handleClose} className='modal_cancel_btn' btnText="Cancel" />
            </div>
        </>
    )
}