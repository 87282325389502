/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import styles from "./CustomNode.module.css";
import { useEffect, useState } from "react";
import { TypeIcon } from "./TypeIcon";
import { Box, Checkbox, IconButton, Tooltip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./CustomNode.css"
import { NoteAddOutlined, DeleteOutlined } from "@mui/icons-material";
import { Icon } from "@fluentui/react";
import { Popconfirm } from "antd";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useSelector } from "react-redux";
interface CustomNodeProps {
    node: any;
    depth: any;
    onToggle: any;
    isOpen: any;
    onDelete?: any;
    onChange: any;
    saveClick: any;
    onBlur?: any;
    isFocused?: any;
    addFile?: any;
    onFocusFunc: Function;
    clickOnPage: any;
    checkActive: any;
    isModified?: boolean;
    showSubmitted: Function;
    index: number;
    deleteClick?: any;
    selectQuestion?: any;
    selectedQuestionBank?: any[];
    isFiltered?:boolean;
    selectedSubject?:any[]
}



export const CustomNode: React.FC<CustomNodeProps> = ({ node, depth, onToggle, isOpen, onDelete, isFocused, addFile, clickOnPage, checkActive, isModified = false, index, deleteClick, selectQuestion, selectedQuestionBank , isFiltered , selectedSubject }) => {
    const indent = depth * 24;
    const { id, droppable } = node;
    const [open, setOpen] = useState<boolean>(true)
    const appLocation = useSelector((state: any) => state.ui.appLocation)
    const allCoursedata: any[] = appLocation.split("/")

    const handleClose = () => {
        setOpen(false)
    }

    const removeTags = (str: string) => {
        if (str === null || str === "" || str === undefined) return "";
        else str = str.toString();
        return htmlToText(str)
    };
    function htmlToText(html: string) {
        var temp = document.createElement('div');
        temp.innerHTML = html;
        return temp.textContent ? temp.textContent : "";
    }

    const handleToggle = (e: any) => {
        e.stopPropagation();
        onToggle(node.id);
    };
    const actionClick = (e: any, id: number, node: any, func: Function) => {
        e.stopPropagation()
        if (!isOpen) {
            onToggle(node.id)
        }
        func(id, node)
    }
    useEffect(() => {
        if (node.text === "defaultRootHierarchy") {
            onToggle(node.id)
        }
    }, [])

    const checkMode = () => {
        if (isModified) {
            return "editMode"
        } else {
            return "viewMode"
        }
    }

    const subjectValidation = () => {
        if (typeof node.id === "string") {
            return false
        }
        else if (node.Subject >= 0 && node.Chapter >= 0 && node.Topic >= 0 && node.difficulty >= 0) {
            return false
        } else {
            return true
        }
    }

    
    const selectQuestionFunction = (event: React.ChangeEvent<HTMLInputElement>, node: any) => {
        event.stopPropagation()
        if(selectedQuestionBank){

            const objectIndex = selectedQuestionBank.findIndex((obj:any) => obj.id === node.id);
    
            if (objectIndex !== -1) {
                // If the id exists, remove the object
                selectedQuestionBank.splice(objectIndex, 1);
            } else {
                // If the id does not exist, add a new object
                selectedQuestionBank.push(node);
            }
    
            selectQuestion(selectedQuestionBank)
        }
    };

    const isSelectedQuestion =(id:number)=>{
        if(selectedQuestionBank){
           const val =  selectedQuestionBank.find((obj:any) => obj.id === id)

           if(val) { return true}
           else {return false}
        }else{
            return false
        }
    }

    return (
        <>
            <div onClick={() => node.data.fileType ? clickOnPage(node, id) : ""}
                className={`tree-node ${subjectValidation() ? "warning_sidebar" : ""} ${checkActive()?.id === node.id ? "activeSidebar" : ""}  ${styles.root} `} key={node.id}
                style={{ paddingInlineStart: indent }}
            >
                <div className={`${styles.file_name_icon} customNodeFileame`}>
                    {subjectValidation() ?
                        <Tooltip title="Some missing fields here , Please fill all the details">
                            <PriorityHighIcon sx={{ color: "orange" }} />
                        </Tooltip>
                        : ""}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {(node.selected !== null && node.selected !== "") ? <Icon className="submission_Icon" iconName="Accept"></Icon> : ""}
                        {allCoursedata[4] === "Question Bank" ?
                           ( (isFiltered && (selectedSubject ? selectedSubject.length : false)) ? <Checkbox className="qb_checkbox" sx={{padding:0 , paddingRight:"5px !important" , height:"20px" , width:"20px"}} checked={isSelectedQuestion(node.id)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => selectQuestionFunction(event, node)} /> : "")
                            : <TypeIcon droppable={droppable || false} fileType="text" />}
                    </div>
                    <div className={`${styles[checkMode()]} sidebarName`}  >
                        {!node.editable &&
                            <div className={`sidebarTextList`} >  {node.data.fileType ?
                                <>
                                    <span>{`${index + 1}. `} </span><p className="sbptext" dangerouslySetInnerHTML={{ __html: node.text }}></p>
                                </> :
                                <Tooltip title={removeTags(node.text)}>
                                    <p onClick={handleToggle} className={`${isModified ? "chapterFileName" : "viewFileName"}`}> {`${index} ${removeTags(node.text)}`}</p>
                                </Tooltip>}
                            </div>
                        }
                        {node.editable && (
                            <p className="newlyAdded"> {`${index + 1}. ${node.text.replace(/(<([^>]+)>)/ig, '')}`} </p>
                        )
                        }
                    </div>
                </div>

                <div className={styles.file_action_icon}>
                    {isModified && (!node.editable && <>
                        {droppable && !node.editable &&
                            <>
                                <div className={styles.actionButton}>
                                    <IconButton size="small" onClick={(e: any) => actionClick(e, id, node, addFile)}>
                                        <Tooltip title="Add Page">
                                            <NoteAddOutlined fontSize="small" />
                                        </Tooltip>
                                    </IconButton>
                                </div>
                            </>
                        }
                        <div className={styles.actionButton}>
                            <Box aria-describedby={id} onClick={(e: any) => deleteClick(e, id, node, index)}>
                                <ExpandMoreIcon />
                            </Box>
                            {/* <Popconfirm
                                title="Delete"
                                description={`Are you sure you want to delete this ${node.data.fileType ? "page" : "folder"}  ?`}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={(e: any) => onDelete(e, id, node)}
                            >
                                <IconButton className="deleteBtn" size="small">
                                    <Tooltip title="Delete">
                                        <DeleteOutlined fontSize="small" />
                                    </Tooltip>
                                </IconButton>
                            </Popconfirm> */}
                        </div>
                    </>)}
                </div>
            </div>
        </>
    );
};