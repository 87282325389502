/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
import { useState, useEffect, useRef } from "react";
import {
    Tree,
    getBackendOptions,
    MultiBackend,
} from "@minoru/react-dnd-treeview";
import { DndProvider } from "react-dnd";
import { ThemeProvider, CssBaseline, Pagination, PaginationItem, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import styles from "../../components/CreatorStudioFunctional.module.css";
import { useDispatch, useSelector } from "react-redux";
import { submitQuiz } from "../../redux/actions/API/courses";
import "../../components/CreatorStudioFunctional.css"
import { Image } from "antd";
import { PrimaryButton } from "@fluentui/react";
import { Theme } from "../../components/Sidebar/Theme";
import { CustomNode } from "../../components/Sidebar/Customode";
import { CustomDragPreview } from "../../components/Sidebar/CustomDragPreview";
import { Placeholder } from "../../components/Sidebar/Placeholder";
import jwt_decode from "jwt-decode";
import Animation from "../../components/Common/Animation/Animation";
import Loader from "../../components/Common/Loader/Loader";
import SwipeableViews from "react-swipeable-views";
import CommonModal from "../../components/commonModal/CommonModal";
import correctAnimationData from "../../components/Common/Animation/bg.json"
import failedAnimationData from "../../components/Common/Animation/failed.json"
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Button from "../../components/Common/Button/Button";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { useNavigate } from "react-router-dom";
import { studentCourse } from "../../utils/routePath";
import QuestionSidebar from "../../components/Common/proSidebarComp";
import "./index.css"
import CountdownTimer from "../../components/Common/countdownTimer";
import { renderMarkDown, setStorageFunction } from "../../utils/CommonFunction";
import TextEditor from "../../components/Common/Texteditor/TextEditor";

interface treeDataType {
    data: { fileType: string },
    droppable: boolean,
    editable: boolean,
    id: any,
    parent: number | null,
    text: string,
    pageType: any,
    Media: any,
    selected: number | null,
    order: number | null,
    answered: boolean,
    correctAnswer: any,
    description: string,
    mcqData: string[],
    Chapter: number | null,
    Subject: number | null,
    Topic: number | null,
    difficulty: number | null,
    multiAnswer: any[],
    PageDataJson: any,
    rangeTo: any
}
let defaultMcqOption: string[] = ["", "", "", ""]

const folderNamelength = 250;

function CreatorStudioStudent() {
    const loginToken = useSelector((state: any) => state.api.login.data.token)
    const appLocation = useSelector((state: any) => state.ui.appLocation)
    const allCoursedata: any[] = appLocation.split("/")
    const [openedFolder, setOpenedFolder] = useState<number[]>([])
    const submitData = useSelector((state: any) => state.api.submitQuiz)
    const submitRef = useRef<{ submitData: any | null }>({ submitData: null });
    const [courseType, setCourseType] = useState("Test")
    const [treeData, setTreeData] = useState<any>([]);
    // const [isFocused, setIsFocused] = useState(false);
    const [newText, setNewtext] = useState("")
    const [descriptionValue, setDescriptionValue] = useState("")
    const [headerValue, setHeaderValue] = useState("")
    const [mcqData, setmcqData] = useState([...defaultMcqOption]);
    const [activeId, setActiveId] = useState<number | undefined>(0)
    const [courseId, setCourseId] = useState()
    const [activePaginationPage, setActivePaginationPage] = useState(1)
    const [currentMcqdata, setCurrentMcqdata] = useState([...defaultMcqOption])
    const [selectedAnswerValue, setSelectedAnswerValue] = useState<any>()
    const [submitAnswer, setSubmitedAnswer] = useState<any>([])
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [correctAnimation, setCorrectAnitmation] = useState(false)
    const [failedAnimation, setFailedAnitmation] = useState(false)
    const [hideExplanation, setHideExplanation] = useState(false)
    const [submissionModal, setSubmissionModal] = useState(false)
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
    const [showHideDescription, setShowHideDescription] = useState(false)
    const [count, setCount] = useState<number>(16);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const allCoursesDataLocal = useSelector((state: any) => state.ui.allCoursesData)
    const [submissionData, setSubmissionData] = useState<any>({})
    const [textAnswerValue, setTextAnswerValue] = useState<string>("")
    const isDeselecting = useRef(false);
    const [submissionCount , setSubmissionCount] = useState<number>(0)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [questionType, setQuestionType] = useState<string>("single")
    const [collapsed, setCollapsed] = useState<string>("medium")
    const removeTags = (str: string) => {
        if (str === null || str === "" || str === undefined) return "";
        const regex = /<(?!\/?(img|iframe)\b)[^>]+>/gi;
        return htmlToText(str.replace(regex, ''));
    };

    useEffect(() => {
        if (submitRef.current.submitData) {
            if (submitRef.current.submitData !== submitData) {

                if (!submitData.error && !submitData.loading) {
                    setSubmissionModal(false)
                    navigate(studentCourse)
                }
            }
        } else {
            submitRef.current.submitData = submitData
        }

    }, [submitRef.current, submitData]);



    useEffect(() => {
        setHasSubmitted(false)
    }, [activePaginationPage])
    const isBase64Url = (url: any) => {
        if (url.startsWith('data:') && url.includes(';base64,')) {
            const base64Part = url.split(';base64,')[1];
            try {
                const decoded = atob(base64Part);
                const reencoded = btoa(decoded);
                if (reencoded === base64Part) {
                    return true;
                }
            } catch (error) {
                return false;
            }
        }
        return false;
    }



    const showSubmitted = (id: any) => {
        const data = submitAnswer.findIndex((item: any) => item.PageId === id)
        return submitAnswer[data]?.answer
    }

    const checkEmpty = (data: any, type: string) => {
        if (data) {
            if (data.errorMessage) {
                if (type === "array") {
                    return []
                }
                else if (type === "object") {
                    return {}
                }
            } else {
                if (type === "array") {
                    if (data) {
                        return data
                    } else {
                        return []
                    }
                } else if (type === "object") {
                    if (data.data) {
                        return data
                    } else {
                        return {
                            data: {}
                        }
                    }
                }
            }

        } else if (!data) {
            if (type === "array") {
                return []
            }
            else if (type === "object") {
                return {}
            }
        }
    }
    let getCoursePagesNewData = checkEmpty(useSelector((state: any) => state.api.getCoursePagesNew?.data), "array")

    const userMetadata = useSelector((state: any) => state.ui.userMetadata)
    const loaderStatus = useSelector((state: any) => state.ui.loaderStatus.spinnerStatus)
    const getUserProfile = useSelector((state: any) => state.api.getUserProfile)

    const [seconds, setSeconds] = useState((allCoursedata[7] * 60));
    const hideBreadcrumbFunction = () => {

        if (allCoursedata[1] === "cstudio") {
            return userMetadata.role === "Student" && allCoursedata[3] === "Test"
        }
    }
    // drop Functoin
    const handleDrop = (newTreeData: any, data: any) => {
    };

    const openFolderFunction = (result: any, dataArray: any) => {
        if (result) {
            if (Object.keys(result)) {
                const array = []
                let status = true
                let parentHierarchy: any
                let previousHierarchyId: any = null
                let number = 0
                while (status) {
                    status = false
                    let id = number > 0 ? parentHierarchy : result.hierarchyId
                    if (id) {
                        const currentInd = dataArray.findIndex((item: any) => (((id) === (item.id)) && !item.data.fileType))
                        if (currentInd >= 0) {
                            const hierarchy = dataArray[currentInd].parentHierarchyId
                            parentHierarchy = dataArray[currentInd].parentHierarchyId
                            array.push(hierarchy)
                        }
                        number++
                        status = (previousHierarchyId !== parentHierarchy)
                        previousHierarchyId = parentHierarchy
                    }
                }
                array.push(result.hierarchyId)
                setOpenedFolder([...array])
            }
        }
    }
    const defaultData = {
        text: "",
        description: "",
        mcqData: "[]",
        Answer: "",
        PageOrder: 0
    }
    const start = (e: any) => {
        e()
    }


    const setMultiAnswers = (item: any) => {
        if (item?.PageDataJson) {
            const jsonData = JSON.parse(item?.PageDataJson)
            if (jsonData.questionType === "multiple") {
                return jsonData.answer.split(",")
            } else if (jsonData.questionType === "single") {
                return jsonData.answer
            }
        }
    }

    useEffect(() => {
        setCourseType(allCoursedata[3])
        setCourseId(allCoursedata[2])
        let parseData: any = allCoursesDataLocal
        const checkData = () => {
            if (Object.keys(parseData).length) {
                if (parseData[decoded_token.nameid]) {
                    if (parseData[decoded_token.nameid][allCoursedata[2]]) {
                        return parseData[decoded_token.nameid][allCoursedata[2]]
                    } else {

                        return [defaultData]
                    }
                } else {
                    return [defaultData]
                }
            } else {
                return [defaultData]
            }
        }
        const decoded_token: any = jwt_decode(loginToken)
        const pagesData = checkData()



        const dataForTree = pagesData?.map((item: any) => {
            const newObj = {} as treeDataType
            newObj.parent = 0
            newObj.editable = false
            newObj.id = item.ID
            newObj.description = item.description
            newObj.PageDataJson = item.PageDataJson
            newObj.mcqData = item.mcqData
            newObj.text = item.heading
            newObj.data = { fileType: 'file' }
            newObj.selected = item.pageId ? null : null
            newObj.order = item.PageOrder
            newObj.multiAnswer = setMultiAnswers(item) ? setMultiAnswers(item) : []
            newObj.rangeTo = item.PageDataJson ? JSON.parse(item.PageDataJson)?.rangeTo : ""
            newObj.Chapter = item.Chapter
            newObj.Subject = item.Subject
            newObj.Topic = item.Topic
            newObj.difficulty = item.Difficulty
            newObj.answered = false
            newObj.correctAnswer = item.Answer
            newObj.Media = item.Media ? item.Media : []
            return newObj
        })
        const subData2 = {
            courseId: +allCoursedata[2],
            userId: +(userMetadata.id),
            classId: +(getUserProfile?.data?.classId),
            attempt: ((+allCoursedata[12]) - (+allCoursedata[10]) + 1),
            auto: allCoursedata[5] === "AI" ? 1 : 0,
            pageWiseAnswer: []
        }


        const assendingData = dataForTree.sort((a: any, b: any) => a.order - b.order)

        const subData = assendingData.map((item: any) => {
            return {
                pageId: item.id,
                answer: null,
                score: 0,
                topicId: item.Topic
            }
        })
        subData2.pageWiseAnswer = subData;
        setSubmissionData(subData2)

        const result = assendingData[0]
        if (result?.PageDataJson) {
            const jsonData = JSON.parse(result?.PageDataJson)
            setQuestionType(jsonData?.questionType)
        }
        setActiveId(result?.id)
        setHeaderValue(result?.text)
        setDescriptionValue(result?.description)
        setmcqData(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
        setCurrentMcqdata(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
        setTreeData([...assendingData])

    }, [getCoursePagesNewData.length, loaderStatus])

    const newPageActive = () => {
        const localData = localStorage.getItem("activeData")
        if (localData) {
            const parseData = JSON.parse(localData)
            let count2 = 0
            let result2 = 0
            for (const item of treeData) {
                if (item.data.fileType !== "") {
                    count2++
                    if (item.id === parseData[0].pageId) {
                        result2 = count2;
                        break;
                    }
                }
            }
            setActivePaginationPage(result2)
            const currentIndex = treeData.findIndex((item: any) => item.id === parseData[0].pageId)
            const result = treeData[currentIndex]

            openFolderFunction(result, treeData)
            setActiveId(result?.id)
            setHeaderValue(result?.text)
            setDescriptionValue(result?.description)
            setmcqData(result?.mcqData ? JSON.parse(result?.mcqData) : [...defaultMcqOption])
            setCurrentMcqdata(result?.mcqData ? JSON.parse(result?.mcqData) : [...defaultMcqOption])
            return (result2)
        }
    }
    useEffect(() => {
        newPageActive()
    }, [treeData.length])
    useEffect(() => {
        let count = 0
        let result: any = null;
        for (const item of treeData) {
            if (item.data.fileType !== "") {
                count++;
                if (count === activePaginationPage) {
                    result = item;
                    break;
                }
            }
        }
        openFolderFunction(result, treeData)
        localStorage.removeItem("activeData")
    }, [activePaginationPage])




    const onChange = (e: any, id: number) => {
        if (e.target.value.length <= folderNamelength) {
            const currentIndex = treeData.findIndex((x: any) => x.id === id);
            treeData[currentIndex].text = e.target.value
            setTreeData([...treeData])
            setNewtext(e.target.value)
        }
    }
    const saveClick = (id: number) => {
        const currentIndex = treeData.findIndex((x: any) => x.id === id);
        if (newText) {
            treeData[currentIndex].editable = false
            treeData[currentIndex].onFocus = false
            treeData[currentIndex].text = newText
            setTreeData([...treeData])
        }
    }





    const setActivePageData = (id: number, data: any) => {
        const currentindex: any = submissionData.pageWiseAnswer.findIndex((vale: any) => vale.pageId === id)
        setActiveId(id)
        if (data?.PageDataJson) {
            const jsonData = JSON.parse(data?.PageDataJson)
            setQuestionType(jsonData?.questionType)
        } else {
            setQuestionType("single")
        }
        setActivePaginationPage(currentindex + 1)
        setHeaderValue(data?.text)
        setDescriptionValue(data?.description)
        setmcqData(data?.mcqData ? JSON.parse(data?.mcqData) : [...defaultMcqOption])
        setCurrentMcqdata(data?.mcqData ? JSON.parse(data?.mcqData) : [...defaultMcqOption])
        setSelectedAnswerValue((submissionData.pageWiseAnswer[currentindex].answer !== null && submissionData.pageWiseAnswer[currentindex].answer !== "") ? submissionData.pageWiseAnswer[currentindex].answer : null)
    }

    const clickOnPage = (data: any, id: number) => {
        setActivePageData(id, data)
    }

    const paginationClick = (data: any, val: any) => {
        setActivePaginationPage(val)
        setSelectedAnswerValue((submissionData.pageWiseAnswer[val - 1].answer !== null && submissionData.pageWiseAnswer[val - 1].answer !== "") ? submissionData.pageWiseAnswer[val - 1].answer : null)
        let count = 0;
        let result = null;
        for (const item of treeData) {
            if (item.data.fileType !== "") {
                count++;
                if (count === val) {
                    result = item;
                    break;
                }
            }
        }
        setActiveId(result.id)
        setHeaderValue(result?.text)
        if (result?.PageDataJson) {
            const jsonData = JSON.parse(result?.PageDataJson)
            setQuestionType(jsonData?.questionType)
        }
        setDescriptionValue(result?.description)
        setmcqData(result?.mcqData ? JSON.parse(result?.mcqData) : [...defaultMcqOption])
        setCurrentMcqdata(result?.mcqData ? JSON.parse(result?.mcqData) : [...defaultMcqOption])
    }
    const paginationChange = (data: any, val: any) => {

        paginationClick(data, val)
    }

    const checkActive = () => {
        let count = 0
        let result = null;
        for (const item of treeData) {
            if (item.data.fileType !== "") {
                count++;
                if (count === activePaginationPage) {
                    result = item;
                    break;
                }
            }
        }
        return result
    }

    const paginationCount = () => {
        const length = treeData.filter(((item: any) => item.data.fileType)).length
        if (length) {
            return length
        } else {
            return 0
        }
    }

    const finalSubmission = () => {
        // const filteredData = submissionData.pageWiseAnswer.filter((item: any) => item.answer !== null)
        // submissionData.pageWiseAnswer = filteredData
        setStorageFunction("submitData", JSON.stringify(submissionData))

        dispatch(submitQuiz(submissionData))
    }
    const closeSubmission = () => {
        setSubmissionModal(false)
    }

    function htmlToText(html: string) {
        var temp = document.createElement('div');
        temp.innerHTML = html;
        if (temp.querySelector('img') || temp.querySelector('iframe')) {
            return temp.innerHTML; // Return the HTML as it is
        } else {
            return temp.textContent ? temp.textContent : ""; // Return text content
        }
    }

    const selectAnswer = (e: React.ChangeEvent<HTMLInputElement>, value: any, index: number, name?: string) => {
        if (isDeselecting.current) {
            isDeselecting.current = false;
            return;
        }
        if (name === "multiple") {
            let val: any
            if (selectedAnswerValue) {
                if (typeof selectedAnswerValue === "string") {
                    val = selectedAnswerValue.split(",")
                } else {
                    val = selectedAnswerValue
                }
            } else {
                val = []
            }
            if (val.includes(index.toString())) {
                const data = val.filter((item: any) => item !== index.toString())
                setSelectedAnswerValue(data)
            } else {
                setSelectedAnswerValue([...val, index.toString()])
            }
        } else {
            if (e.target.checked) {
                setSelectedAnswerValue(index.toString())
            } else {
                setSelectedAnswerValue(null)
            }
        }


    }

    const removeAnswer = (index: number, name?: string, e?: any) => {
        e?.stopPropagation();
        if (name === "multiple") {
            let val: any
            if (selectedAnswerValue) {
                if (typeof selectedAnswerValue === "string") {
                    val = selectedAnswerValue.split(",")
                } else {
                    val = selectedAnswerValue
                }
            } else {
                val = []
            }
            if (val.includes(index.toString())) {
                const data = val.filter((item: any) => item !== index.toString());
                isDeselecting.current = true;
                setSelectedAnswerValue(data)
            }
        } else {
            if (index.toString() === selectedAnswerValue) {
                isDeselecting.current = true;
                setSelectedAnswerValue("");

            }
        }
    }


    const multipleScores = (correctAnswer: any, currentAnswer: any) => {

        const strArray = correctAnswer.split(',').map(Number);
        if (currentAnswer.length < strArray.length && currentAnswer.every((num: any, idx: number) => +num == strArray[idx])) {
            return +allCoursedata[11];
        }
        if (currentAnswer.length === strArray.length && currentAnswer.every((num: any, idx: number) => +num == strArray[idx])) {
            return +allCoursedata[8];
        }
        if (!currentAnswer.every((num: any, idx: number) => +num == strArray[idx])) {
            return -allCoursedata[9];
        }


    }

    const textScore = (correctAnswer: any, range: any, currentAnswer: any) => {
        if (currentAnswer >= correctAnswer && currentAnswer <= range) {
            return +allCoursedata[8];
        } else {
            return -allCoursedata[9];
        }
        // const minRange = +correctAnswer - 2;
        // const maxRange = +correctAnswer + 2;
        // if (currentAnswer >= minRange && currentAnswer <= maxRange) {
        //     return +allCoursedata[8];
        // } else {
        //     return -allCoursedata[9];
        // }

    }


    const saveAndNextClick = () => {

        const currentindex = submissionData.pageWiseAnswer.findIndex((vale: any) => vale.pageId === activeId)
        if (selectedAnswerValue !== null && selectedAnswerValue !== "" && selectedAnswerValue !== undefined) {
            submissionData.pageWiseAnswer[currentindex].answer = selectedAnswerValue.toString()
        }
        const scoreIndex = treeData[currentindex]
        if (questionType === "single") {
            submissionData.pageWiseAnswer[currentindex].score = scoreIndex.correctAnswer == selectedAnswerValue ? +allCoursedata[8] : (selectedAnswerValue !== null ? -allCoursedata[9] : 0)
        }
        else if (questionType === "multiple") {
            submissionData.pageWiseAnswer[currentindex].score = multipleScores(scoreIndex.correctAnswer, selectedAnswerValue)
        } else if (questionType === "text") {
            submissionData.pageWiseAnswer[currentindex].score = textScore(scoreIndex.correctAnswer, scoreIndex.rangeTo, selectedAnswerValue)
        }
        submissionData.pageWiseAnswer[currentindex].topicId = scoreIndex.Topic
        if ((currentindex + 1) !== submissionData.pageWiseAnswer.length) {
            const submitResult = submissionData.pageWiseAnswer[currentindex + 1]
            const result = treeData[currentindex + 1]
            setSelectedAnswerValue((submitResult.answer !== "" && submitResult.answer !== null) ? submitResult.answer : null)
            setActiveId(result.id)
            if (result?.PageDataJson) {
                const jsonData = JSON.parse(result?.PageDataJson)
                setQuestionType(jsonData?.questionType)
            }
            setActivePaginationPage(currentindex + 2)
            setHeaderValue(result?.text)
            setDescriptionValue(result?.description)
            setmcqData(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
            setCurrentMcqdata(result?.mcqData ? JSON.parse(result?.mcqData) : defaultMcqOption)
        }
        setSubmissionData(submissionData)
        if (courseType === "Test") {
            if (selectedAnswerValue !== null && selectedAnswerValue !== "" && selectedAnswerValue !== undefined) {
                treeData[currentindex].selected = selectedAnswerValue.toString()
            }
        }
        setTreeData([...treeData])
    }

    // const showDisabled = () => {
    //     const data = currentMcqdata.filter((item: any) => item.selected)
    //     return data.length
    // }
    useEffect(() => {
        let timer: any;
        if (userMetadata.role === "Student") {
            if (allCoursedata[3] === "Test") {
                if (seconds > 0) {
                    timer = setInterval(() => {
                        setSeconds(seconds - 1);
                    }, 1000);
                } else if (seconds === 0) {
                    finalSubmission()
                }
            }
        }
        return () => {
            clearInterval(timer);
        };
    }, [seconds]);


    const showExplanation = () => {
        setHideExplanation(!hideExplanation)
    }
    const checkClassName = (item: any) => {
        if (courseType !== "Test") {
            if (isSubmitted) {
                if (item.selected && item.isCorrect) {
                    return "correctAnswer"
                } else if (item.selected && !item.isCorrect) {
                    return "wrongAnswer"
                }
            } else {
                return ""
            }
        } else {
            return ""
        }
    }
    const handleSwipe = (val: any) => {
        setSelectedAnswerValue((submissionData.pageWiseAnswer[val].answer !== null && submissionData.pageWiseAnswer[val].answer !== "") ? submissionData.pageWiseAnswer[val].answer : null)
        setActivePaginationPage(val + 1)
        let count = 0;
        let result = null;
        for (const item of treeData) {
            if (item.data.fileType !== "") {
                count++;
                if (count === val + 1) {
                    result = item;
                    break;
                }
            }
        }

        if (result) {
            setActiveId(result.id)
            setHeaderValue(result?.text)
            if (result?.PageDataJson) {
                const jsonData = JSON.parse(result?.PageDataJson)
                setQuestionType(jsonData?.questionType)
            }
            setDescriptionValue(result?.description)
            setmcqData(result?.mcqData ? JSON.parse(result?.mcqData) : [...defaultMcqOption])
            setCurrentMcqdata(result?.mcqData ? JSON.parse(result?.mcqData) : [...defaultMcqOption])
        }
    }
    useEffect(() => {
        const correctData = mcqData.findIndex((item: any) => item.isCorrect)
        setHideExplanation(false)
        const data = submitAnswer.findIndex((item: any) => ((item.PageId === activeId) && (item.answer === correctData)))
        if (courseType !== "Test") {
            if (data > -1) {
                setIsSubmitted(true)
                setDisableSubmit(true)
            } else {
                setIsSubmitted(false)
                setDisableSubmit(false)
            }
        }
    }, [activeId])

    const submittedPagination = (item: any) => {
        if (item.type === "previous" || item.type === "next") {
            return ""
        } else {
            if (submitAnswer[item.page - 1]?.answer) {
                return "submittedPage"
            }
        }
    }

    useEffect(() => {
        if (activePaginationPage === 0) {
            setActivePaginationPage(1)
        }
    }, [activePaginationPage === 0])


    const cancelClick = (e: any) => {
        e.stopPropagation();
    }
    const doneClick = () => {
    }



    const showMore = () => {
        setShowHideDescription(!showHideDescription)
    }

    useEffect(() => {
        if (submitAnswer.length) {
            if (submitAnswer[activePaginationPage - 1]?.selectedAns >= 0) {
                currentMcqdata.map((item: any, index: number) => {
                    if (index === submitAnswer[activePaginationPage - 1].selectedAns) {
                        item.selected = true
                    } else {
                        item.selected = false
                    }
                    return item
                })
                setCurrentMcqdata([...currentMcqdata])
            }
        }
    }, [activePaginationPage])


    const increment = () => {
        setCount((prevCount: number) => {
            if (prevCount < 50) {
                return prevCount + 1; // Return the new state value
            }
            return prevCount;
        });
    };
    const decrement = () => {
        setCount((prevCount: number) => {
            if (prevCount > 16) {
                return prevCount - 1; // Return the new state value
            }
            return prevCount;
        });
    };


    const startIncrementing = () => {
        if (!intervalId && count < 50) {
            const id = setInterval(increment, 200); // Adjust interval as needed
            setIntervalId(id);
        }
    };
    const startDecrement = () => {
        if (!intervalId && count > 16) {
            const id = setInterval(decrement, 200); // Adjust interval as needed
            setIntervalId(id);
        }
    };
    const decreaseFont = () => {
        if (count > 16) {
            setCount(count - 1)
        }
    }
    const increaseFont = () => {
        if (count < 50) {
            setCount(count + 1)
        }
    }

    const stopIncrementing = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };
    const showExplanationBtn = () => {
        return submitAnswer[activePaginationPage - 1]?.answer
    }

    const imageTypes = ["jpeg", "png", "gif", "bmp", "tiff", "svg", "raw", "webp", "heif", "ico"]


    const getFileExtension = (url: any, allowedExtensions: string[]) => {
        if (typeof url === "string") {
            const extension: string = (url.split('.').pop() || '').toLowerCase();
            const splitExtension = extension.split("?")
            if (isBase64Url(url)) {
                return extension
            } else {
                if (allowedExtensions.includes(splitExtension[0])) {
                    return extension;
                } else {
                    return null;
                }
            }
        }
        else {
            if (!url.type.includes("image")) {
                return false
            }
        }

    };

    const downloadFileClick = (elem: any) => {
        if (typeof elem === "string") {
            window.open(elem)
        } else {
            const link = document.createElement('a')
            link.download = elem.name
            link.href = URL.createObjectURL(elem)
            link.click()
        }

    }

    const onFocusFunc = () => {
    }

    const submitClick = () => {
        setSubmissionModal(true)
        if (submissionData && submissionData.pageWiseAnswer) {
            const data = submissionData?.pageWiseAnswer.filter((item: any) => item.answer !== "" && item.answer !== null)
            setSubmissionCount(data.length)
        } else {
            return setSubmissionCount(0)
        }
    }
    const regularSubmitClick = () => {
        setIsSubmitted(true)
        // const check = currentMcqdata?.filter((item: any) => item.selected)
        // const correctAnswer = currentMcqdata?.findIndex((item: any) => item.isCorrect && item.selected)
        const currentindex = treeData.findIndex((item: any) => item.id === activeId)
        submissionData.pageWiseAnswer[currentindex].answer = selectedAnswerValue

        // regularAnswerArray[currentindex].selectedAnswer = regularSelectedAns
        // setRegularAnswerArray([...regularAnswerArray])
        if (courseType !== "Test") {
            if (treeData[currentindex].correctAnswer === selectedAnswerValue) {
                setCorrectAnitmation(true)
                setTimeout(() => {
                    setCorrectAnitmation(false)
                }, 2000);
            } else {
                setFailedAnitmation(true)
                setTimeout(() => {
                    setFailedAnitmation(false)
                }, 2000);
            }
        }
        setSubmissionData(submissionData)
    }

    const childRef = useRef<ChildComponentRef>(null);

    useEffect(() => {
        const totalTime = localStorage.getItem("totalTime")
        const timeLeft = localStorage.getItem("timeLeft")
        if (childRef.current) {
            if (totalTime === timeLeft) {
                childRef.current.callChildFunction();
            }
        }
    }, [])
    const optionOnchange = (e: any) => {
        const inputValue = e.target.value;
        const regex = /^[+-]?\d*\.?\d*$/;
        if (regex.test(inputValue) || inputValue === '') {
            const currentIndex = treeData.findIndex((item: any) => item.id === activeId)
            treeData[currentIndex].answer = inputValue
            setSelectedAnswerValue(inputValue)
            setTreeData([...treeData])
        }
    }

    const fillAnswersValue = (name: string) => {
        if (name === "multiple") {
            if (selectedAnswerValue) {
                if (typeof selectedAnswerValue === "string") {
                    return selectedAnswerValue.split(",")
                } else {
                    return selectedAnswerValue
                }
            } else {
                return []
            }
        }

    }

    const textAnsValue = () => {
        if (selectedAnswerValue) {
            return selectedAnswerValue
        } else {
            return ""
        }
    }

    useEffect(()=>{
        renderMarkDown()
    },[])


    const editorFocus = (e: any, type: any) => {
        console.log("")
    }

    return (
        <>

            <div className={`homeworkElementsContainer creatorStudioParentBody ${hideBreadcrumbFunction() ? "fullScreenBody" : ""}`}>
                <div className="creatorStudio">
                    <CommonModal modalOpen={submissionModal} handleClose={closeSubmission}>
                        <>
                            <p>You have answered {`${submissionCount} / ${submissionData?.pageWiseAnswer?.length}`} questions. Are you sure you want to submit the Answers?</p>
                            <div className="modalFooterBtn">
                                <PrimaryButton text="Yes" disabled={loaderStatus} onClick={finalSubmission}>
                                </PrimaryButton>
                                <Button btnText="Cancel" onClick={closeSubmission} className="okBtn" disabled={false} />
                            </div>
                        </>
                    </CommonModal>
                    <div className="courseDiscription">
                        <div className="courseName">
                            {allCoursedata[4] ? allCoursedata[4] : ""} (
                            {allCoursedata[5]}) <span className="moreBtn" onClick={showMore}>{showHideDescription ? "less..." : "more..."}</span>
                        </div>
                        <div className={`hiddenDescription ${showHideDescription ? "showDescription" : ""} `}>
                            <p className="courseTypeName"> {`(${courseType})`} </p>
                            <div className="courseDesc">
                                {allCoursedata[6]}
                            </div>
                        </div>
                    </div>
                    <div className="creatorStudioPageBody">
                        <div className="creatorStudioSidebar">

                            <div className="collapsible_sidebar">
                                <div className="creatorStudioSidebars">
                                    {(loaderStatus && treeData.length <= 1) ?
                                        <div className="loadingSidebar">
                                            <Loader />
                                        </div>
                                        : ""}
                                    <QuestionSidebar {...{ collapsed, setCollapsed }}>
                                        <ThemeProvider theme={Theme}>
                                            <CssBaseline />
                                            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                                                <div className={styles.app}>
                                                    <Tree
                                                        tree={treeData}
                                                        rootId={0}
                                                        initialOpen={openedFolder}
                                                        render={(node, { depth, isOpen, onToggle }) => {
                                                            const index = treeData.findIndex((item: any) => item.id === node.id);
                                                            return (
                                                                <CustomNode {...{ node, depth, isOpen, onChange, onToggle, onFocusFunc, saveClick, clickOnPage, checkActive, showSubmitted, cancelClick, doneClick, index }} />
                                                            )
                                                        }}
                                                        dragPreviewRender={(monitorProps) =>
                                                            <CustomDragPreview monitorProps={monitorProps} />
                                                        }
                                                        onDrop={handleDrop}
                                                        canDrag={() => false}
                                                        classes={{
                                                            root: styles.treeRoot,
                                                            draggingSource: styles.draggingSource,
                                                            placeholder: styles.placeholderContainer
                                                        }}
                                                        sort={false}
                                                        insertDroppableFirst={false}
                                                        canDrop={() => false}
                                                        dropTargetOffset={5}
                                                        placeholderRender={(node, { depth }) => (
                                                            <Placeholder node={node} depth={depth} />
                                                        )}
                                                    />
                                                </div>
                                            </DndProvider>
                                        </ThemeProvider>
                                    </QuestionSidebar>


                                </div>
                            </div>

                        </div>
                        <div className="creatorStudioQuestionSection">

                            {
                                treeData.findIndex((item: any) => item.data.fileType) > -1 ?
                                    <div className={`studentTestSection fullScreen} `}>
                                        <div className="fontBtn">
                                            <button
                                                onMouseDown={startDecrement}
                                                onMouseUp={stopIncrementing}
                                                onMouseLeave={stopIncrementing}
                                                disabled={count <= 16}
                                                onClick={decreaseFont}
                                            >
                                                <ZoomOutIcon />
                                            </button>
                                            <button
                                                disabled={count >= 50}
                                                onMouseDown={startIncrementing}
                                                onMouseUp={stopIncrementing}
                                                onMouseLeave={stopIncrementing}
                                                onClick={increaseFont}
                                            >
                                                <ZoomInIcon />
                                            </button>
                                        </div>
                                        <div className="test_actions">

                                            <div>
                                                {treeData.findIndex((item: any) => item.data.fileType) > -1 &&
                                                    (courseType === "Test" ?
                                                        <PrimaryButton text={activePaginationPage === treeData.length ? "Save" : "Save and Next"} onClick={saveAndNextClick} >
                                                        </PrimaryButton> :
                                                        <PrimaryButton text="Next" onClick={saveAndNextClick}>
                                                        </PrimaryButton>
                                                    )
                                                }
                                            </div>
                                            <div className="test_timer_submit">
                                                {courseType === "Test" ?
                                                    <CountdownTimer ref={childRef} /> : ''}
                                                {(courseType === "Test" ?
                                                    <div className="finalsubmit_btn">
                                                        <PrimaryButton text="Final Submit" onClick={submitClick} >
                                                        </PrimaryButton>
                                                    </div>
                                                    : ""
                                                )}
                                            </div>

                                        </div>

                                        <SwipeableViews index={activePaginationPage - 1} enableMouseEvents onChangeIndex={handleSwipe} className="creatorStudioSwip">
                                            {treeData.filter((item: any) => item.data.fileType).map((val: any, index: number) => {
                                                return (
                                                    <div className="checkShadow" key={index}>
                                                        <div className={`image_parent swipeableCard`}>
                                                            <p className="chapterName" dangerouslySetInnerHTML={{ __html: (val.hierarchyName === "defaultRootHierarchy" ? "" : val.hierarchyName) }} ></p>

                                                            <p className="heading_text" style={{ fontSize: `${count}px` }} dangerouslySetInnerHTML={{ __html: val.text?.replace(/\\n\\t/g, '<br />') }} ></p>
                                                            {/* {(courseType === "Test") && (val.pageData.description && <div className="test_heading">
                                                                <div className="explanation_section">  <p dangerouslySetInnerHTML={{ __html: val.pageData.description }}></p> <Tooltip title="Note: This section should contain detailed explanation for answers and will be shown to the students at the time of results ">  <InfoIcon /> </Tooltip></div>
                                                            </div>)} */}
                                                            {(courseType !== "Test") && (val.description && <div className="test_heading">
                                                                <div className="explanation_section"><p dangerouslySetInnerHTML={{ __html: val.description?.replace(/\\n\\t/g, '<br />') }}></p> </div>
                                                            </div>)}
                                                            <div className="image_feild_parent">
                                                                {val?.images?.length ?
                                                                    <div className="image_section">
                                                                        {val.images.map((elem: any) => {
                                                                            return (
                                                                                <>
                                                                                    {getFileExtension(elem, imageTypes) ?
                                                                                        <Image
                                                                                            width="100%"
                                                                                            src={elem}
                                                                                            key={elem}
                                                                                        /> :
                                                                                        <Button className="downloadBtn"
                                                                                            onClick={() => downloadFileClick(elem)}
                                                                                            btnText={<><InsertDriveFileOutlinedIcon /> Download File</>} />
                                                                                    }

                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div> : ""}
                                                                {treeData.length ?
                                                                    <div className="studentMcqOptions" style={{ width: val?.images?.length ? "40%" : "100%" }}>
                                                                        {questionType === "single" ?
                                                                            <RadioGroup
                                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                                name="radio-buttons-group"
                                                                            >
                                                                                {currentMcqdata?.map((item: any, index: number) => {
                                                                                    return (
                                                                                        <div className="mcqCheckbox mcqCheckbox_student " key={index}>
                                                                                            <FormControlLabel
                                                                                                value={item}
                                                                                                onClick={(e) => removeAnswer(index, 'single', e)}
                                                                                                control={<Radio
                                                                                                    checked={selectedAnswerValue == index.toString()}
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectAnswer(e, item, index)}
                                                                                                    sx={{
                                                                                                        color: "#306eb3",
                                                                                                        '&.Mui-checked': {
                                                                                                            color: "#306eb3",
                                                                                                        },

                                                                                                    }} />}
                                                                                                label={<TextEditor key={item} {...{editorFocus}} editorOnFocus="" className="textEditDiv" onChange={(e: any, val: any) => selectAnswer(e, index.toString(), val)} type={index.toString()} value={item} />}
                                                                                            />
                                                                                        </div>
                                                                                        
                                                                                        // <p className="heading_text" dangerouslySetInnerHTML={{__html : item}} ></p>
                                                                                    )
                                                                                })}
                                                                            </RadioGroup>
                                                                            : ""}
                                                                        {questionType === "multiple" ? currentMcqdata?.map((item: any, index: number) => {
                                                                            return (
                                                                                <div className="mcqCheckbox" key={index}>
                                                                                    <input type="checkbox"
                                                                                        disabled={disableSubmit}
                                                                                        checked={fillAnswersValue("multiple").includes(index.toString())}
                                                                                        // checked={(regularSelectedAns !== "") ? (index === regularSelectedAns) : false}
                                                                                        id={item}
                                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectAnswer(e, item, index, "multiple")}
                                                                                    />
                                                                                    
                                                                                    <label htmlFor={item} className="mcqCheckbox_student" > <TextEditor key={item} {...{editorFocus}} editorOnFocus="" className="textEditDiv" onChange={(e: any, val: any) => selectAnswer(e, index.toString(), val)} type={index.toString()} value={item} /> </label>
                                                                                </div>
                                                                            )
                                                                        }) : ""}
                                                                        {questionType === "text" ?
                                                                            <input className="cs_input_range" onChange={optionOnchange} placeholder="Enter answer range" value={textAnsValue()} />
                                                                            : ""}
                                                                        {/* {mcqData?.map((item: any, index: number) => {
                                                                            return (
                                                                                item ?
                                                                                    <div className="mcqCheckbox" key={index}>
                                                                                        <input type="checkbox"
                                                                                            disabled={disableSubmit}
                                                                                            className={checkClassName(item)}
                                                                                            checked={(selectedAnswerValue >= 0 && selectedAnswerValue !== "") ? (index == selectedAnswerValue) : false}
                                                                                            id={item}
                                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectAnswer(e, item, index)}
                                                                                        />
                                                                                        <label htmlFor={item} dangerouslySetInnerHTML={{ __html: item }} ></label>
                                                                                    </div>
                                                                                    : ""
                                                                            )
                                                                        })} */}
                                                                        {correctAnimation ? <Animation name={correctAnimationData} /> : ""}
                                                                        {failedAnimation ? <Animation name={failedAnimationData} /> : ""}
                                                                        {courseType !== "Test" && treeData.length ?
                                                                            <div className="mcqButtons">

                                                                                {/* <PrimaryButton text="Submit" disabled={disableSubmit} onClick={regularSubmitClick} className={disableSubmit ? "disabledbtn" : ""}> */}
                                                                                {val.mcqData !== '[\"\",\"\",\"\",\"\"]' ?
                                                                                    <PrimaryButton text="Submit" onClick={regularSubmitClick} className={disableSubmit ? "disabledbtn" : ""}>
                                                                                    </PrimaryButton> : ""}
                                                                                {showExplanationBtn() && ((descriptionValue) ?
                                                                                    <PrimaryButton text={`${hideExplanation ? "Hide" : "Show"} Explanation`} onClick={showExplanation} className="explanationBtn">
                                                                                    </PrimaryButton> : "")}
                                                                            </div> : ""
                                                                        }
                                                                    </div> : ""}
                                                            </div>
                                                            {hideExplanation ?
                                                                descriptionValue &&
                                                                <div className="test_heading">
                                                                    <div dangerouslySetInnerHTML={{ __html: descriptionValue }}></div>
                                                                </div>
                                                                : ""
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </SwipeableViews>

                                    </div> : <div className="noPagesFound">
                                        <p>Please Add Pages!</p>

                                    </div>
                            }
                            {loaderStatus ? "" :
                                <>

                                    <div className="saveBtnParent">
                                        {treeData.length ?
                                            <Pagination className="finalPagination" onChange={paginationChange} count={paginationCount()} boundaryCount={1} siblingCount={0} page={activePaginationPage} variant="outlined" color="primary"
                                                renderItem={(item: any) => (
                                                    <PaginationItem
                                                        {...item}
                                                        className={submittedPagination(item)}
                                                    />
                                                )
                                                }
                                            />
                                            : ""}

                                        {/* {treeData.findIndex((item: any) => item.data.fileType) > -1 &&
                                            (courseType === "Test" ?
                                                <PrimaryButton text="Save and Next" onClick={saveAndNextClick} >
                                                </PrimaryButton> :
                                                <PrimaryButton text="Next" onClick={saveAndNextClick}>
                                                </PrimaryButton>
                                            )
                                        } */}
                                    </div>
                                </>}
                        </div>

                    </div>
                </div>
            </div >
        </>
    );
}
export default CreatorStudioStudent

export interface ChildComponentRef {
    callChildFunction: () => void;
}
