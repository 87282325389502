import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { Tooltip } from 'antd';
import React from 'react';

interface PopoverCompProps {
    // Define your props here
    content: any,
    children: any,
    placement?: TooltipPlacement,
    className?: any;
    showTool?: boolean;
    text?:any
}

const PopoverComp: React.FC<PopoverCompProps> = ({ children, content, className, placement ,showTool=false, text }) => {
    return (
        showTool ?
            <Tooltip title={text}>
                <Popover className={className} content={content} placement={placement} trigger="click">
                    {children}
                </Popover>
            </Tooltip> :
            <Popover className={className} content={content} placement={placement} trigger="click">
                {children}
            </Popover>
    );
};


export default PopoverComp;
