import React from 'react';
import { downloadTemplate } from '../../../utils/CommonFunction';
import { Icon } from '@fluentui/react';
import Button from '../Button/Button';

interface ImportModalDataProps {
    fileChange: (e:any) => void;
    uploadFiles : any;
    importStudents:()=>void
}

const ImportModalData: React.FC<ImportModalDataProps> = ({ fileChange , uploadFiles,importStudents }) => {
    return (

        <>
            <p className="modal_heading">Bulk Student addition with template</p>
            <button className="download_btn" onClick={() => downloadTemplate()}>
                <Icon iconName="DownloadDocument" className="downloadIcon" />
                Click here to download template file
            </button>
            <div className="bulkImportBody">
                <div className="importFromTemplate" onClick={() => { }}>
                    <label htmlFor="bulk_register">
                        <Icon
                            iconName="Up"
                            className="homeworkIcons"
                            title="Upload Template"
                        />
                        <div className="addteacherLabel">Upload</div>
                    </label>
                    <input
                        id="bulk_register"
                        type="file"
                        onChange={fileChange}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    {uploadFiles ? <p>{uploadFiles.name}</p> : ""}
                </div>
            </div>

            <div className="modal_footer_btn">
                <Button onClick={importStudents} className='modal_ok_btn' btnText="Upload" />
            </div>
        </>

    );
};

export default ImportModalData;