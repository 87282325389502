import React from 'react';
import { connect } from 'react-redux';
import { setAppLocation } from '../redux/actions/UI/appLocation';
import { previousLocationData } from '../redux/actions/UI/previousLocationData';
import { setStorageFunction } from '../utils/CommonFunction';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  setAppLocation: Function;
  appLocation: any;
  appPreviousLocation: any;
  previousLocationData: Function;
  previousLocationDataState: any;
}


interface ErrorBoundaryState {
  hasError: boolean;
  foundError: boolean;
}

class ErrorBoundaries extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      foundError: false,
    };
  }

  static getDerivedStateFromError(_: Error) {
    console.log("derived error", JSON.stringify(_));
    const count = localStorage.getItem("count")
    if (count) {
      if (+count <= 3) {
        setStorageFunction("count", ((+count) + 1).toString())
        return { hasError: true, foundError: true };
      } else {
        // localStorage.clear()
        // window.location.reload()
        // setStorageFunction("count", "0")
      }
    } else {
      setStorageFunction("count", '1')
    }
    return { hasError: true, foundError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const count = localStorage.getItem("count")

    if (count) {
      if (+count <= 3) {
        setStorageFunction("count", ((+count) + 1).toString())
        // this.props.setAppLocation(this.props.previousLocationDataState?.pathname);
        // this.props.previousLocationData({
        //   pathname: this.props.appPreviousLocation,
        //   showCourse: this.props.previousLocationDataState.showCourse,
        // });

      } else {
        // localStorage.clear()
        // window.location.reload()
        // setStorageFunction("count" , "0")
      }
    } else {
      setStorageFunction("count", '1')
    }
  }


  render() {
    if (this.state.foundError) {
      // this.props.setAppLocation(this.props.previousLocationDataState?.pathname);
      // this.props.previousLocationData({
      //   pathname: this.props.appPreviousLocation,
      //   showCourse: this.props.previousLocationDataState.showCourse,
      // });
      this.setState({
        foundError: false
      })
      return
    }
    return this.props.children;
  }
}
const mapStateToProps = (state: any) => ({
  appLocation: state.ui.appLocation,
  appPreviousLocation: state.ui.appPreviousLocation,
  previousLocationDataState: state.ui.previousLocationData
  ,
});
const mapDispatchToProps = (dispatch: any) => ({
  setAppLocation: (payload: any) => dispatch(setAppLocation(payload)),
  previousLocationData: (payload: any) => dispatch(previousLocationData(payload)),

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBoundaries);
