
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import "./components/Dashboard/Dashboard.css"
import "./components/Homeworks.css"
import "./components/Admin/DepartmentAndTeacherManager.css"
import "./components/Main.css"
import "./components/Admin/Analytics.css"
import SetUpNewPassword from './components/SetUpNewPassword';
import ForgetPassword from './components/ForgetPassword';
import { lazy, Suspense, useEffect, useState } from 'react';
import "./App.css"
import Login from './screens/login';
import { classes, courses, creatorStudio, scorecard, studentCourse, institutions } from './utils/routePath';
import DashboardLayout from './Layout/dashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { enableLoader } from './redux/actions/UI/spinnerStatus';
import ProtectedRoute from './utils/protectedRoute';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { getTokenCustom, onMessageListener } from './firebase';
import AddStudent from './screens/addStudent';
import useNetworkStatus from './utils/useNetworkStatus';
import Submission from './screens/submission';
import PaymentSuccess from './screens/paymentSuccess';
import Chat from './screens/chat';
import LoginLayout from './Layout/loginLayout/Index';
import { setStorageFunction } from './utils/CommonFunction';
import UserRegister from './screens/userRegister';
import Dashboard from './screens/dashboard';
import DtManager from './screens/dtmanager';
import StudentManager from './screens/studentManager';
import Course from './screens/course';
import Institutions from './screens/institutions';
import StudentCourseScreem from './screens/studentCourse';
import Scoreboard from './screens/scoreboard';
import Analytic from './screens/analytics';
import CreatorStudioFunctional from './components/CreatorStudioFunctional';
import ChangePassword from './components/ChangePassword/ChangePassword';
import CreatorStudioStudent from './screens/creatorStudioStudent';
import Error from './components/Error';
import ViewProfile from "./components/ViewProfile/Index"
initializeIcons();

function App() {
  const count = localStorage.getItem("time")
  const [buttonTimer, setButtonTimer] = useState<number>(count ? +count : 0)
  const loaderStatus = useSelector((state: any) => state.ui.loaderStatus.spinnerStatus)
  const dispatch = useDispatch()
  // const isOnline = useNetworkStatus();
  // const [online, setOnline] = useState<boolean>(isOnline)
  useNetworkStatus()
  useEffect(() => {
    if (process.env.REACT_APP_APP_NAME) {
      document.title = process.env.REACT_APP_APP_NAME;
    }
  }, []);


  // Function for stop loader after 30 sec
  useEffect(() => {
    if (loaderStatus) {
      if (buttonTimer <= 0 && (count === "0" || !count)) {
        dispatch(enableLoader(false))
      } else {
        const intervalId = setInterval(() => {
          setButtonTimer((prevTime) => {
            const newTime = prevTime - 1;
            setStorageFunction("time", newTime.toString());
            return newTime;
          });
        }, 1000);
        return () => clearInterval(intervalId);
      }
    }
  }, [buttonTimer, loaderStatus])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [window.location.href])

  const param = useParams()
  getTokenCustom()

  onMessageListener()
    .then((payload: any) => {
      // setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
      alert(payload?.notification?.body)
    })
    .catch((err) => console.log('failed: ', err));

  // const offlinePageData = localStorage.getItem("offlineData")
  // const loginToken = useSelector((state: any) => state?.api?.login?.data?.token)
  // const addApiCall = async (data: any) => {
  //   if (data.upload) {
  //     const formdata: any = new FormData()
  //     const array = JSON.parse(data.uploadData).map((item: any, index: number) => {
  //       if (typeof item === "string") {
  //         if (isBase64Url(item)) {
  //           return dataURLtoFile(item, `image${index}.png`);
  //         }
  //       } else {
  //         return item
  //       }
  //     })

  //     for (let i = 0; i < array.length; i++) {
  //       const element = array[i];
  //       if (element) {
  //         formdata.append("files", element)
  //       }
  //     }
  //     const requestOptions: any = {
  //       method: 'POST',
  //       headers: { Authorization: `${loginToken}`, },
  //       body: formdata
  //     };
  //     fetch(`${process.env.REACT_APP_API_URL}${uploadMedia}`, requestOptions)
  //       .then(response => response.json())
  //       .then(async (resData: any) => {
  //         const { upload, uploadData, ...rest } = data;
  //         const updatedObject = {
  //           ...rest,
  //           files: resData.result
  //         };
  //         const response = await postAPiCall("/CreatorStudios/AddPage", updatedObject)
  //         const offlinePageData = localStorage.getItem("offlineData")
  //         if (response.status === 200) {
  //           if (offlinePageData) {
  //             const offlinePage = JSON.parse(offlinePageData)
  //             const data = offlinePage.addPageData.slice(1)
  //             localStorage.setItem("offlineData", JSON.stringify(data))
  //           }
  //         }
  //       })
  //   } else {
  //     const { upload, uploadData, ...rest } = data;
  //     const updatedObject = {
  //       ...rest
  //     };
  //     const response = await postAPiCall("/CreatorStudios/AddPage", updatedObject)
  //     const offlinePageData = localStorage.getItem("offlineData")
  //     if (response.status === 200) {
  //       if (offlinePageData) {
  //         const offlinePage = JSON.parse(offlinePageData)
  //         const data = offlinePage.addPageData.slice(1)
  //         localStorage.setItem("offlineData", JSON.stringify(data))
  //       }
  //     }
  //   }

  // }
  // const updateApiCall = async (data: any) => {

  //   if (data.upload) {
  //     const formdata: any = new FormData()
  //     const array = JSON.parse(data.uploadData).map((item: any, index: number) => {
  //       if (typeof item === "string") {
  //         if (isBase64Url(item)) {
  //           return dataURLtoFile(item, `image${index}.png`);
  //         }
  //       } else {
  //         return item
  //       }
  //     })

  //     for (let i = 0; i < array.length; i++) {
  //       const element = array[i];
  //       if (element) {
  //         formdata.append("files", element)
  //       }
  //     }
  //     const requestOptions: any = {
  //       method: 'POST',
  //       headers: { Authorization: `${loginToken}`, },
  //       body: formdata
  //     };
  //     fetch(`${process.env.REACT_APP_API_URL}${uploadMedia}`, requestOptions)
  //       .then(response => response.json())
  //       .then(async (resData: any) => {
  //         const { upload, uploadData, ...rest } = data;
  //         const updatedObject = {
  //           ...rest,
  //           files: resData.result
  //         };
  //         const response = await postAPiCall("/CreatorStudios/UpdatePage", updatedObject)
  //         const offlinePageData = localStorage.getItem("offlineData")
  //         if (response.status === 200) {
  //           if (offlinePageData) {
  //             const offlinePage = JSON.parse(offlinePageData)
  //             const data = offlinePage.updatePageData.slice(1)
  //             localStorage.setItem("offlineData", JSON.stringify(data))
  //           }
  //         }
  //       })
  //   } else {

  //     const response = await postAPiCall("/CreatorStudios/UpdatePage", data)
  //     const offlinePageData = localStorage.getItem("offlineData")
  //     if (response.status === 200) {
  //       if (offlinePageData) {
  //         const offlinePage = JSON.parse(offlinePageData)
  //         const data = offlinePage.updatePageData.slice(1)
  //         localStorage.setItem("offlineData", JSON.stringify(data))
  //       }
  //     }
  //   }
  // }


  // useEffect(() => {
  //   const handleOfflineData = async () => {
  //     if (online) {
  //       const offlinePageData = localStorage.getItem("offlineData");
  //       if (offlinePageData) {
  //         const offlinePage = JSON.parse(offlinePageData);
  //         if (offlinePage?.updatePageData?.length || offlinePage?.addPageData?.length) {
  //           for (const element of offlinePage?.addPageData) {
  //             await addApiCall(element);
  //           }
  //           for (const element of offlinePage?.updatePageData) {
  //             await updateApiCall(element);
  //           }
  //         }
  //       }
  //     }
  //   }
  //   handleOfflineData();

  // }, [online])


  // const Login = lazy(() => import("./screens/login"))
  // const SetUpNewPassword = lazy(() => import("./components/SetUpNewPassword"))
  // const Payment = lazy(() => import("./components/payment"))
  // const ForgetPassword = lazy(() => import("./components/ForgetPassword"))
  // const AddStudent = lazy(() => import("./screens/addStudent"))
  // const Dashboard = lazy(() => import("./screens/dashboard"))
  // const UserRegister = lazy(() => import("./screens/userRegister"))
  // const DtManager = lazy(() => import("./screens/dtmanager"))
  // const StudentManager = lazy(() => import("./screens/studentManager"))
  // const Course = lazy(() => import("./screens/course"))
  // const Institutions = lazy(() => import("./screens/institutions"))
  // const StudentCourseScreem = lazy(() => import("./screens/studentCourse"))
  // const Scoreboard = lazy(() => import("./screens/scoreboard"))
  // const Analytic = lazy(() => import("./screens/analytics"))
  // const CreatorStudioFunctional = lazy(() => import("./components/CreatorStudioFunctional"))
  // const ViewProfile = lazy(() => import("./components/ViewProfile/Index"))
  // const ChangePassword = lazy(() => import("./components/ChangePassword/ChangePassword"))
  // const CreatorStudioStudent = lazy(() => import("./screens/creatorStudioStudent"))
  // const Error = lazy(() => import("./components/Error"))


  return (
    <>
     
      <Routes>
        <Route element={<LoginLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="/setupnewpassword" element={<SetUpNewPassword />} />
          {/* <Route path="/payment" element={<Payment />} /> */}
          <Route path="/forgotpassword" element={<ForgetPassword />} />
          <Route path="/register/:token/:id" element={<AddStudent />} />
          <Route path="/payment_success" element={<PaymentSuccess />} />
          <Route path="/register/:token" element={<AddStudent />} /> {/* for teacher */}
          <Route path="/user-register/:token/:id" element={<UserRegister />} />
        </Route>


        <Route element={<ProtectedRoute />}>
          <Route element={<DashboardLayout />}>
            <Route path="/courses/creator-studio" element={<Navigate to="/creator-studio" replace />} />
            <Route path={classes} element={<Dashboard />} />
            <Route path="/dtManagers" element={<DtManager />} />
            <Route path="/student-manager/:id" element={<StudentManager />} />
            <Route path={courses} element={<Course />} />
            <Route path={institutions} element={<Institutions />} />
            <Route path={studentCourse} element={<StudentCourseScreem />} />
            <Route path={scorecard} element={<Scoreboard />} />
            <Route path="/analytic/:courseId/:classId/:courseCode/:leftAttempts" element={<Analytic />} />
            <Route path={creatorStudio} element={<CreatorStudioFunctional />} />
            <Route path={"/creator-studio/:type"} element={<CreatorStudioFunctional />} />
            <Route path="/viewProfile" element={<ViewProfile />} />
            <Route path="/viewSubmission/:courseId/:userId/:coourseCode/:leftAttempts" element={<Submission />} />
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route path="/student-test" element={<CreatorStudioStudent />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="*" element={<Error />} />
          </Route>
        </Route>
        {/* <Route path="/dashboard" element={<AppContainer />} />
      <Route path="/cstudio/:id" element={<AppContainer />} />
      <Route path="/dtmanager" element={<AppContainer />} />
      <Route path="/viewProfile" element={<AppContainer />} />
      <Route path="/analytics" element={<AppContainer />} />
      <Route path="/viewsubmission" element={<AppContainer />} /> */}

        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
