import { Link } from "react-router-dom";
import React from "react";
import './Error.css'
import { connect } from "react-redux";
import { userMetadata } from "../redux/actions/UI/userMetadata";

interface ErrorProps {
    userMetadata: userMetadata
}
 
interface ErrorState {
    
}
 
class Error extends React.Component<ErrorProps, ErrorState> {
    constructor(props: ErrorProps) {
        super(props);
    }
    render() { 
        return ( <div className="errorPageBody">
            <div className="errorPageMessage">Studybook<br></br>Error : 404, Page does not exist<br></br>
                <Link to='./' className="loginPageLink">{this.props.userMetadata.isLoggedIn ? "Go to Dashboard" : "Go to login page"}</Link>
            </div>
        </div> );
    }
}

const mapStateToProps = (state: any) => ({
    userMetadata: state.ui.userMetadata
});

const mapDispatchToProps = (dispatch: any) => ({
})
 
export default connect(mapStateToProps, mapDispatchToProps)(Error);