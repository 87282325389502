import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getCourses, getUserProfile, updateUserprofile } from "../../redux/actions/API/courses";
import "./Index.css";
import { DatePicker, Icon, TextField } from "@fluentui/react";
import CommonButton from "../Common/CommonButton";
import { message } from "antd";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import 'react-html5-camera-photo/build/css/index.css';
import { avatar } from "../../utils/ImagePath";
import { openNotification } from "../../utils/CommonFunction";
import { uploadMedia } from "../../utils/routePath";
import Dragger from "antd/es/upload/Dragger";

interface UserProfileState {
    firstName: string;
    middleName: string;
    lastName: string;
    emailId: string;
    phoneNumber: string;
    personalAddress: string;
    dateOfBirth: Date | string;
    dob?: Date;
    roleId: number;
    userId: number;
    userAccountStatus: number;
    classId: number;
    picture?: string;
    institutionId?: number;
    createdDate?: string;
    institutionName?: string;
}


interface ViewProfileProps {
    getUserProfile?: Function;
    loginState?: any;
    userData?: any;
    updateUserprofile?: Function;
    updateUserProfileState?: IDataResponse;
    getUserProfileState?: IDataResponse;
    getCourses?: Function;
    getCoursesData?: any;
}

const ViewProfile: React.FC<ViewProfileProps & PropsFromRedux> = (props) => {

    const [userDataState, setUserDataState] = useState<UserProfileState>(props.userData);
    const [loader, setLoader] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const [openPopover, setOpenPopover] = useState(false);
    const [selectedImg, setSelectedImg] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState<string | undefined>(userDataState.picture);

    useEffect(() => {
        if (props.updateUserProfileState !== props.updateUserProfileState) {
            if (!props.updateUserProfileState.loading && !props.updateUserProfileState.error) {
                if (props.updateUserProfileState?.data?.errorMessage && props.updateUserProfileState?.data?.errorMessage !== "") {
                    message.error(`Something went wrong, please try again later`);
                    setLoader(false);
                } else {
                    props.getUserProfile({ userId: props.loginState.id });
                    setLoader(false);
                }
            }
        }
    }, [props.updateUserProfileState]);

    const editClick = (editName: string) => {
        setUserDataState((prevState: any) => ({
            ...prevState,
            userAccountStatus: prevState.userAccountStatus ? 1 : 0,
            dateOfBirth: prevState.dob!,
            [editName]: !prevState[editName]
        }));
    };

    const editChange = (editName: string, data: React.ChangeEvent<HTMLInputElement>) => {
        setUserDataState((prevState) => ({
            ...prevState,
            [editName === "dob" ? "dateOfBirth" : editName]: data.target.value
        }));
    };

    const apiCallFunction = async (response?: any) => {
        const { emailId, firstName, lastName, middleName, personalAddress, phoneNumber, dob, picture } = userDataState;
        const data = {
            roleId: userDataState.roleId,
            firstName,
            middleName,
            lastName,
            dateOfBirth: userDataState.dob!,
            personalAddress,
            userId: userDataState.userId,
            phoneNumber,
            emailId,
            userAccountStatus: 1,
            classId: userDataState?.classId,
            picture: response ? response?.result[0] : picture
        };

        if (emailId && firstName && lastName && middleName && personalAddress && dob) {
            setLoader(true);
            delete userDataState.institutionId;
            delete userDataState.dob;
            delete userDataState.createdDate;
            delete userDataState.institutionName;
            props.updateUserprofile({ data, classId: userDataState.classId });
        } else {
            message.error(`Please fill all the fields`);
        }
    };

    const saveClick = async () => {
        if (selectedImg) {
            const formData = new FormData();
            formData.append("files", selectedImg);
            const requestOptions = {
                method: 'POST',
                headers: { Authorization: `${props.loginState?.token}` },
                body: formData
            };
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}${uploadMedia}`, requestOptions);
                if (response.ok) {
                    const data = await response.json()
                    apiCallFunction(data);
                } else {
                    openNotification("topRight", "something went wrong", "error");
                }
            } catch (error) {
                openNotification("topRight", "something went wrong", "error");
            }
        } else {
            apiCallFunction();
        }
    };

    const changeDate = (date?: Date | null) => {
        setUserDataState((prevState) => ({
            ...prevState,
            dateOfBirth: dateFormat(date!),
            dob: date!
        }));
    };

    const dateFormat = (date: Date) => {
        const inputDate = new Date(date);
        const day = inputDate.getDate();
        const month = inputDate.getMonth() + 1;
        const year = inputDate.getFullYear();
        const hours = inputDate.getHours();
        const minutes = inputDate.getMinutes();
        const seconds = inputDate.getSeconds();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const textFormat = (text: string | undefined) => {
        return text ? text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        )) : text;
    };

    const dataURLtoFile = (dataurl: string, filename: string) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)?.[1];
        let bstr = atob(arr[arr.length - 1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) u8arr[n] = bstr.charCodeAt(n);
        const file = new File([u8arr], filename, { type: mime });
        return file;
    };


    const draggerChange = (e: Blob) => {
        if (e) {
            if (e.size > 300 * 1024) {
                openNotification("topRight", "Image size exceeds the 300KB limit. Please upload a smaller image.", "error","error");
                return;
            }
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result;
                if (typeof result === 'string') {
                    const file = dataURLtoFile(result, 'hello.png');
                    const previewImageUrl = URL.createObjectURL(file);
                    setSelectedImg(file);
                    setPreviewImage(previewImageUrl);
                }
            };
            reader.readAsDataURL(e);
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: "10px"
    };



    return (
        <div className="homeworkElementsContainer">
            <div className="profile_header">
                <h2>USER PROFILE</h2>

            </div>
            <div className="profile_camera">
                <Dragger multiple={false} maxCount={1} onChange={(e: any) => draggerChange(e.file.originFileObj)}>
                    <img className="profile-img" src={previewImage ? previewImage : avatar} alt="" />

                </Dragger>

                <p><span className="acount_status"> Account Status : </span> <span className={props.userData?.userAccountStatus ? "activeState" : "inActiveState"} > {props.userData?.userAccountStatus ? "Active" : "InActive"}</span></p>

            </div>
            <div className="profile_body">
                <div className="profile_section">
                    <div className="profile_field">
                        <p className="profile_label">First Name</p>

                        <div className="profile_value">

                            <TextField value={userDataState?.firstName}
                                placeholder="Enter First Name"
                                onChange={(e) => { editChange("firstName", e as ChangeEvent<HTMLInputElement>) }}
                            />

                        </div>
                    </div>
                    <div className="profile_field">
                        <p className="profile_label">Middle Name</p>
                        <div className="profile_value">
                            <TextField value={userDataState?.middleName}
                                placeholder="Enter Middle Name"
                                onChange={(e) => { editChange("middleName", e as ChangeEvent<HTMLInputElement>) }} />


                        </div>
                    </div>
                    <div className="profile_field">
                        <p className="profile_label">Last Name</p>
                        <div className="profile_value">
                            <TextField value={userDataState?.lastName}
                                placeholder="Enter Last Name"
                                onChange={(e) => { editChange("lastName", e as ChangeEvent<HTMLInputElement>) }} />
                        </div>
                    </div>
                    <div className="profile_field">
                        <p className="profile_label">Phone Number</p>
                        <div className="profile_value">

                            <TextField value={userDataState?.phoneNumber}
                                placeholder="Enter Phone Number"
                                onChange={(e) => { editChange("phoneNumber", e as ChangeEvent<HTMLInputElement>) }} />

                        </div>
                    </div>
                    <div className="profile_field">
                        <p className="profile_label">Email</p>
                        <div className="profile_value">

                            <TextField value={userDataState?.emailId}
                                placeholder="Enter Your Email"
                                onChange={(e) => { editChange("emailId", e as ChangeEvent<HTMLInputElement>) }} />

                        </div>
                    </div>

                    <div className="profile_field">
                        <p className="profile_label">Date of Birth</p>
                        <div className="profile_value">
                            <DatePicker
                                value={userDataState?.dob? new Date(userDataState?.dob):undefined}
                                onSelectDate={changeDate}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                            />
                        </div>
                    </div>
                    <div className="profile_field">
                        <p className="profile_label">Class</p>
                        {props.getCoursesData?.classGrade ?
                            <div className="profile_value">
                                <p className="profile_name">{props.getCoursesData?.classGrade}</p>
                            </div> : ""}
                    </div>
                    {props.userData?.roleNumber ?
                        <div className="profile_field">
                            <p className="profile_label">Roll Number</p>
                            <div className="profile_value">
                                <p className="profile_name">{props.userData?.roleNumber}</p>
                            </div>
                        </div> : ""}
                    <div className="profile_field">
                        <p className="profile_label">Institution Name</p>
                        <div className="profile_value">
                            <p className="profile_name">{props.userData?.institutionName}</p>
                        </div>
                    </div>
                    <div className="profile_field">
                        <p className="profile_label">Personal Address</p>
                        <div className="profile_value">

                            <TextField value={userDataState?.personalAddress}
                                placeholder="Enter Your Personal Address"
                                multiline={true}
                                rows={5}
                                resizable={false}
                                onChange={(e) => { editChange("personalAddress", e as ChangeEvent<HTMLInputElement>) }} />

                        </div>
                    </div>

                </div>
                <div className="save_btn_section">
                    <CommonButton btnText={loader ? "Loading..." : "Save"} onClick={saveClick} className="saveBtn" disabled={loader} />
                </div>
            </div>
            {/* <Modal
                open={cameraModalOpen}
                onClose={(event: any, reason: any) => handleClose(event, reason)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="camera_modal">
                    {cameraOpen &&
                        <div className="camera_parent mobile_camera_parent">
                            <Icon iconName="Cancel" onClick={closeModal}></Icon>
                            <Camera
                                onTakePhoto={(dataUri: any) => { handleTakePhoto(dataUri) }}
                            />
                        </div>
                    }
                </Box>
            </Modal> */}


        </div>
    );
};

const mapStateToProps = (state: any) => ({
    userMetadata: state.ui.userMetadata,
    loginState: state.ui.userMetadata,
    userData: state.api.getUserProfile?.data,
    updateUserProfileState: state.api.updateUserprofile,
    getUserProfileState: state.api.getUserProfile,
    getCoursesData: state.api.getCourses.data ? state.api.getCourses?.data[0] : {}
});

// Connecting Redux to the component
const connector = connect(mapStateToProps, { updateUserprofile, getUserProfile, getCourses });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ViewProfile);

