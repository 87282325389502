import Button from "../../../components/Common/Button/Button"


interface DeleteModalContentProps {
    // Define your props here
    confirmDlt: () => void;
    handleClose: () => void;
}

export const DeleteModalContent: React.FC<DeleteModalContentProps> = ({ confirmDlt, handleClose }) => {
    return (
        <>
            {/* <p className="modal_heading"></p> */}
            <p>Are you sure? This action will delete the institution.</p>
            <div className="modal_footer_btn">
                <Button onClick={confirmDlt} className='modal_ok_btn' btnText="Delete" />
                <Button onClick={handleClose} className='modal_cancel_btn' btnText="Cancel" />
            </div>
        </>
    )
}