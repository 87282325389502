import React, { useEffect } from 'react';
import DateSelect from '../dateSelect';
import TimeSelect from '../timeSelect';
import { TextField } from '@fluentui/react';
import dayjs from 'dayjs';
import { convertStamptoDate } from '../../../utils/CommonFunction';
import Dropdown from '../dropdown';

interface ScheduleModalProps {
    selectDate: (e: any, timeString: any) => void;
    selectTime: (e: any, timeString: any) => void;
    minuteChange?: (e: any) => void;
    minValue?: string;
    selectedDate?: string;
    taskValue?: string;
    handleChange?:(e:any)=>void
}

const ScheduleModal: React.FC<ScheduleModalProps> = ({ selectDate, selectTime, minuteChange, minValue, selectedDate, taskValue,handleChange }) => {
    const defaultValue = () => {
        if (taskValue) {
            const dateArray = taskValue.split(" ")
            const dateAndTime = convertStamptoDate(dateArray[1]).split(", ")
            return dateAndTime
        } else {
            return ['', undefined]
        }
    }
    const defaultActiveTime = () => {
        if (taskValue) {
            const dateArray = taskValue.split(" ")
            return dateArray[2]
        }
    }
    const options = [
        
            { value: '3 ', label: 'Publish with result ' },
            { value: '1 ', label: 'Publish without result ' },
        
    ]
    
    return (
        <div className="uptopModal">
            <p className="modalHeading">Schedule Time</p>
            <div className="dateTimePicker">
                <DateSelect defaultValue={defaultValue()[0]} onChange={(e: any, timeString: any) => selectDate(e, timeString)} value={dayjs(selectedDate)} />
                <TimeSelect defaultValue={defaultValue()[1]} onChange={(e: any, timeString: any) => selectTime(e, timeString)} />

            </div>
            <TextField
                label="Minutes"
                placeholder="Time for which the course stays published (Number only)"
                onChange={minuteChange}
                type="number"
                defaultValue={defaultActiveTime()}
            ></TextField>
            <Dropdown placeholder="Please Select " options={options} handleChange={handleChange} />
        </div>
    );
};

export default ScheduleModal;