import React, { useRef } from "react";
import "./TextEditor.css"
import QuillEditor from "../quillEditor";
import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter';
interface TextEditorProps {
  value: any;
  onChange: (e: any, type: string , val?:any) => void;
  labelText?: string;
  type: string;
  editorFocus: (e: any, type: string) => void;
  editorOnFocus: string;
  className?: string;
  key?:any
}


const TextEditor: React.FC<TextEditorProps> = ({ onChange, value, labelText, type, editorFocus, editorOnFocus, className , key}) => {
  const editor = useRef(null);
  Quill.register("modules/blotFormatter", BlotFormatter)
  
  return (
    <div  className="questionFieldBody">
      <label style={{marginLeft: type ==="header" ? "35px" : ""}}>{labelText} </label>
      {editorOnFocus === type ?
        <QuillEditor key={key} isFocus={editorOnFocus === type} {...{type , value , onChange }}/>
        :
        <div className={`${className} editorDiv`} onClick={(e: any) => editorFocus(e, type)}>
          <p className="heading_text" dangerouslySetInnerHTML={{ __html: value }} ></p>
        </div>
      }
    </div>
  );
};


export default TextEditor;