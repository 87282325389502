import React, { useState } from 'react'
import BreadCrumb from '../Common/BreadCrumb'
import logo from "../../assets/webodhi_logo.png"
import { TextField } from '@fluentui/react'
import "./ChangePassword.css"
import { closedEye, openEye } from '../../utils/ImagePath'
import CommonButton from '../Common/CommonButton'
import { useDispatch, useSelector } from 'react-redux'
import { ChangePasswordAction } from '../../redux/actions/API/courses'

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState({
        value: "",
        error: false,
        show: false,
        errorText: ""
    })
    const [newPassword, setNewPassword] = useState({
        value: "",
        error: false,
        show: false,
        errorText: ""
    })
    const [repeatPassword, setRepeatPassword] = useState({
        value: "",
        error: false,
        show: false,
        errorText: ""
    })


    const dispatch = useDispatch()
    const loaderStatus = useSelector((state: any) => state.ui.loaderStatus.spinnerStatus)

    const passwordChange = (e: any, name: string) => {
        const value = e.target.value
        if (name === "oldPassword") {
            if (value.length < 5) {
                setOldPassword(prevstate => ({ ...prevstate, error: true, errorText: "Password should be minimum 5 character" }))
            } else {
                setOldPassword(prevstate => ({ ...prevstate, error: false, errorText: "" }))
            }
            setOldPassword(prevstate => ({ ...prevstate, value: value }))
        } else if (name === "password") {
            if (value.length < 5) {
                setNewPassword(prevstate => ({ ...prevstate, error: true, errorText: "Password should be minimum 5 character" }))
            } else if (value.length >= 5 && repeatPassword.value.length >= 5 && (repeatPassword.value !== value)) {
                setRepeatPassword(prevstate => ({ ...prevstate, error: true, errorText: "New password and confirm password should be same" }))
            } else {
                setNewPassword(prevstate => ({ ...prevstate, error: false, errorText: "" }))
                setRepeatPassword(prevstate => ({ ...prevstate, error: false, errorText: "" }))
            }
            setNewPassword(prevstate => ({ ...prevstate, value }))
        } else if (name === "repeatPassword") {
            if (value.length < 5) {
                setRepeatPassword(prevstate => ({ ...prevstate, error: true, errorText: "Password should be minimum 5 character" }))
            } else if (value.length >= 5 && newPassword.value.length >= 5 && (newPassword.value !== value)) {
                setRepeatPassword(prevstate => ({ ...prevstate, error: true, errorText: "New password and confirm password should be same" }))
            } else {
                setNewPassword(prevstate => ({ ...prevstate, error: false, errorText: "" }))
                setRepeatPassword(prevstate => ({ ...prevstate, error: false, errorText: "" }))
            }
            setRepeatPassword(prevstate => ({ ...prevstate, value: value }))
        }
    }
    const passwordHideShow = (name: string) => {
        if (name === "oldPassword") {
            setOldPassword(prevstate => ({ ...prevstate, show: !oldPassword.show }))
        } else if (name === "password") {
            setNewPassword(prevstate => ({ ...prevstate, show: !newPassword.show }))
        } else if (name === "repeatPassword") {
            setRepeatPassword(prevstate => ({ ...prevstate, show: !repeatPassword.show }))
        }
    }
    const checkDisabled = () => {
        if (newPassword.error || oldPassword.error || repeatPassword.error || (!newPassword.value.length) || (!oldPassword.value.length) || (!repeatPassword.value.length || loaderStatus)) {
            return true
        } else {
            return false
        }
    }
    const submitClick = () => {
        if (!checkDisabled()) {
            const data = {
                oldPassword: oldPassword.value,
                newPassword: newPassword.value
            }
            dispatch(ChangePasswordAction(data))
            setNewPassword(prestate => ({ value: "", error: false, show: false, errorText: "" }))
            setOldPassword(prestate => ({ value: "", error: false, show: false, errorText: "" }))
            setRepeatPassword(prestate => ({ value: "", error: false, show: false, errorText: "" }))
        }
    }


    return (
        <>
            <div className="homeworkElementsContainer">

                <form>
                    <div className="form-signin changePassword">
                        <div className="logoBody ">
                            <img src={logo} alt="logo"></img>
                        </div>
                        <h3 className="form-signin-heading">Change Password</h3>
                        <div className="password_feild">
                            <TextField
                                placeholder="Old Password"
                                className="loginField"
                                type={oldPassword.show ? "text" : "password"}
                                value={oldPassword.value}
                                onChange={(e: any) => passwordChange(e, "oldPassword")}
                                required={true}
                                errorMessage={oldPassword.errorText}
                            />
                            <img className="password_eye" onClick={() => passwordHideShow("oldPassword")} src={oldPassword.show ? openEye : closedEye} alt="" />
                        </div>
                        <div className="password_feild">

                            <TextField
                                placeholder="New Password"
                                className="loginField"
                                value={newPassword.value}
                                type={newPassword.show ? "text" : "password"}
                                onChange={(e: any) => passwordChange(e, "password")}
                                required={true}
                                errorMessage={newPassword.errorText}
                            />
                            <img className="password_eye" onClick={() => passwordHideShow("password")} src={newPassword.show ? openEye : closedEye} alt="" />
                        </div>
                        <div className="password_feild">
                            <TextField
                                placeholder="New Password"
                                className="loginField"
                                value={repeatPassword.value}
                                type={repeatPassword.show ? "text" : "password"}
                                onChange={(e: any) => passwordChange(e, "repeatPassword")}
                                required={true}
                                errorMessage={repeatPassword.errorText}
                            />
                            <img className="password_eye" onClick={() => passwordHideShow("repeatPassword")} src={repeatPassword.show ? openEye : closedEye} alt="" />
                        </div>
                        <div className="submitBtn">
                            <CommonButton disabled={checkDisabled()} className="submit ms-Button--primary" btnText="Change Password" onClick={submitClick} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ChangePassword