import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Common/Button/Button';
import { Icon } from '@fluentui/react';
import { openNotification } from '../../utils/CommonFunction';
import CommonModal from '../../components/commonModal/CommonModal';
import { DeleteModalContent } from './institutionsModalContent/deleteModal';

import { addInstitution, deleteInstitution, editInstitution, getInstitutions } from '../../redux/actions/API/institutions';
import { uploadMedia } from '../../utils/routePath';
import { Tooltip } from 'antd';
import "./index.css"
import AddEditInstitutions from './institutionsModalContent/addEditInstitutions';
import InstitutionBox from '../../components/Common/institutionBox';

interface InstitutionsProps {
    // Define your props here
}

export interface Institution {
    ID: number;
    InstitutionId?: number;
    InstitutionType: string;
    Details: string;
    TierCode: number;
    StudentSizeLimit: number;
    TeacherSizeLimit: number;
    AdminSizeLimit: number;
    InstitutionCode: string;
    InstitutionName: string;
    CreatedDate: string;
    CreatedBy: number;
    LastModifiedDate: string;
    LastModifiedBy: number;
    IsActive: number;
    InstitutionPicture: string;
    Username: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    PhoneNumber: string;
    EmailId: string;
    DOB: string;
    UserPassword: string;
    PersonalAddress: string;
}



const Institutions: React.FC<InstitutionsProps> = (props) => {
    const allInstitutionData: Institution[] = useSelector((state: any) => state.api.getInstitutions?.data) || []
    const loginState = useSelector((state: any) => state?.api?.login)
    const [selectedImg, setSelectedImg] = useState<File | null>(null);

    const [selectedInstitute, setSelectedInstitute] = useState<Institution | undefined>()
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const [institutionModal, setInstitutionModal] = useState<boolean>(false)
    const [isEditableInstitution, setIsEditableInstitution] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getInstitutions())
    }, [])

    const saveInstitution = (institute: Institution) => {
        const payload = {
            ...institute
        }
        if (!selectedInstitute) {
            dispatch(addInstitution(payload))
        }
        else {
            dispatch(editInstitution(payload))
        }

        setInstitutionModal(false)

    }

    const editClick = (item: Institution, e: React.MouseEvent<HTMLElement>) => {
        setSelectedInstitute(item);
        setInstitutionModal(true);
    }

    const deleteClick = (item: Institution, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setSelectedInstitute(item)
        setModalOpen(true)
    }


    const handleClose = () => {
        setInstitutionModal(false)
        setModalOpen(false)
        setSelectedInstitute(void 0);

    }

    const confirmDlt = () => {
        dispatch(deleteInstitution({
            ID: selectedInstitute?.ID,
        }))
        handleClose()
    }


    const submitInstitution = async (institute: Institution) => {
        if (selectedImg) {
            const formData = new FormData();
            formData.append("files", selectedImg);
            const requestOptions = {
                method: 'POST',
                headers: { Authorization: `${loginState?.data.token}` },
                body: formData
            };
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}${uploadMedia}`, requestOptions);
                if (response.ok) {
                    const data = await response.json();
                    institute.InstitutionPicture = data ? data?.result[0] : institute.InstitutionPicture
                    saveInstitution(institute);
                } else {
                    openNotification("topRight", "something went wrong", "error");
                }
            } catch (error) {
                openNotification("topRight", "something went wrong", "error");
            }
        } else {
            saveInstitution(institute);
        }
    }




    const addInstitutionModalClick = (name: string, item?: any) => {
        setInstitutionModal(true)
        setIsEditableInstitution(false)
    }

    const editInstitutionBtnClick = () => {
        setIsEditableInstitution(!isEditableInstitution)
    }






    return (
        <div>


            <CommonModal handleClose={handleClose} modalOpen={modalOpen}  >
                <DeleteModalContent {...{ confirmDlt, handleClose }} />

            </CommonModal>
            <CommonModal className='institute-popup' handleClose={handleClose} modalOpen={institutionModal}  >
                <AddEditInstitutions handleClose={handleClose} onImageSelect={setSelectedImg} onSubmit={submitInstitution} isEditMode={!!selectedInstitute} item={selectedInstitute} />

            </CommonModal>

            <div className="page_header">
                <div className="refresh_with_time">
                    <Button btnText={
                        <>
                            <Icon
                                iconName="Edit"
                                className="homeworkIcons"
                                title="Edit Institution"
                            />
                            <span> {isEditableInstitution ? "View" : "Edit"}</span>
                        </>

                    } onClick={editInstitutionBtnClick}
                        className={`${isEditableInstitution ? "buttonWithicon activeEditAllCourse" : " buttonWithicon enableEditAllCourse"}`}
                        disabled={false} />

                </div>
            </div>

            <div className="homeworkElementsContainer">
                <div className='add_container'>
                    <div className='config_container'>

                        <div
                            className="addSubject"
                            style={{ background: "#E6E6E6", cursor: "pointer" }}
                            onClick={() => addInstitutionModalClick("Add Institution")}
                        >
                            <Tooltip title="Add Institution">
                                <div className="addNewSubject">
                                    <Icon iconName="add" className="addSubjectIcon" />
                                    <p className="add_course_text">Add</p>
                                </div>
                            </Tooltip>
                        </div>

                    </div>

                    <div className="allCourses">
                        {allInstitutionData?.map((item: any) => {
                            return (
                                <InstitutionBox  {...{ editClick, item, deleteClick }} editStatus={isEditableInstitution} />
                            )
                        })}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Institutions;